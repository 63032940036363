import React, { useState, useMemo } from 'react'
import { Button } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { PortsSchema, LocationsSchema } from '../../utils/FormSchemas'
import { formatAddresses } from '../../utils/Functions'
import Icon from '@mdi/react'
import { mdiMapMarker, mdiMapMarkerPlusOutline } from '@mdi/js'

const LocationsCard = (props) => {
	const { orderIndex, segment, terminals, form, corporate } = props
	const [locationsCounter, setLocationsCounter] = useState(1)
	const locationPropsEnum = useMemo(
		() => ({
			Import: {
				loading: { title: 'Loading Point Information', multiple: false, type: 'loading', text: 'LOADING', schema: PortsSchema },
				dropoff: { title: 'Drop-off Information', multiple: true, type: 'dropoff', text: 'DROP-OFF', schema: LocationsSchema },
			},
			Export: {
				terminal: { title: 'Release Terminal', multiple: false, type: 'terminal', text: 'TERMINAL', schema: LocationsSchema },
				loading: { title: 'Loading Point Information', multiple: true, type: 'loading', text: 'LOADING', schema: LocationsSchema },
				dropoff: { title: 'Drop-off Information', multiple: false, type: 'dropoff', text: 'DROP-OFF', schema: PortsSchema },
			},
			LongHaul: {
				loading: { title: 'Loading Point Information', multiple: false, type: 'loading', text: 'LOADING', schema: LocationsSchema },
				dropoff: { title: 'Drop-off Information', multiple: true, type: 'dropoff', text: 'DROP-OFF', schema: LocationsSchema },
			},
		}),
		[]
	)

	const removeLocation = (type, index) => {
		const locations = form.getFieldValue([orderIndex, type])
		locations.splice(index, 1)
		setLocationsCounter((prevState) => prevState - 1)
		form.setFieldsValue({ [orderIndex]: { [type]: locations } })
	}

	const HeaderBar = ({ title, type, index, multiple }) => {
		const PinColor = type === 'loading' ? 'bg-primary-800' : type === 'dropoff' ? 'bg-red-500' : 'bg-gray-500'
		return (
			<div
				className={`flex h-12 items-center justify-between border-b bg-gray-100 px-6 ${
					index >= 0 || (segment === 'Export' && type === 'dropoff') ? '' : 'rounded-t-lg'
				}`}
			>
				<div className='flex items-center'>
					<div className={`mr-4 flex h-4 w-4 items-center justify-center rounded-full ${PinColor}`}>
						<Icon path={mdiMapMarker} size={0.5} className='text-white' />
					</div>
					<p className='font-bold'>{multiple && locationsCounter > 1 ? `${title} # ${index + 1}` : title}</p>
				</div>
				{multiple && locationsCounter > 1 && (
					<Button className='btn-delete' onClick={() => removeLocation(type, index)}>
						Remove
					</Button>
				)}
			</div>
		)
	}

	const LocationCard = (type) => {
		const locationProps = locationPropsEnum[segment][type]
		const options = formatAddresses(corporate?.CorporateLocations)
		const containerTerminals = terminals?.map((terminal) => ({ formattedAddress: terminal?.formattedAddress, _id: terminal?._id }))

		return locationProps?.multiple ? (
			<>
				{[...Array(locationsCounter)].map((_, index) => {
					return (
						<div key={index}>
							{HeaderBar({ ...locationProps, index })}
							<div className='p-6'>{RenderSchema(locationProps.schema({ form, orderIndex, type, index, options }))}</div>
						</div>
					)
				})}
				{locationProps.multiple && (
					<div className='flex w-full items-center justify-end px-6 pb-6'>
						<Button className='btn-dashed py-4' onClick={() => setLocationsCounter((prevState) => prevState + 1)}>
							<Icon path={mdiMapMarkerPlusOutline} size={0.8} className='mr-2' />
							ADD ANOTHER {locationProps?.text === 'LOADING' ? `${locationProps?.text} LOCATION` : locationProps?.text}
						</Button>
					</div>
				)}
			</>
		) : (
			<>
				{HeaderBar(locationProps)}
				<div className='p-6'>{RenderSchema(locationProps.schema({ form, type, orderIndex, index: 0, options, terminals: containerTerminals }))}</div>
			</>
		)
	}

	return (
		<div className='scroll-top'>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Pickup and Drop-off Information</p>
			<div className='mb-12 rounded-lg border bg-white'>{Object.values(locationPropsEnum[segment])?.map((location) => LocationCard(location?.type))}</div>
		</div>
	)
}

export default LocationsCard
