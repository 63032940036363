// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import { Layout, Breadcrumb } from 'antd'
import moment from 'moment'
// COMPONENTS
import OrderStatusPill from '../utility/OrderStatusPill'
// HELPERS
import { SegmentsEnum } from '../../utils/Enums'
import { IconText } from '../../utils/Functions'
import { mdiCash } from '@mdi/js'

const SummaryCard = (props) => {
	const {
		docNumber,
		shippingLine,
		type,
		freightType,
		finalizedRate,
		orderStatus,
		subOrders,
		orderCategory,
		loadingDT,
		preparedOrderNumber,
		preferences,
		createdAt,
		freeDays,
	} = props?.record || {}
	const loadingDate = loadingDT && moment(loadingDT).format('DD-MMM-YYYY')
	const showFinalizedRate =
		preferences?.movementType === 'ImportShifting' || type === 'LongHaul'
			? `${subOrders?.[0]?.finalizedRate} / ${subOrders?.[0]?.finalizedRateUnit}`
			: finalizedRate

	const OrderBreadcrumbs = () => (
		<Breadcrumb separator='>'>
			<Breadcrumb.Item className='cursor-pointer text-primary-800' onClick={() => props?.history?.push(`/shipments/${type}`)}>
				{SegmentsEnum[type]?.long}
			</Breadcrumb.Item>
			<Breadcrumb.Item className='font-medium'>{docNumber}</Breadcrumb.Item>
		</Breadcrumb>
	)

	const OrderDetails = (key, value) => (
		<div className='mr-5 flex flex-col'>
			<p className='mb-2 text-xs font-semibold text-gray-500'>{key}</p>
			<p className='text-sm font-medium'>{value}</p>
		</div>
	)

	return (
		<Layout.Header className='padding h-fit border-b p-6'>
			<div className='flex items-center justify-between'>
				<span>
					{OrderBreadcrumbs()}
					<p className='mt-1 text-2xl font-semibold'>{docNumber}</p>
					<div className='mt-1 flex flex-row items-center'>
						<p className='rounded-2xl bg-black-25 px-2 py-[3px] text-sm font-medium'>{preparedOrderNumber}</p>
						{['Shifting', 'Destuffing']?.includes(orderCategory) && (
							<p className={`ml-3 rounded ${orderCategory === 'Shifting' ? 'bg-[#FFC700]' : 'bg-cyan-100'} px-2 py-1 text-center text-xs font-semibold`}>
								{orderCategory}
							</p>
						)}
						<div className='ml-3 rounded bg-[#E1F5FE]  px-2 py-1 text-[#1D74BD]'>
							{IconText({
								icon: mdiCash,
								size: 0.8,
								iconColor: '#1D74BD',
								text: ` Freight Rate: PKR ${showFinalizedRate || '---'}`,
								textClass: 'text-xs',
							})}
						</div>
					</div>
					<div className='mt-1 text-sm font-medium text-gray-500'>{freightType}</div>
				</span>
				{OrderStatusPill({ status: orderStatus })}
			</div>
			<div className='mt-3 flex w-full justify-around rounded-lg bg-black-25 px-8 py-3'>
				{OrderDetails('Order Date', moment(createdAt).format('DD-MMM-YYYY'))}
				{type === 'Import' && OrderDetails('BL Number', docNumber)}
				{type === 'Export' && OrderDetails('CRO Number', docNumber)}
				{['Import', 'Export'].includes(type) && OrderDetails('Shipping Line', shippingLine)}
				{loadingDate && OrderDetails('Loading Date', loadingDate)}
				{'Import' === type && OrderDetails('Container Return Deadline', freeDays ? moment(freeDays).format('DD-MMM-YYYY') : 'Not Available')}
			</div>
		</Layout.Header>
	)
}

export default SummaryCard
