// REACT
import React, { useEffect, useState } from 'react'
// 3RD PARTY LIBRARIES
import Icon from '@mdi/react'
import { mdiRestart } from '@mdi/js'
import { Layout, Button, List, Select } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { useRecoilValue } from 'recoil'
// COMPONENTS
import LiveTrackingCard from '../components/dashboard/LiveTrackingCard'
import DashboardHeader from '../components/dashboard/DashboardHeader'
import TrackingMap from '../components/dashboard/TrackingMap'
import Searchbar from '../components/utility/Searchbar'
// RECOIL
import { dashboardSelector } from '../recoil/selectors'
// HELPERS
import { SegmentsEnum } from '../utils/Enums'
// HOOKS
import { useFetch } from '../hooks/useFetchQuery'
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'

const Dashboard = (props) => {
	const { segment = 'Import' } = useParams()
	const { selectedTab, activeCorporate } = useOutletContext()
	const { pathname } = useLocation()

	const navigator = useNavigate()

	const [showSearch, setShowSearch] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState()

	const [selectedVehicle, setSelectedVehicle] = useState(null)
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 30 })

	const collectionData = useRecoilValue(dashboardSelector({ segment: segment }))

	const [loading] = useFetch({
		type: 'Orders',
		atomKey: segment,
		variables: { type: segment, businessCode: activeCorporate?.businessCode },
		subscriptionVariables: { type: segment, businessCode: activeCorporate?.businessCode },
		pageInfo: pageInfo,
	})
	const loadMore = () => {
		setPageInfo((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }))
	}

	useEffect(() => {
		setSearchData({ data: collectionData, collection: segment })
		setSelectedVehicle(null)
	}, [showSearch, collectionData, segment, activeCorporate])

	useEffect(() => {
		setPageInfo({ pageNo: 1, pageSize: 30 })
	}, [segment, activeCorporate])

	return (
		<>
			<Layout className='overflow-y-hidden rounded-lg !bg-background p-6 shadow-[0_1px_8px_rgba(0,0,0,0.16)]'>
				<DashboardHeader selectedTab={selectedTab} />
				<Layout className='flex flex-row rounded-2xl'>
					<Layout className='h-full w-1/4 rounded-bl-2xl bg-white'>
						<Layout.Header className='flex items-center p-3 px-6'>
							{showSearch ? (
								<Searchbar
									data={collectionData}
									collection={segment}
									setSearchData={setSearchData}
									filterProperty={['refNumber', 'preparedOrderNumber', 'preparedSubOrderNumber', 'docNumber', 'shippingLine', 'containerNumbers']}
									className='h-12 w-full rounded'
								/>
							) : (
								<Select
									className='SelectField w-full'
									defaultValue={segment}
									onChange={(selectedSegment) => {
										navigator(`${pathname.split('/').slice(0, -1).join('/')}/${selectedSegment}`)
									}}
								>
									{Object.values(SegmentsEnum)?.map((segment) => (
										<Select.Option key={segment.key} value={segment.key}>
											{segment.text}
										</Select.Option>
									))}
								</Select>
							)}
							<div className='ml-4' onClick={() => setShowSearch((prevState) => !prevState)}>
								{showSearch ? (
									<div title='Close Search' className='flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-red-500'>
										<CloseOutlined style={{ color: '#FFFFFF' }} />
									</div>
								) : (
									<div title='Search Orders' className='flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-primary-800'>
										<SearchOutlined style={{ color: '#FFFFFF' }} />
									</div>
								)}
							</div>
							{!showSearch && (
								<button
									onClick={() => {
										setSelectedRecord(null)
										setSelectedVehicle(null)
									}}
								>
									<div className=' ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-primary-800'>
										<Icon path={mdiRestart} className='text-white' size='20' />
									</div>
								</button>
							)}
						</Layout.Header>
						<Layout.Content key='scrollableDiv' className='h-full w-full overflow-scroll rounded-bl-2xl bg-white p-3 pt-0'>
							<List
								dataSource={searchData?.data}
								loading={loading}
								loadMore={
									searchData?.data?.length > 0 && (
										<div className='my-4 px-4 text-center'>
											<Button
												className='h-12 w-full rounded-md border-dashed border-primary-800 bg-white text-[16px] font-semibold text-primary-800 active:text-primary-800'
												onClick={loadMore}
											>
												Load More
											</Button>
										</div>
									)
								}
								renderItem={(record) => (
									<List.Item key={record?._id} className='block w-full items-center'>
										<LiveTrackingCard record={record} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} setSelectedVehicle={setSelectedVehicle} />
									</List.Item>
								)}
							></List>
						</Layout.Content>
					</Layout>
					<Layout className='h-full w-3/4 rounded-r-2xl bg-white'>
						<TrackingMap
							records={collectionData}
							selectedRecord={selectedRecord}
							selectedVehicle={selectedVehicle}
							segment={segment}
							setSelectedVehicle={setSelectedVehicle}
						/>
					</Layout>
				</Layout>
			</Layout>
			<Outlet />
		</>
	)
}

export default Dashboard
