import { useEffect, useState } from 'react'

import { userAtom, corporatesAtom, superUsersAtom } from '../recoil/atoms'
import { authSelector } from '../recoil/selectors'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_CORPORATE_BY_BUSINESS_CODE, GET_ALL_CORPORATES } from '../graphql/queries'
import { CREATE_CLIENT, CONNECT_CLIENT_CORPORATES } from '../graphql/mutations'

import { auth, firestore } from '../firebase/firebaseConfig'

import { useNavigate, useLocation } from 'react-router-dom'
import { GET_CLIENT } from '../graphql/queries'
import { message } from 'antd'

export const useAuth = (setLoading = () => {}, setStatus = () => {}) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[2]
	const userAuth = useRecoilValue(authSelector())

	const [getClients] = useLazyQuery(GET_CLIENT)
	const [getCorporates] = useLazyQuery(GET_CORPORATE_BY_BUSINESS_CODE)
	const [getAllCorporates, { loading }] = useLazyQuery(GET_ALL_CORPORATES)
	const [createClient] = useMutation(CREATE_CLIENT)
	const [updateClient] = useMutation(CONNECT_CLIENT_CORPORATES)

	const [user, setUserAtom] = useRecoilState(userAtom)
	const superUsers = useRecoilValue(superUsersAtom)
	const setCorporatesAtom = useSetRecoilState(corporatesAtom)
	const [activeCorporate, setActiveCorporateAtom] = useState({})

	useEffect(() => {
		if (!loading && userAuth) {
			if (userAuth?.user === 'no user') {
				message.error('No user record for this email address.')
				setLoading(false)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === false) {
				setLoading(false)
				setUserAtom(null)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === true && currLocation === 'login' && Object.keys(activeCorporate).length && user) {
				setLoading(false)
				const redirectTo = JSON.parse(sessionStorage.getItem('redirectTo'))
				navigate(redirectTo || `/dashboard/${user?.isKAM ? 'kam' : 'client'}/${activeCorporate?._id}/Import`, { replace: true })
			}
		}
	}, [userAuth, loading, activeCorporate]) // eslint-disable-line

	const signInWithEmailAndPassword = async ({ email, password, keepSignedIn }) => {
		const userCredential = await auth.signInWithEmailAndPassword(email, password)
		if (userCredential) {
			const token = await auth.currentUser?.getIdToken()
			localStorage.setItem('token', JSON.stringify({ token: token }))
			const clientQuery = await getClients({ variables: { where: { User: { _id: userCredential?.user?.uid } } } })
			if (!!clientQuery?.data?.clients?.[0]) {
				authenticateUser({ userData: clientQuery?.data?.clients?.[0], superUsers, storageKey: keepSignedIn ? 'localStorage' : 'sessionStorage' })
			} else {
				return 'error'
			}
		}
	}

	const authenticateUser = async ({ userData, storageKey }) => {
		const isSuperUser = superUsers?.includes(userData?.User?.email)
		const corporatesData = isSuperUser ? (await getAllCorporates())?.data?.corporates : userData?.Corporate

		const activeCorporateData = isSuperUser
			? (await getAllCorporates())?.data?.corporates?.[0]
			: (await getCorporates({ variables: { where: { _id: corporatesData?.[0]?._id } } }))?.data?.corporates?.[0]

		setUserAtom(userData)
		setCorporatesAtom(corporatesData || [])
		setActiveCorporateAtom(activeCorporateData)

		sessionStorage.setItem('user', JSON.stringify(userData))
		sessionStorage.setItem('corporates', JSON.stringify(corporatesData))
		sessionStorage.setItem('activeCorporate', JSON.stringify(activeCorporateData))
		if (storageKey === 'localStorage') {
			localStorage.setItem('user', JSON.stringify(userData))
			localStorage.setItem('corporates', JSON.stringify(corporatesData))
			localStorage.setItem('activeCorporate', JSON.stringify(activeCorporateData))
			localStorage.setItem('keepSignedIn', JSON.stringify(true))
		}
	}

	const signupWithEmailPassword = async ({ email, name, password, businessId, inviteId }) => {
		let userCreationRequest
		let authResponse
		userCreationRequest = await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUser`, {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password }),
		}).then((response) => {
			if (response.status === 400) {
				authResponse = { code: 'userExists' }
				return
			} else {
				return response
			}
		})
		authResponse = userCreationRequest ? await userCreationRequest.json() : authResponse
		const nameSplit = name?.split(' ')
		if (authResponse?.uid || authResponse?.code === 'userExists') {
			await auth.signInWithEmailAndPassword(email, password)
			const token = await auth.currentUser.getIdToken()
			localStorage.setItem('token', JSON.stringify({ token: token }))
			const createdClient = await createClient({
				variables: {
					createClientInput: {
						isKAM: email?.includes('@bridgelinxpk.com'),
						User: {
							_id: auth.currentUser?.uid,
							firstName: nameSplit.length > 1 ? nameSplit?.slice(0, -1).join(' ') : nameSplit?.[0],
							lastName: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '',
							email,
						},
					},
				},
			})
			await updateClient({
				variables: {
					connectClientCorporatesInput: {
						ClientId: createdClient?.data?.createClient?._id,
						CorporateId: businessId?.map((id) => id),
					},
				},
			})
			firestore.collection('invites').doc(inviteId).set({ status: 'processed' }, { merge: true })
			return 'success'
		} else {
			return 'error'
		}
	}

	const resetPassword = async (email) => {
		await auth.sendPasswordResetEmail(email)
		navigate('/auth/resetLinkSent')
		setLoading(false)
		return 'success'
	}

	const logout = () => {
		if (userAuth) {
			auth.signOut()
			sessionStorage.setItem('user', JSON.stringify(null))
			sessionStorage.setItem('token', JSON.stringify(null))
			sessionStorage.setItem('corporates', JSON.stringify(null))
			sessionStorage.setItem('activeCorporate', JSON.stringify(null))

			localStorage.setItem('user', JSON.stringify(null))
			localStorage.setItem('token', JSON.stringify(null))
			localStorage.setItem('corporates', JSON.stringify(null))
			localStorage.setItem('activeCorporate', JSON.stringify(null))
			localStorage.setItem('keepSignedIn', JSON.stringify(null))
			window.location.href = '/'
		}
	}

	const authorization = async ({ signup, forgotPassword, email, password, keepSignedIn, name, businessId, inviteId }) => {
		setLoading(true)
		try {
			if (forgotPassword) {
				const result = await resetPassword(email)
				if (result === 'success') {
					message.success('Reset link has been sent to your email.')
					setLoading(false)
				}
			} else if (signup) {
				const result = await signupWithEmailPassword({ email, name, password, businessId, inviteId })
				if (result === 'success') {
					message.success('You have been registered successfully.')
					setLoading(false)
					window.location.href = '/'
				} else {
					message.error('The email address is already in use by another account.')
					setLoading(false)
					setStatus('invalid')
				}
			} else {
				const result = await signInWithEmailAndPassword({ email, password, keepSignedIn })
				if (result === 'error') {
					message.error('You do not have a valid account to login.')
					setLoading(false)
				}
			}
		} catch (error) {
			setLoading(false)
			if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
				message.error('Email address or password is incorrect.')
			} else if (error.code === 'auth/user-not-found') {
				message.error('No user record for this email address.')
			} else {
				message.error('Please check your internet connection.')
			}
		}
	}

	return { authorization, logout }
}
