// REACT
import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './App.css'

// CONTAINERS
import Authentication from './containers/Authentication'
import HomePage from './containers/HomePage'
import PrivateRoute from './utils/PrivateRoute'

import Signup from './containers/Signup'
import Dashboard from './containers/Dashboard'
import Shipments from './containers/Shipments'
import OrderDetails from './containers/OrderDetails'
import NewOrder from './containers/NewOrder'
import ChangeCorporateModal from './components/modals/ChangeCorporateModal'
import InviteMembersModal from './components/modals/InviteMembersModal'
import OrderSummaryModal from './components/modals/OrderSummaryModal'
import ValidatedRoute from './utils/ValidatedRoute'
import RedirectRfqPage from './containers/RedirectRfqPage'

const App = () => {
	return (
		<Router>
			<Routes>
				<Route
					element={
						<PrivateRoute redirect='../auth/login'>
							<HomePage />
						</PrivateRoute>
					}
				>
					<Route path='dashboard/:userType/:activeCorporateId/:segment' element={<Dashboard />}>
						<Route path='inviteMembers' element={<InviteMembersModal />} />
						<Route path='changeCorporate' element={<ChangeCorporateModal />} />
					</Route>
					<Route path='shipments/:userType/:activeCorporateId/:segment' element={<Shipments />}>
						<Route path='inviteMembers' element={<InviteMembersModal />} />
						<Route path='changeCorporate' element={<ChangeCorporateModal />} />
					</Route>
					<Route path='shipments/:userType/:activeCorporateId/:segment/:id/orderSummary/:subView' element={<OrderDetails />} />
				</Route>
				<Route
					path='shipments/:userType/:activeCorporateId/:segment/create/:rfqId?'
					element={
						<PrivateRoute redirect='../auth/login'>
							<ValidatedRoute redirect='../redirect/rfq'>
								<NewOrder />
							</ValidatedRoute>
						</PrivateRoute>
					}
				>
					<Route path='orderSummary' element={<OrderSummaryModal />} />
				</Route>
				<Route
					path='shipments/:userType/:activeCorporateId/:segment/:id/orderSummary/:subView'
					element={
						<PrivateRoute redirect='../auth/login'>
							<OrderDetails />
						</PrivateRoute>
					}
				/>
				<Route path='redirect/rfq/:errorType/:segment/:rfqId' element={<RedirectRfqPage />} />
				<Route path='auth/:action' element={<Authentication />} />
				<Route path='auth/:action/:id' element={<Signup />} />
				<Route path='*' element={<Navigate to='auth/login' replace />} />
			</Routes>
		</Router>
	)
}

export default App
