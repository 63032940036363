import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import ContainerDetails from './ContainerDetails'

const ContainerQuantity = (props) => {
	const { form, orderIndex, segment, container, containerCounter, setContainerCounter } = props
	const [selected, setSelected] = useState(containerCounter?.[orderIndex]?.[container?.key] > 0)

	useEffect(() => {
		if (selected !== undefined) {
			setContainerCounter((prevState) => ({
				...prevState,
				[orderIndex]: { ...prevState?.[orderIndex], [container.key]: selected ? prevState?.[orderIndex]?.[container.key] || 1 : 0 },
			}))
		}
	}, [selected]) // eslint-disable-line

	useEffect(() => {
		form.setFieldsValue({
			[orderIndex]: {
				containersSpecifics: {
					...form.getFieldValue([orderIndex, 'containersSpecifics']),
					[container?.key]: {
						...form.getFieldValue([orderIndex, 'containersSpecifics'])?.[container?.key],
						quantity: containerCounter?.[orderIndex]?.[container?.key],
					},
				},
			},
		})
	}, [containerCounter]) // eslint-disable-line

	return (
		<div
			className={
				selected
					? container?.key === 'xl20ft' && containerCounter?.[orderIndex]?.[container?.key] > 1
						? 'h-42 m-2 rounded-md bg-primary-50 p-6'
						: 'h-42 m-2 rounded-md bg-primary-50 p-6'
					: 'm-2 h-16 rounded-md p-6 '
			}
		>
			<Checkbox defaultChecked={selected} onChange={(e) => setSelected(e.target.checked)}>
				<p className='ml-3 font-semibold'>
					{container?.text} {container?.key !== 'other' && 'Container'}
				</p>
			</Checkbox>
			{selected && (
				<ContainerDetails
					form={form}
					orderIndex={orderIndex}
					segment={segment}
					container={container}
					containerCounter={containerCounter}
					setContainerCounter={setContainerCounter}
				/>
			)}
		</div>
	)
}

export default ContainerQuantity
