import React, { useState, useEffect } from 'react'
import { Form, Radio } from 'antd'

import { RenderSchema } from '../../utils/FormRenderer'
import { VehicleInfoSchema } from '../../utils/FormSchemas'
import { ContainerDimensionsEnum } from '../../utils/Enums'

import VehicleType from './VehicleType'
import ContainerDetails from './ContainerDetails'

const VehicleInfo = (props) => {
	const { form, orderIndex, lotOrder, segment, containerCounter, setContainerCounter } = props
	const [selected, setSelected] = useState(form?.getFieldValue([orderIndex, 'vehicleInfo', 'vehicleType']))
	useEffect(() => {
		if (selected !== undefined) {
			setContainerCounter((prevState) => ({
				...prevState,
				[orderIndex]: { ...prevState?.[orderIndex], [selected]: selected ? prevState?.[orderIndex]?.[selected] || 1 : 0 },
			}))
			form.setFieldsValue({
				[orderIndex]: {
					vehicleInfo: {
						vehicleType: selected,
					},
				},
			})
		}
	}, [selected]) // eslint-disable-line

	useEffect(() => {
		form.setFieldsValue({
			[orderIndex]: {
				specialRequests: { ...form.getFieldValue([orderIndex, 'specialRequests']), numberVehicles: containerCounter?.[orderIndex]?.[selected] },
			},
		})
	}, [containerCounter]) // eslint-disable-line

	return (
		<>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Select Movement & Vehicle Type</p>
			<div className='mb-12 rounded-lg border bg-white'>
				<div className='px-6 pt-6'>{RenderSchema(VehicleInfoSchema({ orderIndex }))}</div>
				{lotOrder ? (
					<VehicleType orderIndex={orderIndex} />
				) : (
					<div id='shipmentInfo'>
						<p className='mb-[2px] px-6 text-xs font-semibold text-gray-500'>Select Container Dimensions</p>
						<Form.Item
							id='hello'
							key={[orderIndex, 'vehicleInfo']}
							name={[orderIndex, 'vehicleInfo']}
							rules={[
								{
									validator: async () => {
										if (Object.values(containerCounter?.[orderIndex]).some((count) => count > 0)) Promise.resolve()
										else throw new Error('Container specifics are required.')
									},
								},
							]}
						>
							<Form.Item
								shouldUpdate={(prevValues, currentValues) =>
									prevValues?.[orderIndex]?.specialRequests?.lotOrder !== currentValues?.[orderIndex]?.specialRequests?.lotOrder
								}
								noStyle
							>
								{() => {
									const dimensions = Object.values(ContainerDimensionsEnum(segment))
									return dimensions?.map((container) => (
										<div>
											<Radio.Group id={'inheritSpan'} defaultChecked={false} value={selected} onChange={(e) => setSelected(e.target.value)}>
												<Radio
													value={container.key}
													className={
														selected === container?.key ? 'h-42 m-2 w-full rounded-md border-primary-800 bg-primary-50 p-6 font-semibold' : ' h-16 w-full p-6 font-semibold'
													}
												>
													{container?.text} {!['other', 'halfBody', 'dumper', 'flatbed']?.includes(container?.key) && 'Container'}
													{selected === container.key && (
														<ContainerDetails
															form={form}
															orderIndex={orderIndex}
															segment={segment}
															container={container}
															containerCounter={containerCounter}
															setContainerCounter={setContainerCounter}
														/>
													)}
												</Radio>
											</Radio.Group>
										</div>
									))
								}}
							</Form.Item>
						</Form.Item>
					</div>
				)}
			</div>
		</>
	)
}

export default VehicleInfo
