import { mdiCash, mdiInboxMultipleOutline, mdiSwapVerticalVariant, mdiTextBoxOutline, mdiTruckOutline } from '@mdi/js'
import { ContainerDimensionsEnum, SegmentsEnum, VehicleTypeEnum } from '../../utils/Enums'
import { IconText, prepareContacts } from '../../utils/Functions'
import Icon from '@mdi/react'
import moment from 'moment'
import RouteTimeline from '../utility/RouteTimeline'

const OrderSummaryModalSteps = ({ segment, values, isKAM }) => {
	const prepareTimelineData = () => {
		const clearingAgents = prepareContacts(values?.clearingAgents)

		const loading =
			segment === 'Import' ? [{ ...values?.loading, type: 'loading' }] : values?.loading?.map((location) => ({ ...location, type: 'loading' }))
		const dropoff =
			segment === 'Export' ? [{ ...values?.dropoff, type: 'dropoff' }] : values?.dropoff?.map((location) => ({ ...location, type: 'dropoff' }))

		return { clearingAgents, loading, dropoff, type: segment }
	}

	const ShipmentInfoCard = () => {
		const docNumber = values?.shipmentInfo?.documentNumber
		const loadingDT = values?.loadingDT
		const freightType = values?.shipmentInfo?.freightType
		const freightWeight = values?.grossFreightWeight || values?.shipmentInfo?.freightWeight

		const shipmentInfo = (label, value) => (
			<div className='w-1/2 pb-4 pr-4'>
				<p className='text-xs font-semibold text-gray-500'>{label}</p>
				<p className='mt-1 font-medium'>{value}</p>
			</div>
		)

		return (
			<>
				<div className='flex h-12 items-center rounded-t-md border-b bg-gray-50 px-4'>
					<Icon path={mdiTextBoxOutline} size={0.8} className='mr-2 text-gray-500' />
					<p className='font-semibold text-primary-800'>Shipping Information</p>
				</div>
				<div className='flex flex-wrap p-4 pb-0 pr-0'>
					{docNumber && shipmentInfo(`${SegmentsEnum[segment]?.docType} Number`, docNumber)}
					{freightType && shipmentInfo('Commodity', freightType)}
					{freightWeight && shipmentInfo('Cargo Weight', `${freightWeight} Tons`)}
					{loadingDT && shipmentInfo('Loading Date', moment(loadingDT).format('DD MMM YYYY'))}
				</div>
			</>
		)
	}

	const ContainerDimensions = () => {
		const containersSpecifics = values?.containersSpecifics && Object.entries(values?.containersSpecifics)
		const selectedContainers = containersSpecifics?.filter((specifics) => specifics?.[1]?.quantity > 0 && specifics?.[0] !== 'xl2020ft')
		const specialRequests = values?.specialRequests
		const pricingInfo = values?.pricingInfo
		return (
			<>
				<div className='flex h-12 items-center border-b bg-gray-50 px-4'>
					<Icon path={mdiInboxMultipleOutline} size={0.8} className='mr-2 text-gray-500' />
					<p className='font-semibold text-primary-800'>Container Dimensions</p>
				</div>
				<div className='px-4 pt-4'>
					<p className='mb-3 text-xs font-semibold text-gray-500'>Selected Container Dimensions</p>
					<div className='flex flex-col items-start'>
						{selectedContainers?.map((specifics) => (
							<div className='flex flex-row'>
								<div className='mb-2 mr-2 flex h-10 items-center rounded bg-gray-100 px-4 font-medium'>
									<span className='mr-2 rounded-full bg-gray-600 px-2 text-white'>{specifics[1]?.quantity}</span>
									{ContainerDimensionsEnum()[specifics[0]]?.text}
								</div>
								{!['shifting', 'destuffing']?.includes(specialRequests?.orderCategory) && isKAM && (
									<div className='mb-2 mr-2 flex h-10 items-center rounded bg-gray-100 px-4 font-medium'>
										{IconText({
											icon: mdiCash,
											size: 0.9,
											text: `PKR ${Intl.NumberFormat('en-US').format(specifics[1]?.finalizedRate)} / Container`,
											textClass: 'font-medium text-xs',
										})}
									</div>
								)}
							</div>
						))}
					</div>
				</div>
				{['shifting', 'destuffing']?.includes(specialRequests?.orderCategory) && (
					<div className='p-4'>
						<p className='mb-3 text-xs font-semibold text-gray-500'>Destuffed and moved on</p>
						<div className='flex flex-row flex-wrap items-start'>
							{values?.vehicleDetails?.map((vehicle) => {
								return (
									<div className='mb-2 mr-2 flex h-10 items-center rounded bg-gray-100 px-4 font-medium'>
										<span className='mr-2 rounded-full bg-gray-600 px-2 text-white'>{vehicle.quantity}</span>
										{VehicleTypeEnum[vehicle.vehicleType]?.text}
									</div>
								)
							})}
							{isKAM && (
								<div className='mb-2 mr-2 flex h-10 items-center rounded bg-gray-100 px-4 font-medium'>
									{IconText({
										icon: mdiCash,
										size: 0.9,
										text: `PKR ${Intl.NumberFormat('en-US').format(pricingInfo?.finalizedRate)} / ${pricingInfo?.finalizedRateUnit}`,
										textClass: 'font-medium text-xs',
									})}
								</div>
							)}
						</div>
					</div>
				)}
			</>
		)
	}

	const VehicleInfo = () => {
		const { vehicleType } = values?.vehicleInfo || {}
		const selectedVehicle = ContainerDimensionsEnum(segment)?.[vehicleType]?.text
		const numberVehicles = values?.specialRequests?.numberVehicles
		const pricingInfo = values?.pricingInfo
		const lotOrder = values?.specialRequests?.lotOrder

		return (
			<>
				<div className='flex h-12 items-center border-b bg-gray-50 px-4'>
					<Icon path={mdiTruckOutline} size={0.8} className='mr-2 text-gray-500' />
					<p className='font-semibold text-primary-800'>Vehicle Information</p>
				</div>
				<div className='p-4'>
					<p className='mb-3 text-xs font-semibold text-gray-500'>Selected Vehicle</p>
					<div className='flex flex-row items-start'>
						<div className='mb-2 mr-2 flex h-10 w-max items-center rounded bg-gray-100 px-4 font-medium'>
							{!isNaN(numberVehicles) && <span className='mr-2 rounded-full bg-gray-600 px-2 text-white'>{numberVehicles}</span>}
							{selectedVehicle}
						</div>
						{isKAM && (
							<div className='mb-2 mr-2 flex h-10 items-center rounded bg-gray-100 px-4 font-medium'>
								{IconText({
									icon: mdiCash,
									size: 0.9,
									text: `PKR ${Intl.NumberFormat('en-US').format(pricingInfo?.finalizedRate)} / ${lotOrder ? 'Tonne' : 'Vehicle'}`,
									textClass: 'font-medium text-xs',
								})}
							</div>
						)}
					</div>
				</div>
			</>
		)
	}
	return (
		<>
			<div className='flex h-full overflow-scroll bg-background px-6 py-4'>
				<div className='mr-6 flex w-6/12 flex-col rounded-md bg-white shadow'>
					<div className='flex h-12 items-center border-b px-4 '>
						<Icon path={mdiSwapVerticalVariant} size={0.8} className='mr-2 text-gray-500' />
						<p className='font-semibold text-primary-800'>Route Information</p>
					</div>
					<div className='p-6'>
						<RouteTimeline record={prepareTimelineData()} />
					</div>
				</div>
				<div className='w-6/12 rounded-md bg-white shadow'>
					{ShipmentInfoCard()}
					{segment === 'LongHaul' ? VehicleInfo() : ContainerDimensions()}
				</div>
			</div>
		</>
	)
}

export default OrderSummaryModalSteps
