import { useMemo } from 'react'

export const LeftDOTS = 'L'
export const RightDOTS = 'R'

const range = (start, end) => {
	return Array.from({ length: end - start + 1 }, (_, i) => i + start)
}

export const usePagination = (props) => {
	const { totalCount, pageSize, siblingCount = 1, currentPage } = props
	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize)
		const totalPageNumber = siblingCount + 5

		if (totalPageCount === 0) {
			return range(1, 1)
		}
		if (totalPageCount <= totalPageNumber) {
			return range(1, totalPageCount)
		}

		const firstPageIndex = 1
		const lastPageIndex = totalPageCount
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)
		const showLeftDots = leftSiblingIndex > 2
		const showRightDots = rightSiblingIndex < totalPageCount - 2

		if (!showLeftDots && showRightDots) {
			const leftItemCount = 2 * siblingCount + 3
			const leftRange = range(1, leftItemCount)
			return [...leftRange, RightDOTS, totalPageCount]
		} else if (showLeftDots && !showRightDots) {
			const rightItemCount = 3 + 2 * siblingCount
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
			return [firstPageIndex, LeftDOTS, ...rightRange]
		} else if (showLeftDots && showRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex)
			return [firstPageIndex, LeftDOTS, ...middleRange, RightDOTS, lastPageIndex]
		}
	}, [totalCount, pageSize, siblingCount, currentPage])
	return paginationRange
}
