import { atom } from 'recoil'

export const superUsersAtom = atom({ key: 'superUsersAtom', default: ['szaidi@bridgelinxpk.com', 'experience@bridgelinxpk.com'] })

export const userAtom = atom({ key: 'userAtom', default: undefined })
export const lanesAtom = atom({ key: 'lanesAtom', default: [] })
export const rfqAtom = atom({ key: 'rfqAtom', default: {} })
export const activeCorporateAtom = atom({ key: 'activeCorporateAtom', default: {} })
export const corporatesAtom = atom({ key: 'corporatesAtom', default: [] })
export const terminalsAtom = atom({ key: 'terminalsAtom', default: [] })
export const portsAtom = atom({ key: 'portsAtom', default: [] })
export const shippingLinesAtom = atom({ key: 'shippingLinesAtom', default: [] })

export const importOrdersAtom = atom({ key: 'importOrdersAtom', default: [] })
export const exportOrdersAtom = atom({ key: 'exportOrdersAtom', default: [] })
export const longHaulOrdersAtom = atom({ key: 'longHaulOrdersAtom', default: [] })
