import { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useRecoilValue } from 'recoil'
import { emptyContainerSelector } from '../../recoil/selectors'

import LocationsCard from './LocationsCard'
import ShipmentDetails from './ShipmentDetails'
import ContainerInfo from './ContainerInfo'
import FreightDetails from './FreightDetails'
import VehicleInfo from './VehicleInfo'
import OtherRequestsInfo from './OtherRequestsInfo'
import SelectVehiclesCard from './SelectVehiclesCard'
import ShiftingRateCalculationCard from './ShiftingRateCalculationCard'
import LotRateCalculationCard from './LotRateCalculationCard'
import { handleScroll } from '../../utils/Functions'

const OrderCreationForm = ({
	form,
	watcher,
	segment,
	userType,
	corporate,
	orderIndex,
	shippingLines,
	setPromiseLoading,
	vehicleCounter,
	setVehicleCounter,
	setContainerCounter,
	containerCounter,
    messageApi,
    setPointerEvents,
}) => {
	const uuid = useMemo(() => uuidv4(), [])
	const terminals = useRecoilValue(emptyContainerSelector)

	const [lotOrder, setLotOrder] = useState(true)
	const [destuffingOrder, setDestuffingOrder] = useState(false)

	useEffect(() => {
		handleScroll({ scrollToClass: '.scroll-top' })
	}, [])

	useEffect(() => {
		if (watcher && ['shifting', 'destuffing']?.includes(watcher?.[orderIndex]?.specialRequests?.orderCategory)) {
			setDestuffingOrder(true)
			form?.setFieldsValue({
				[orderIndex]: {
					specialRequests: {
						...watcher?.[orderIndex]?.specialRequests,
						doubleTwenty: false,
					},
					containersSpecifics: {
						...watcher?.[orderIndex]?.containersSpecifics,
						xl2020ft: undefined,
					},
				},
			})
		}
		if (watcher && !['shifting', 'destuffing']?.includes(watcher?.[orderIndex]?.specialRequests?.orderCategory)) {
			setDestuffingOrder(false)
		}
		if (
			watcher &&
			['shifting', 'destuffing']?.includes(watcher?.[orderIndex]?.specialRequests?.orderCategory) &&
			watcher?.[orderIndex]?.pricingInfo?.quotedRateUnit
		) {
			let vehicleDetails = watcher?.[orderIndex]?.vehicleDetails
			Object?.keys(vehicleDetails)?.forEach(
				(index) => (vehicleDetails[index] = { ...vehicleDetails[index], quotedRateUnit: watcher?.[orderIndex]?.pricingInfo?.quotedRateUnit })
			)
			form.setFieldsValue({
				[orderIndex]: { vehicleDetails: vehicleDetails },
			})
		}
		if (
			watcher &&
			watcher?.[orderIndex]?.containersSpecifics?.['xl20ft']?.quantity &&
			watcher?.[orderIndex]?.containersSpecifics?.['xl20ft']?.quantity < 2
		) {
			form?.setFieldsValue({
				[orderIndex]: {
					specialRequests: {
						...watcher?.[orderIndex]?.specialRequests,
						doubleTwenty: false,
					},
					containersSpecifics: {
						...watcher?.[orderIndex]?.containersSpecifics,
						xl2020ft: undefined,
					},
				},
			})
		}
		if (watcher && watcher?.[orderIndex]?.specialRequests?.doubleTwenty === false) {
			form?.setFieldsValue({
				[orderIndex]: {
					containersSpecifics: {
						...watcher?.[orderIndex]?.containersSpecifics,
						xl2020ft: undefined,
					},
				},
			})
		}
		if (watcher && watcher?.[orderIndex]?.specialRequests?.lotOrder !== undefined) {
			setLotOrder(watcher?.[orderIndex]?.specialRequests?.lotOrder)
		}
	}, [form, watcher]) // eslint-disable-line

	return (
		<>
			{<LocationsCard orderIndex={orderIndex} segment={segment} terminals={terminals} form={form} corporate={corporate} />}
			{['Import', 'Export'].includes(segment) &&
				ShipmentDetails({ orderIndex, segment: segment, form, documentId: uuid, setPromiseLoading, terminals, shippingLines, messageApi, setPointerEvents })}
			{['Import', 'Export'].includes(segment) &&
				ContainerInfo({ orderIndex, segment: segment, isKAM: userType === 'kam', form, containerCounter, setContainerCounter })}
			{userType === 'kam' && destuffingOrder && SelectVehiclesCard({ orderIndex, form, watcher, vehicleCounter, setVehicleCounter })}
			{userType === 'kam' && destuffingOrder && ShiftingRateCalculationCard({ form, orderIndex })}
			{segment === 'LongHaul' && VehicleInfo({ orderIndex, segment: segment, form, lotOrder, containerCounter, setContainerCounter })}
			{segment === 'LongHaul' && FreightDetails({ orderIndex, segment: segment, lotOrder, isKAM: userType === 'kam' })}
			{segment === 'LongHaul' && userType === 'kam' && lotOrder && LotRateCalculationCard({ orderIndex })}
			{OtherRequestsInfo({ orderIndex, segment: segment, isKAM: userType === 'kam' })}
		</>
	)
}

export default OrderCreationForm
