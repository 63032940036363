import React, { useMemo } from 'react'
import { SegmentsEnum } from '../../utils/Enums'

const TrackingCountCard = (props) => {
	const { selectedRecord } = props

	const statusCount = useMemo(() => {
		const countEnum = structuredClone(SegmentsEnum[selectedRecord?.type]?.trackingEnum)
		selectedRecord?.subOrders?.forEach((order) => {
			const { driverStatusHistory } = order || {}
			if (driverStatusHistory?.length > 0) {
				const lastStatus = driverStatusHistory[0]
				if (!!countEnum[lastStatus?.status]) {
					const lastCount = countEnum[lastStatus?.status].count
					countEnum[lastStatus?.status].count = lastCount > 0 ? lastCount + 1 : 1
				} else {
					const lastCount = countEnum['trackingNotStarted'].count
					countEnum['trackingNotStarted'].count = lastCount > 0 ? lastCount + 1 : 1
				}
			} else {
				const lastCount = countEnum['trackingNotStarted'].count
				countEnum['trackingNotStarted'].count = lastCount > 0 ? lastCount + 1 : 1
			}
		})
		const filteredCount = Object.values(countEnum)?.filter((status) => status?.count > 0)
		return filteredCount
	}, [selectedRecord])

	const SummaryBlock = (label, count, divClass) => (
		<div className={`flex w-full flex-col items-center border-r last:border-0 ${divClass} `}>
			<p className='text-3xl font-bold text-primary-800'>{count}</p>
			<p className='text-xs font-medium text-gray-500'>{label}</p>
		</div>
	)

	return (
		<div className='absolute bottom-2 w-full p-4'>
			{statusCount?.length > 0 &&
				+(<div className='flex justify-between rounded-md bg-white/60 py-4 '>{statusCount?.map((status) => SummaryBlock(status?.long, status?.count))}</div>)}
		</div>
	)
}

export default TrackingCountCard
