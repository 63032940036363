import React from 'react'

import { RenderSchema } from '../../utils/FormRenderer'
import { FreightDetailsSchema } from '../../utils/FormSchemas'

const FreightDetails = (props) => {
	return (
		<>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Freight Details</p>
			<div className='mb-12 rounded-lg border bg-white p-6'>{RenderSchema(FreightDetailsSchema(props))}</div>
		</>
	)
}

export default FreightDetails
