// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import Icon from '@mdi/react'
import { Dropdown, Menu, Button } from 'antd'
import { mdiArrowDownDropCircle } from '@mdi/js'
// HELPERS
import { SegmentsEnum } from '../../utils/Enums'
import { useNavigate, useParams } from 'react-router-dom'

const PlaceOrderButton = (props) => {
	const { activeCorporateId, userType } = useParams()
	const navigator = useNavigate()
	const DropdownMenu = () => (
		<Menu>
			{Object.values(SegmentsEnum)?.map((segment) => (
				<Menu.Item
					key={segment?.key}
					className='h-12 font-medium hover:bg-primary-50 hover:text-primary-800'
					onClick={() => navigator(`/shipments/${userType}/${activeCorporateId}/${segment?.key}/create`)}
				>
					{segment?.text}
				</Menu.Item>
			))}
		</Menu>
	)

	return (
		<Dropdown overlay={DropdownMenu()} trigger={['click']} className='cursor-pointer'>
			<Button className='btn-primary flex items-center'>
				<p className='mr-3'>PLACE ORDER</p>
				<Icon path={mdiArrowDownDropCircle} size={0.8} className='text-white' />
			</Button>
		</Dropdown>
	)
}

export default PlaceOrderButton
