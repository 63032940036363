import React, { useEffect, useState } from 'react'
// import BridgelinxLogo from '../logo.png'

import { Form } from 'antd'

import { useAuth } from '../hooks/auth'
import Signup from '../components/authentication/Signup'
import Error from '../components/authentication/Error'
import Loading from '../components/utility/Loading'

import { firestore } from '../firebase/firebaseConfig'
import { useParams } from 'react-router-dom'

const Authentication = () => {
	const { id } = useParams()
	const [loading, setLoading] = useState(false)
	const [status, setStatus] = useState('verifying')
	const [inviteData, setInviteData] = useState({})
	const [form] = Form.useForm()

	const { authorization } = useAuth(setLoading, setStatus)

	useEffect(() => verifyInviteLink(), []) // eslint-disable-line

	const verifyInviteLink = async () => {
		const inviteQuery = await firestore.collection('invites').doc(id).get()
		const invite = inviteQuery.data()
		if (invite?.status === 'unprocessed') {
			form.setFieldsValue(invite)
			setInviteData(invite)
			setStatus('verified')
		} else setStatus('invalid')
	}

	return (
		<div className='h-full w-full overflow-scroll bg-background'>
			<Form
				key='Authentication'
				layout='vertical'
				className='flex-column-box h-full w-full'
				form={form}
				onFinish={(values) => {
					return authorization({
						signup: true,
						inviteId: id,
						name: values?.name,
						email: values?.email,
						password: values?.password,
						businessId: inviteData?.businessId,
					})
				}}
			>
				{status === 'verified' && <Signup />}
				{status === 'verifying' && (
					<Loading
						headline='Verifying Invite Link'
						message='We are verifying your invite link and fetching related information.             '
						showButton={false}
					/>
				)}
				{status === 'invalid' && (
					<Error
						headline='Invalid Invite Link'
						message='The invite link is invalid. Please ensure that the unique invite link is correct and has not been used already.'
						showButton={false}
					/>
				)}
				{loading && (
					<Loading
						headline='Setting up your account'
						message='We are creating your profile and shortly you will be navigated to your company’s dashboard.'
						showButton={false}
					/>
				)}
			</Form>
		</div>
	)
}

export default Authentication
