import {
	mdiInboxArrowDownOutline,
	mdiInboxArrowUpOutline,
	mdiWidgetsOutline,
	mdiTruckMinusOutline,
	mdiProgressCheck,
	mdiTruckCheckOutline,
	mdiCancel,
} from '@mdi/js'
import { CREATE_IMPORT_ORDER, CREATE_EXPORT_ORDER, CREATE_LONGHAUL_ORDER, UPDATE_CORPORATE_EMAILS } from '../graphql/mutations'
import { GET_ALL_ORDERS, GET_ALL_LOCATIONS_BY_TYPE, GET_CORPORATE_BY_BUSINESS_CODE, GET_ALL_CORPORATES, GET_ALL_LANES } from '../graphql/queries'
import { ORDER_UPDATED_BY_ID, UPDATE_ORDER_SUBSCRIPTION } from '../graphql/subscriptions'

import { activeCorporateAtom, exportOrdersAtom, importOrdersAtom, lanesAtom, longHaulOrdersAtom, portsAtom, terminalsAtom } from '../recoil/atoms'
import OrderCreationForm from '../components/newOrder/OrderCreationForm'
import OrderSummaryModalSteps from '../components/modals/OrderSummaryModalSteps'
import { useCreateOrder } from '../hooks/modalFinishHooks'

export const GraphQLEnums = {
	Orders: {
		fetch: {
			default: {
				name: GET_ALL_ORDERS,
				key: ['orders'],
				getVariables: ({ id, businessCode, type, rfqId, statuses }) => ({
					options: { sort: [{ createdAt: 'DESC' }] },
					where: {
						...(id && { _id: id }),
						...(rfqId && { RfqId: rfqId }),
						businessCode: businessCode,
						type: type,
						...(statuses && {
							ParentOrder: {
								[GraphQLEnums?.Orders?.types?.[type]?.parentType]: {
									status_IN: statuses,
								},
							},
						}),
					},
					conditions: {
						Import: type === 'Import',
						Export: type === 'Export',
						LongHaul: type === 'LongHaul',
					},
				}),
			},
		},
		subscribe: {
			default: { name: UPDATE_ORDER_SUBSCRIPTION, key: ['OrderUpdatedByTypeAndBusinessCode'] },
			updatedById: { name: ORDER_UPDATED_BY_ID, key: ['OrderUpdatedById'] },
		},
		create: {
			Import: {
				function: CREATE_IMPORT_ORDER,
				functionInput: 'createImportOrderInput',
				functionOutput: 'createImportOrder',
			},
			Export: {
				function: CREATE_EXPORT_ORDER,
				functionInput: 'createExportOrderInput',
				functionOutput: 'createExportOrder',
			},
			LongHaul: {
				function: CREATE_LONGHAUL_ORDER,
				functionInput: 'createLongHaulOrderInput',
				functionOutput: 'createLongHaulOrder',
			},
		},
		update: {},
		atoms: {
			Import: importOrdersAtom,
			Export: exportOrdersAtom,
			LongHaul: longHaulOrdersAtom,
		},
		types: {
			Import: {
				parentType: 'ParentImport',
				childType: 'SubImport',
				childrenType: 'SubImports',
			},
			Export: {
				parentType: 'ParentExport',
				childType: 'SubExport',
				childrenType: 'SubExports',
			},
			LongHaul: {
				parentType: 'ParentLongHaul',
				childType: 'SubLongHaul',
				childrenType: 'SubLongHauls',
			},
		},
	},
	Lanes: {
		key: 'Lanes',
		fetch: {
			default: {
				name: GET_ALL_LANES,
				key: ['lanes'],
				getVariables: () => ({
					where: {
						AND: [
							{
								from: {
									ParentRegion_NOT: null,
								},
							},
							{
								to: {
									ParentRegion_NOT: null,
								},
							},
						],
					},
				}),
			},
		},
		atoms: {
			lanes: lanesAtom,
		},
	},
	Corporates: {
		fetch: {
			corporateData: {
				name: GET_CORPORATE_BY_BUSINESS_CODE,
				key: ['corporates', '0'],
				getVariables: ({ businessCode }) => ({
					where: { businessCode: businessCode },
				}),
			},
			corporates: {
				name: GET_ALL_CORPORATES,
				key: ['corporates'],
			},
			clientCorporates: {
				name: GET_ALL_CORPORATES,
				key: ['clients', '0', 'Corporate'],
				getVariables: ({ _id }) => ({
					where: { User: { _id: _id } },
				}),
			},
		},
		atoms: {
			corporateData: activeCorporateAtom,
		},
		update: {
			corporateEmails: {
				function: UPDATE_CORPORATE_EMAILS,
				functionInput: 'updateCorporateInput',
				functionOutput: 'updateCorporate',
			},
		},
	},
	Locations: {
		fetch: {
			default: {
				name: GET_ALL_LOCATIONS_BY_TYPE,
				key: ['locations'],
				getVariables: ({ type }) => ({
					where: { type: type },
				}),
			},
		},
		atoms: {
			port: portsAtom,
			terminal: terminalsAtom,
		},
	},
}

export const SegmentsEnum = {
	Import: {
		key: 'Import',
		text: 'Import',
		long: 'Import Orders',
		initial: 'I',
		atomName: 'importOrdersAtom',
		collectionName: 'Import',
		icon: mdiInboxArrowDownOutline,
		shortCode: 'IMP',
		textColor: '#3C7D8B',
		bgColor: '#E2F6FB',
		docType: 'BL',
		docTypeText: 'Bill of Lading',
		createOrder: CREATE_IMPORT_ORDER,
		getOrders: GET_ALL_ORDERS,
		atom: importOrdersAtom,
		queryOutput: 'getAllOrdersByCorporateAndOrderStatusAndType',
		trackingEnum: {
			trackingNotStarted: { order: 0, key: 'trackingNotStarted', long: 'Tracking not Started' },
			driverMovingToPickupLocation: { order: 1, key: 'driverMovingToPickupLocation', long: 'Moving To Port' },
			driverReachedPickupLocation: { order: 2, key: 'driverReachedPickupLocation', long: 'At Port' },
			driverMovingToDropoffLocation: { order: 3, key: 'driverMovingToDropoffLocation', long: 'On Route' },
			driverReachedDropoffLocation: { order: 4, key: 'driverReachedDropoffLocation', long: 'At Dropoff Location' },
			driverMovingToContainerDropoffLocation: { order: 5, key: 'driverMovingToContainerDropoffLocation', long: 'Moving to Container Terminal' },
			driverReachedContainerDropoffLocation: { order: 6, key: 'driverReachedContainerDropoffLocation', long: 'At Terminal' },
		},
		filterProperties: ['preparedOrderNumber', 'driverPhone', 'driverVehicleRegistration', 'docNumber', 'containerNumber', 'biltyNumber', 'shippingLine'],
	},
	Export: {
		key: 'Export',
		text: 'Export',
		long: 'Export Orders',
		initial: 'E',
		atomName: 'exportOrdersAtom',
		collectionName: 'Export',
		icon: mdiInboxArrowUpOutline,
		shortCode: 'EXP',
		textColor: '#467D48',
		bgColor: '#E4F7E5',
		docType: 'CRO',
		docTypeText: 'Container Release Order',
		createOrder: CREATE_EXPORT_ORDER,
		getOrders: GET_ALL_ORDERS,
		atom: exportOrdersAtom,
		queryOutput: 'getAllOrdersByCorporateAndOrderStatusAndType',
		trackingEnum: {
			trackingNotStarted: { order: 0, key: 'trackingNotStarted', long: 'Tracking not Started' },
			driverMovingToContainerTerminal: { order: 1, key: 'driverMovingToContainerDropoffLocation', long: 'Moving to Container Terminal' },
			driverReachedContainerTerminal: { order: 2, key: 'driverReachedContainerDropoffLocation', long: 'At Terminal' },
			driverMovingToPickupLocation: { order: 3, key: 'driverMovingToPickupLocation', long: 'Moving To Loading' },
			driverReachedPickupLocation: { order: 4, key: 'driverReachedPickupLocation', long: 'At Loading Location' },
			driverMovingToDropoffLocation: { order: 5, key: 'driverMovingToDropoffLocation', long: 'On Route' },
			driverReachedDropoffLocation: { order: 6, key: 'driverReachedDropoffLocation', long: 'At Port' },
		},
		filterProperties: ['preparedOrderNumber', 'driverPhone', 'driverVehicleRegistration', 'docNumber', 'containerNumber', 'biltyNumber', 'shippingLine'],
	},
	LongHaul: {
		key: 'LongHaul',
		text: 'Bulk & Local',
		long: 'Bulk & Local Orders',
		initial: 'L',
		atomName: 'longHaulOrdersAtom',
		collectionName: 'LongHaul',
		icon: mdiWidgetsOutline,
		shortCode: 'BUL',
		textColor: '#7558B4',
		bgColor: '#F0E9FF',
		createOrder: CREATE_LONGHAUL_ORDER,
		getOrders: GET_ALL_ORDERS,
		atom: longHaulOrdersAtom,
		queryOutput: 'getAllOrdersByCorporateAndOrderStatusAndType',
		trackingEnum: {
			trackingNotStarted: { order: 0, key: 'trackingNotStarted', long: 'Tracking not Started' },
			driverMovingToPickupLocation: { order: 3, key: 'driverMovingToPickupLocation', long: 'Moving To Loading' },
			driverReachedPickupLocation: { order: 4, key: 'driverReachedPickupLocation', long: 'At Loading Location' },
			driverMovingToDropoffLocation: { order: 5, key: 'driverMovingToDropoffLocation', long: 'Moving To Dropoff' },
			driverReachedDropoffLocation: { order: 6, key: 'driverReachedDropoffLocation', long: 'At Dropoff' },
		},
		filterProperties: ['preparedOrderNumber', 'driverPhone', 'driverVehicleRegistration', 'biltyNumber'],
	},
}

export const orderStatusEnum = {
	'Order Pending': {
		key: 'Order Pending',
		text: 'Pending Assignment',
		background: '#F8F8F8',
		color: '#676767',
		icon: mdiTruckMinusOutline,
	},
	'Order Processing': {
		key: 'Order Processing',
		text: 'Pending Assignment',
		background: '#F8F8F8',
		color: '#676767',
		icon: mdiTruckMinusOutline,
	},
	'Order Confirmed': {
		key: 'Order Confirmed',
		text: 'Pending Assignment',
		background: '#F8F8F8',
		color: '#676767',
		icon: mdiTruckMinusOutline,
	},
	'Order Ongoing': {
		key: 'Order Ongoing',
		text: 'In Transit',
		background: '#FFF1E2',
		color: '#FF8100',
		icon: mdiProgressCheck,
	},
	'Order Completed': {
		key: 'Order Completed',
		text: 'Completed',
		background: '#E4F7E5',
		color: '#3D8E40',
		icon: mdiTruckCheckOutline,
	},
	'Order Cancelled': {
		key: 'Order Cancelled',
		text: 'Cancelled',
		background: '#FFEBEE',
		color: '#F44336',
		icon: mdiCancel,
	},
}

export const ContainerDimensionsEnum = (segment) => ({
	xl20ft: { key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#xl20ft' },
	xl40ft: { key: 'xl40ft', text: '40 Ft Standard', long: '40 Feet', icon: '#xl40ft' },
	xl40ftHC: { key: 'xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC' },
	xl40ftOT: { key: 'xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT' },
	...(segment === 'LongHaul'
		? {
				flatbed: { key: 'flatbed', text: 'Flatbed', long: 'Flatbed', icon: '#flatbed' },
				dumper: { key: 'dumper', text: 'Dumper', long: 'Dumper', icon: '#dumper' },
				halfBody: { key: 'halfBody', text: 'Half Body', long: 'Half Body', icon: '#halfBody' },
		  }
		: {}),
	other: { key: 'other', text: 'Other', long: 'Other', icon: '#other' },
})

export const OrderCategoryEnum = {
	directSeal: { key: 'directSeal', text: 'Direct Seal', category: 'Direct Seal' },
	shifting: { key: 'shifting', text: 'Shifting', category: 'Shifting' },
	destuffing: { key: 'destuffing', text: 'Destuffing', category: 'Destuffing' },
}

export const VehicleTypeEnum = {
	'Flatbed-Large': { key: 'Flatbed-Large', text: '45ft Flatbed', long: '45 Feet Flatbed', icon: '#other' },
	'Flatbed-Medium': { key: 'Flatbed-Medium', text: '43ft Flatbed', long: '43 Feet Flatbed', icon: '#other' },
	'Flatbed-Small': { key: 'Flatbed-Small', text: 'Small Flatbed', long: 'Small Flatbed', icon: '#other' },
	'Lowbed-Standard': { key: 'Lowbed-Standard', text: 'Lowbed', icon: '' },
	'Lowbed-MultiAxle': { key: 'Lowbed-MultiAxle', text: 'Multi-Axle Lowbed', icon: '' },
	'Trolly-xl20ft': { key: 'Trolly-xl20ft', text: '20 Feet Trolly', long: '20 Feet Trolly', icon: '#xl20ft' },
	'Trolly-xl40ft': { key: 'Trolly-xl40ft', text: '40 Feet Trolly', long: '40 Feet Trolly', icon: '#xl40ft' },
}

export const VehicleTypeEnumLongHaul = {
	flatbed: { key: 'flatbed', text: 'Flatbed', long: 'Flatbed', icon: '#flatbed' },
	dumper: { key: 'dumper', text: 'Dumper', long: 'Flatbed', icon: '#dumper' },
	halfBody: { key: 'halfBody', text: 'Half Body', long: 'Flatbed', icon: '#halfBody' },
	other: { key: 'other', text: 'Other', long: 'Flatbed', icon: '' },
}

export const SearchoptionsEnum = {
	preparedOrderNumber: { key: 'preparedOrderNumber', value: 'Order Number' },
	driverPhone: { key: 'driverPhone', value: 'Driver Phone' },
	driverVehicleRegistration: { key: 'driverVehicleRegistration', value: 'Vehicle Number' },
	containerNumber: { key: 'containerNumber', value: 'Container Number' },
	docNumber: { key: 'docNumber', value: 'Document Number' },
	shippingLine: { key: 'shippingLine', value: 'Shipping Line' },
	biltyNumber: { key: 'biltyNumber', value: 'Bilty Number' },
	phoneNumber: { key: 'phoneNumber', value: 'Phone Number' },
}

export const PageSizes = {
	10: { frontend: 10, backend: 50 },
	20: { frontend: 20, backend: 100 },
	50: { frontend: 50, backend: 250 },
	100: { frontend: 100, backend: 500 },
}

export const locationsEnum = {
	Import: {
		loading: { key: 'loading', text: 'Loading Port', color: 'bg-primary-800', multiple: false, pocType: 'Clearing Agent' },
		dropoff: { key: 'dropoff', text: 'Dropoff Location', color: 'bg-red-500', multiple: true, pocType: 'Point of Contact' },
		terminal: { key: 'terminal', text: 'Empty Container Drop Off', color: 'bg-gray-500', multiple: true, pocType: 'Point of Contact' },
	},
	Export: {
		terminal: { key: 'terminal', text: 'Release Terminal', color: 'bg-gray-500', multiple: false, pocType: 'Point of Contact' },
		loading: { key: 'loading', text: 'Loading Location', color: 'bg-primary-800', multiple: true, pocType: 'Point of Contact' },
		dropoff: { key: 'dropoff', text: 'Dropoff Port', color: 'bg-red-500', multiple: false, pocType: 'Clearing Agent' },
	},
	Longhaul: {
		loading: { key: 'loading', text: 'Loading Location', color: 'bg-primary-800', multiple: false, pocType: 'Point of Contact' },
		dropoff: { key: 'dropoff', text: 'Dropoff Location', color: 'bg-red-500', multiple: true, pocType: 'Point of Contact' },
	},
}

export const OrderCreationEnum = {
	OrderForm: ({ segment, rfqData }) =>
		segment !== 'LongHaul' && Object.values(rfqData?.shipmentInfo || {})?.length > 1
			? Object.values(rfqData?.shipmentInfo || {})?.map((_, index) => ({
					title: `Order ${index + 1}`,
					watchField: 'all',
					width: 'w-full',
					columns: [{ widthClassName: 'w-full', element: OrderCreationForm }],
					buttons: [
						{ type: 'back', title: 'GO BACK', className: 'btn-back' },
						Object.values(rfqData?.shipmentInfo)?.length - 1 !== index
							? { type: 'validateNext', title: 'Next', className: 'btn-primary' }
							: { type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
					],
			  }))
			: [
					{
						title: '',
						watchField: 'all',
						width: 'w-full',
						columns: [{ widthClassName: 'w-full', element: OrderCreationForm }],
						buttons: [
							{ type: 'back', title: 'GO BACK', className: 'btn-back' },
							{ type: 'submit', title: 'SUBMIT DETAILS', className: 'btn-primary' },
						],
					},
			  ],

	SummaryModal: ({ values }) =>
		Object.values(values || {})?.length > 1
			? Object.values(values || {})
					?.filter((value) => value)
					?.map((_, index) => ({
						title: `Order ${index + 1}`,
						watchField: 'all',
						width: 'w-full',
						useFinishFunction: useCreateOrder,
						columns: [{ widthClassName: 'w-full', element: OrderSummaryModalSteps }],
						buttons: [
							...(index === 0 ? [{ type: 'back', title: 'GO BACK', className: 'btn-delete' }] : []),
							{ type: 'submit', title: 'PLACE ORDER', className: 'btn-place-order' },
						],
					}))
			: [
					{
						title: '',
						watchField: 'all',
						width: 'w-full',
						useFinishFunction: useCreateOrder,
						columns: [{ widthClassName: 'w-full', element: OrderSummaryModalSteps }],
						buttons: [
							{ type: 'cancel', title: 'GO BACK', className: 'btn-delete' },
							{ type: 'submit', title: 'PLACE ORDER', className: 'btn-place-order' },
						],
					},
			  ],
}
