// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import { Button } from 'antd'
import Icon from '@mdi/react'
import { mdiEye } from '@mdi/js'
// ASSETS
import Sprite from '../../assets/icons/Sprite.svg'

const DocumentsCard = (props) => {
	const { documents } = props.record || {}

	const DocumentBlock = (document) => {
		return (
			<div className='mr-4 mb-4 w-[calc(50%-1.5rem)] rounded-lg border p-4'>
				<svg className='h-12 w-12'>
					<use href={Sprite + '#PDF'} />
				</svg>
				<p className='mt-4 font-semibold'>{document?.text}</p>
				<Button className='btn-header-active mt-4 flex !h-11 items-center justify-center' onClick={() => window.open(document?.document, '_blank')} block>
					<Icon path={mdiEye} size={1} className='mr-2 text-primary-800' />
					VIEW
				</Button>
			</div>
		)
	}
	return (
		<div className='flex w-full flex-wrap items-center rounded-md bg-white p-6 shadow'>{documents?.map((document) => DocumentBlock(document))}</div>
	)
}

export default DocumentsCard
