import React from 'react'

import { RenderSchema } from '../../utils/FormRenderer'
import { DoIssuanceSchema, DocumentInfoSchema, DocumentUpload } from '../../utils/FormSchemas'

import Sprite from '../../assets/icons/Sprite.svg'

const ShipmentDetails = (props) => {
	const { orderIndex, segment, documentId, setPromiseLoading, terminals, shippingLines, messageApi, setPointerEvents } = props

	return (
		<>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Shipment Information</p>
			<div className='mb-12 rounded-lg border bg-white'>
				{segment === 'Import' && (
					<div className='flex flex-col border-b px-6 pt-3' style={{ minHeight: 48 }}>
						{RenderSchema(
							DoIssuanceSchema({
								orderIndex,
								segment,
								shippingLines,
								terminals: terminals?.map((terminal) => ({ formattedAddress: terminal?.formattedAddress, _id: terminal?._id })),
							})
						)}
					</div>
				)}
				{segment === 'Export' && <div className='border-b px-6 pt-3'>{RenderSchema(DocumentInfoSchema({ orderIndex, segment, shippingLines }))}</div>}
				{(segment === 'Export' || segment === 'Import') && (
					<div className='flex flex-col items-center justify-center p-6'>
						<svg className='mb-6' style={{ width: 100, height: 90 }}>
							<use href={Sprite + '#Upload'} />
						</svg>
						<p className='font-bold'>Upload {segment === 'Import' ? 'Bill of Lading' : 'Container Release Order (CRO)'} Document</p>
						<p className='mb-6 text-gray-500'>
							{segment === 'Import' ? 'Bill of Lading' : 'Container Release Order'} will help us arrange requried vehicles for you
						</p>
						<div className='Upload flex w-full flex-col items-center justify-center'>
							{RenderSchema(DocumentUpload({ orderIndex, segment, documentId, setLoading: setPromiseLoading, messageApi, setPointerEvents }))}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default ShipmentDetails
