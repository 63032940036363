import React from 'react'
import { Form, Button, Input } from 'antd'

const Signup = (props) => {
	return (
		<div className='w-2/5 rounded-lg bg-white p-12'>
			<p className='mb-3 text-3xl font-semibold'>Sign up to access your account </p>
			<p className='mb-6 text-base font-medium text-gray-400'>This unique link will expire as soon as you create your account.</p>
			<Form.Item key='name' name='name' label='Full Name' className='mb-3' rules={[{ required: true, message: 'Please enter your full name.' }]}>
				<Input className='h-12 rounded' placeholder='Enter your full name' />
			</Form.Item>
			<Form.Item key='email' name='email' label='Email Address' className='mb-3' rules={[{ required: true, message: 'Email is required.' }]} dis>
				<Input className='h-12 rounded' placeholder='johndoe@gmail.com' disabled />
			</Form.Item>
			<Form.Item key='password' name='password' label='Password' className='mb-4' rules={[{ required: true, message: 'Please enter your password.' }]}>
				<Input.Password className='h-12 rounded' placeholder='Enter your password' />
			</Form.Item>
			<Form.Item
				key='confirmPassword'
				name='confirmPassword'
				label='Confirm Password'
				dependencies={['password']}
				className='mb-8'
				rules={[
					{
						required: true,
						message: 'Re-enter your password for confirmation.',
					},
					({ getFieldValue }) => ({
						validator: async (_, value) => {
							return !value || getFieldValue('password') === value
								? Promise.resolve()
								: Promise.reject(new Error('The two passwords that you entered do not match.'))
						},
						validateTrigger: 'onSubmit',
					}),
				]}
			>
				<Input.Password className='h-12 rounded' placeholder='Enter your password' />
			</Form.Item>
			<Form.Item className='mb-0'>
				<Button className='btn-primary' htmlType='submit' block>
					SIGN UP
				</Button>
			</Form.Item>
		</div>
	)
}

export default Signup
