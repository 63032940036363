import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Form, Button, Input, Select } from 'antd'

import Icon from '@mdi/react'
import { mdiContentCopy } from '@mdi/js'

import { firestore } from '../../firebase/firebaseConfig'

import { useRecoilValue } from 'recoil'
import { superUsersAtom, userAtom } from '../../recoil/atoms'
import { corporatesSelector } from '../../recoil/selectors'
// GRAPHQL
import { useLazyQuery } from '@apollo/client'
import { GET_CLIENT } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom'

const InviteMembersModal = () => {
	const [loading, setLoading] = useState(false)
	const [inviteLink, setInviteLink] = useState()
	const [form] = Form.useForm()
	const navigate = useNavigate()

	const user = useRecoilValue(userAtom)
	const superUsers = useRecoilValue(superUsersAtom)
	const corporates = useRecoilValue(corporatesSelector())

	const [getClients] = useLazyQuery(GET_CLIENT)

	const isSuperUser = useMemo(() => superUsers?.includes(user?.User?.email), [superUsers, user])

	useEffect(() => {
		form.resetFields()
		setInviteLink(null)
	}, []) // eslint-disable-line

	// prettier-ignore
	useEffect(
		() => !isSuperUser && form.setFieldsValue({ businessCodeAndId: corporates?.map((corporate) => corporate?.businessCode + '_' + corporate?._id) }),
		[corporates]) // eslint-disable-line

	const generateInviteLink = async (values) => {
		setLoading(true)
		const businessCodes = values.businessCodeAndId.reduce((prev, current) => [...prev, current.split('_')[0]], [])
		const businessIds = values.businessCodeAndId.reduce((prev, current) => [...prev, current.split('_')[1]], [])
		// This could be done in one iteration as well but since the number of business codes will be limited, this cleaner version works too.

		const _id = firestore.collection('invites').doc()?.id
		await firestore.collection('invites').doc(_id).set({
			_id: _id,
			businessCode: businessCodes,
			businessId: businessIds,
			email: values?.email,
			status: 'unprocessed',
		})
		setInviteLink(`${window.location.origin}/auth/signup/${_id}`)
		setLoading(false)
	}

	const copyLink = () => navigator.clipboard.writeText(inviteLink)
	const onCancel = () => navigate(-1)

	const GeneratedLink = () => (
		<>
			<div className='mt-10 flex items-center'>
				<Input className='h-12 rounded' placeholder={inviteLink} disabled />
				<Button className='ml-4 flex items-center p-0 text-primary-800' type='link' onClick={copyLink}>
					<Icon size={0.8} className='mr-1' path={mdiContentCopy} />
					<p className='font-bold'>COPY LINK</p>
				</Button>
			</div>
			<Button className='btn-primary mt-10' block loading={loading} onClick={onCancel}>
				DONE
			</Button>
		</>
	)

	return (
		<Modal open={true} className='InviteMembersModal' footer={null} centered={true} onCancel={onCancel}>
			<div className='p-10'>
				<p className='mb-3 text-3xl font-semibold'>Invite Members</p>
				<p className='mb-6 text-base font-medium text-gray-400'>This unique link will expire as soon as the member will create an account</p>
				<Form key='InviteMembers' layout='vertical' className='w-full' form={form} onFinish={generateInviteLink}>
					<Form.Item
						key='email'
						name='email'
						label='Email Address'
						className='mb-3'
						rules={[
							{
								required: true,
								validator: async (_, value) => {
									const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
									if (expression.test(value)) {
										setLoading(true)
										const promises = [firestore.collection('invites').where('email', '==', value).get(), getClients({ variables: { where: { User: { email: value } } } })]
										const snapshots = await Promise.all(promises)
										const invite = snapshots[0].docs.map((doc) => ({ ...doc.data() }))?.[0]
										const client = snapshots[1]?.data?.clients?.length !== 0
										setLoading(false)
										if (client) {
											return Promise.reject(new Error('A client with this email already exists.'))
										} else if (invite) {
											return Promise.reject(new Error('An invite has already been sent to this account.'))
										} else return Promise.resolve()
									} else return Promise.reject(new Error('Please enter a valid email address.'))
								},
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input className='h-12 rounded' placeholder='johndoe@gmail.com' />
					</Form.Item>
					<Form.Item
						key='businessCodeAndId'
						name='businessCodeAndId'
						label='Allowed Business Accounts'
						className='mb-3'
						rules={[{ required: true, message: 'Select business account to be allowed for the account.' }]}
					>
						<Select className='SelectField CustomHeightSelector' mode='multiple' showSearch allowClear>
							{corporates?.map((corporate) => (
								<Select.Option key={corporate?.businessCode} value={corporate?.businessCode + '_' + corporate?._id}>
									{corporate?.businessNameCode}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{!inviteLink ? (
						<Form.Item className='mb-0 mt-7'>
							<Button className='btn-primary' htmlType='submit' block loading={loading}>
								GENERATE LINK
							</Button>
						</Form.Item>
					) : (
						<GeneratedLink />
					)}
				</Form>
			</div>
		</Modal>
	)
}

export default InviteMembersModal
