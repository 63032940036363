import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { GraphQLEnums } from '../utils/Enums'

import { useSetRecoilState } from 'recoil'
import { userAtom } from '../recoil/atoms'

export const useLazyFetch = ({ type, atomKey, storageKey }) => {
	const [fetchData, { data, loading, error }] = useLazyQuery(GraphQLEnums[`${type}`].fetch?.default?.name)

	useEffect(() => {
		if (error) {
			throw error
		}
	}, [data, error]) // eslint-disable-line

	return [fetchData, loading, data]
}
