import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import { useRecoilValue } from 'recoil'
import SummaryCard from '../components/orderDetails/SummaryCard'
import DynamicTabs from '../components/layout/DynamicTabs'
import SubOrderCard from '../components/orderDetails/SubOrderCard'
import RouteTimeline from '../components/utility/RouteTimeline'
import DocumentsCard from '../components/orderDetails/DocumentsCard'
import TrackingDrawer from '../components/orderDetails/TrackingDrawer'

import TrackingMap from '../components/orderDetails/TrackingMap'
import { getOrderByIdSelector } from '../recoil/selectors'
import { useOutletContext, useParams } from 'react-router-dom'
import { useFetch } from '../hooks/useFetchQuery'

const OrderDetails = () => {
	const { subscribeRef } = useOutletContext
	const { segment, id, subView } = useParams()

	const [selectedVehicle, setSelectedVehicle] = useState()
	const [showTracking, setShowTracking] = useState({ visible: false })
	const selectedRecord = useRecoilValue(getOrderByIdSelector({ id, segment }))

	useFetch({
		type: 'Orders',
		atomKey: segment,
		variables: { id: id, type: segment },
	})

	useEffect(() => {
		return subscribeRef?.current?.('updatedById', { id: id })
	}, [selectedRecord, id]) // eslint-disable-line

	return (
		<Layout className='flex flex-row'>
			<Layout.Content className='flex h-full w-7/12 flex-col border-r'>
				<SummaryCard record={selectedRecord} />
				<DynamicTabs type='orderDetails' record={selectedRecord} segment={subView} />
				<Layout.Content className='h-full overflow-scroll bg-background p-6'>
					{subView === 'details' &&
						selectedRecord &&
						Object.values(selectedRecord?.jobs)?.map((order) =>
							order?.job?.Vehicle ? (
								<SubOrderCard
									key={order?._id}
									order={{ ...order, subOrders: Object.values(order?.subOrders) }}
									setSelectedVehicle={setSelectedVehicle}
									setShowTracking={setShowTracking}
								/>
							) : (
								Object.values(order?.subOrders)?.map((subOrder) => (
									<SubOrderCard
										key={subOrder?._id}
										order={{ job: order?.job, subOrders: [subOrder] }}
										setSelectedVehicle={setSelectedVehicle}
										setShowTracking={setShowTracking}
									/>
								))
							)
						)}
					{subView === 'route' && (
						<div className='w-full rounded-md bg-white p-6 pl-8 pt-8 shadow'>
							<RouteTimeline record={selectedRecord} />
						</div>
					)}
					{subView === 'documents' && selectedRecord?.documents?.length > 0 && <DocumentsCard record={selectedRecord} />}
				</Layout.Content>
			</Layout.Content>
			<Layout.Content className='relative flex h-full w-5/12 flex-col'>
				{showTracking?.visible && <TrackingDrawer showTracking={showTracking} setShowTracking={setShowTracking} />}
				<TrackingMap records={selectedRecord?.jobs} selectedSegment={segment} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />
			</Layout.Content>
		</Layout>
	)
}

export default OrderDetails
