import { Steps } from 'antd'
import { mdiArrowLeft, mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'

const { Step } = Steps

const StepsHeader = ({ pages, page, showBackIcon = false, buttonfuncs }) => {
	return (
		<>
			<div className='flex w-full flex-grow-0 flex-row items-center justify-center bg-white px-8 py-4'>
				{showBackIcon && (
					<div className='mr-6 flex h-8 cursor-pointer items-center' onClick={buttonfuncs['back']}>
						<Icon size={1} className='' path={mdiArrowLeft} />
					</div>
				)}

				<Steps direction='horizontal' size='small' current={page}>
					{pages
						?.filter((page) => !page.resultPage)
						?.map((pageData, index) => (
							<Step
								key={`step-${index}`}
								className='items-center'
								title={<div className='text-[16px] font-[500] text-black-400'>{pageData.title}</div>}
								status={index < page ? 'finish' : 'process'}
								icon={
									index < page ? (
										<div className='mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-primary-800'>
											<Icon path={mdiCheck} className='text-white' size={0.7} />
										</div>
									) : (
										<div
											className={`mr-3 flex h-6 w-6 items-center justify-center rounded-full text-xs font-[500] ${
												index === page ? 'bg-primary-800 !text-white' : 'bg-black-75 !text-white'
											}`}
										>
											<p className=''>{index + 1}</p>
										</div>
									)
								}
							/>
						))}
				</Steps>
			</div>
		</>
	)
}

export default StepsHeader
