import { Form, Input, Checkbox, Divider } from 'antd'
import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import ContainerCosts from './ContainerCosts'
import { useParams } from 'react-router-dom'

const ContainerDetails = (props) => {
	const { userType } = useParams()
	const { segment, orderIndex, form, containerCounter, container, setContainerCounter } = props
	const doIssuance = form?.getFieldValue([orderIndex, 'specialRequests', 'doIssuance'])
	const orderCategory = form?.getFieldValue([orderIndex, 'specialRequests', 'orderCategory'])
	const doubleTwenty = form?.getFieldValue([orderIndex, 'specialRequests', 'doubleTwenty'])
	const isKAM = userType === 'kam'

	const FormItem = (props) => (
		<Form.Item
			key={props.name}
			name={props.name}
			label={props.label}
			className={props.className}
			initialValue={props.initialValue}
			style={props?.style}
			rules={[
				{
					required: props.required,
					validator: (_, value = '') => {
						if (value?.toString().length > 0) {
							if (value < 0) {
								return Promise.reject(new Error(`This value cannot be negative.`))
							} else if (parseInt(value) < props.minValue) {
								return Promise.reject(new Error(`This value cannot be less than ${props.minValue}`))
							} else if (parseInt(value) > props.maxValue) {
								return Promise.reject(new Error(`This value cannot be greater than ${props.maxValue}`))
							} else {
								return Promise.resolve()
							}
						} else if (!props.required) {
							return Promise.resolve(value)
						} else {
							return Promise.reject(new Error(props.message))
						}
					},
				},
			]}
			shouldUpdate={(prevValues, currentValues) => prevValues?.specialRequests?.doubleTwenty !== currentValues?.specialRequests?.doubleTwenty}
		>
			<Input
				className='h-10 rounded'
				disabled={props?.disabled}
				hidden={props?.hidden}
				type={props?.type || 'number'}
				placeholder={props.placeholder}
				maxValue={props?.maxValue}
				minValue={props?.minValue}
				onWheel={(event) => event.currentTarget.blur()}
				addonBefore={props?.addonBefore}
				addonAfter={props?.addonAfter}
			/>
		</Form.Item>
	)

	return (
		<>
			<div className='ml-9 flex flex-row items-start'>
				<div className='relative mt-7 flex w-1/3 justify-center'>
					<Icon
						path={mdiMinusCircle}
						size={1}
						className={`left-counter-button ${containerCounter?.[orderIndex]?.[container.key] <= 1 ? 'text-gray-500' : 'cursor-pointer text-primary-800'}`}
						onClick={() =>
							containerCounter?.[orderIndex]?.[container.key] > 1 &&
							setContainerCounter((prevState) => ({ [orderIndex]: { ...prevState?.[orderIndex], [container.key]: prevState?.[orderIndex]?.[container.key] - 1 } }))
						}
					/>
					<Form.Item
						key={segment === 'LongHaul' ? [orderIndex, 'specialRequests', 'numberVehicles'] : [orderIndex, 'containersSpecifics', container?.key, 'quantity']}
						name={segment === 'LongHaul' ? [orderIndex, 'specialRequests', 'numberVehicles'] : [orderIndex, 'containersSpecifics', container?.key, 'quantity']}
						rules={[
							{
								required: true,
								validator: (_, value = '') => {
									if (value?.toString()?.length > 0) {
										if (parseFloat(value) <= 0) return Promise.reject(new Error(`This value cannot be negative.`))
										else return Promise.resolve()
									} else return Promise.reject(new Error('Quantity is required.'))
								},
							},
						]}
						className='m-0 w-full'
					>
						<Input
							className='QuantityField'
							type='number'
							onInput={(e) => (e.target.value = e.target.value.slice(0, 3))}
							onChange={(e) =>
								setContainerCounter((prevState) => ({ ...prevState, [orderIndex]: { ...prevState?.[orderIndex], [container?.key]: e.target.valueAsNumber } }))
							}
							onWheel={(event) => event.currentTarget.blur()}
						/>
					</Form.Item>
					<Icon
						path={mdiPlusCircle}
						size={1}
						className={`right-counter-button ${containerCounter?.[orderIndex]?.[container.key] >= 999 ? 'text-gray-500' : 'cursor-pointer text-primary-800'}`}
						onClick={() =>
							containerCounter?.[orderIndex]?.[container.key] < 999 &&
							setContainerCounter((prevState) => ({
								...prevState,
								[orderIndex]: { ...prevState?.[orderIndex], [container.key]: prevState?.[orderIndex]?.[container.key] + 1 },
							}))
						}
					/>
				</div>
				{(isKAM || segment !== 'Import') && (
					<div className='ml-6 w-52'>
						{FormItem({
							name: segment === 'LongHaul' ? [orderIndex, 'shipmentInfo', 'freightWeight'] : [orderIndex, 'containersSpecifics', container?.key, 'freightWeight'],
							label: 'Weight Per Container',
							message: 'Weight is Required',
							placeholder: 'Enter Weight',
							addonAfter: 'Tons',
							required: true,
						})}
					</div>
				)}
			</div>
			{container?.key === 'xl20ft' &&
				containerCounter?.[orderIndex]?.[container?.key] > 1 &&
				!['shifting', 'destuffing']?.includes?.(orderCategory) &&
				segment !== 'LongHaul' && (
					<div className='ml-9 mt-2 flex w-full flex-row'>
						<Form.Item
							key={[orderIndex, 'specialRequests', 'doubleTwenty']}
							name={[orderIndex, 'specialRequests', 'doubleTwenty']}
							initialValue={false}
							valuePropName='checked'
						>
							<Checkbox defaultChecked={false} className='outline-teal-50'>
								<p className='ml-2 font-semibold'>Move 20 Feet Containers as Double Twenty</p>
							</Checkbox>
						</Form.Item>
					</div>
				)}
			<div className='ml-9 mt-1 flex flex-col justify-between'>
				{isKAM && doubleTwenty && container?.key === 'xl20ft' ? (
					<>
						<div className='mb-2 text-base font-semibold text-primary-800'>Vehicle Information & Costs</div>
						<div className='flex flex-row'>
							<div className='flex flex-col'>
								<div className='mb-4 mt-2 text-xs font-semibold text-gray-500'>Vehicle Type</div>
								<div className='text-sxs font-semibold'>Double Twenty Truck</div>
							</div>
							{FormItem({
								name: [orderIndex, 'containersSpecifics', 'xl2020ft', 'quantity'],
								label: 'No. of Vehicles',
								message: 'No. of Vehicles is Required',
								className: 'ml-10',
								maxValue: Math.floor(form?.getFieldValue([orderIndex, 'containersSpecifics', container?.key, 'quantity']) / 2),
								minValue: 1,
								required: true,
							})}
						</div>
						<ContainerCosts
							form={form}
							segment={segment}
							orderIndex={orderIndex}
							doubleTwenty={doubleTwenty}
							containerKey={'xl2020ft'}
							orderCategory={orderCategory}
							doIssuance={doIssuance}
						/>
						{form?.getFieldValue([orderIndex, 'containersSpecifics', container?.key, 'quantity']) -
							form?.getFieldValue([orderIndex, 'containersSpecifics', 'xl2020ft', 'quantity']) * 2 >
							0 && (
							<>
								<Divider></Divider>
								<div className='mb-8 flex flex-row'>
									<div className='flex flex-col'>
										<div className='mb-4 mt-2 text-xs font-semibold text-gray-500'>Vehicle Type</div>
										<div className='text-sm font-semibold'>Regular 20ft Vehicle</div>
									</div>
									<div className='ml-12 flex flex-col'>
										<div className='mb-4 mt-2 text-xs font-semibold text-gray-500'>No. of Vehicles</div>
										<div className='text-sm font-semibold'>
											{form?.getFieldValue([orderIndex, 'containersSpecifics', container?.key, 'quantity']) -
												form?.getFieldValue([orderIndex, 'containersSpecifics', 'xl2020ft', 'quantity']) * 2 || 0}
										</div>
									</div>
								</div>
								<ContainerCosts
									form={form}
									segment={segment}
									orderIndex={orderIndex}
									doubleTwenty={doubleTwenty}
									containerKey={container?.key}
									orderCategory={orderCategory}
									doIssuance={doIssuance}
								/>
							</>
						)}
					</>
				) : (
					isKAM &&
					doubleTwenty &&
					container?.key !== 'xl20ft' && (
						<ContainerCosts
							form={form}
							segment={segment}
							orderIndex={orderIndex}
							doubleTwenty={doubleTwenty}
							containerKey={container?.key}
							orderCategory={orderCategory}
							doIssuance={doIssuance}
						/>
					)
				)}
				{isKAM && !doubleTwenty && (
					<ContainerCosts
						form={form}
						segment={segment}
						orderIndex={orderIndex}
						doubleTwenty={doubleTwenty}
						containerKey={container?.key}
						orderCategory={orderCategory}
						doIssuance={doIssuance}
					/>
				)}
			</div>
		</>
	)
}

export default ContainerDetails
