// REACT
import React, { useState, useMemo } from 'react'
// 3RD PARTY LIBRARIES
import { Table, Layout } from 'antd'

// COMPONENTS
import AdvancedSearchBar from '../utility/AdvancedSearchBar'
import PlaceOrderButton from '../utility/PlaceOrderButton'
import Loading from '../utility/Loading'

// HELPERS
import { ordersColumn, shipmentInfoColumn, routeColumn1, routeColumn2, routeColumn3, statusColumn } from '../../utils/TableColumns'
import { SegmentsEnum } from '../../utils/Enums'
import Pagination from '../utility/Pagination'

// RECOIL
import { segmentOrdersSelector } from '../../recoil/selectors'
import { useRecoilValue } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'

const DynamicOrdersTable = (props) => {
	const { pathname } = useLocation()
	const { segment, setPageInfo, pageInfo, setBackendPageInfo, loading } = props
	const navigator = useNavigate()
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [keyword, setKeyword] = useState('')
	const { totalCount, collectionData } = useRecoilValue(
		segmentOrdersSelector({ segment, pageNo: pageInfo?.pageNo, pageSize: pageInfo?.pageSize, keyword })
	)

	const [filterProperty, setFilterProperty] = useState(SegmentsEnum[segment]?.filterProperties[0])
	const columns = useMemo(
		() => [
			ordersColumn,
			...(segment !== 'LongHaul' ? [shipmentInfoColumn] : []),
			routeColumn1(segment),
			routeColumn2(segment),
			routeColumn3(segment),
			statusColumn,
		],
		[segment]
	)

	return (
		<Layout.Content className='h-full w-full bg-background p-6'>
			{loading ? (
				<Layout className='h-full w-full items-center justify-center rounded'>
					<Loading headline='Loading Data' message='Please wait while your data is fetched.' showButton={false} />
				</Layout>
			) : (
				<Layout className='h-full w-full !rounded-lg bg-white shadow-lg'>
					<Layout.Header className='flex items-center justify-between border-b px-6' style={{ height: '4.5rem', borderRadius: '8px 8px 0px 0px' }}>
						<AdvancedSearchBar
							data={collectionData}
							keyword={keyword}
							setKeyword={setKeyword}
							collection={segment}
							setSearchData={setSearchData}
							filterProperty={filterProperty}
							segment={segment}
							setFilterProperty={setFilterProperty}
						/>
						<PlaceOrderButton {...props} />
					</Layout.Header>
					<Layout.Content className='align-center h-full justify-center' style={{ borderRadius: '0px 0px 8px 8px' }}>
						<Table
							className=''
							rowKey={(record) => record?._id}
							columns={columns}
							dataSource={searchData?.data}
							pagination={false}
							rowClassName='hover:cursor-pointer'
							size='small'
							scroll={{ y: 'calc(100% - 55px)' }}
							onRow={(record) => ({
								onClick: () => navigator(`${pathname}/${record?._id}/orderSummary/details`),
							})}
						/>
					</Layout.Content>
					{keyword?.length === 0 && (
						<Pagination setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount} pageInfo={pageInfo}></Pagination>
					)}
				</Layout>
			)}
		</Layout.Content>
	)
}

export default DynamicOrdersTable
