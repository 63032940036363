// REACT
import React, { useMemo } from 'react'
// 3RD PARTY LIBRARIES
import Icon from '@mdi/react'
// import { mdiInformationOutline } from '@mdi/js'
// HELPERS
import { orderStatusEnum } from '../../utils/Enums'

const OrderStatusPill = (props) => {
	const orderStatus = useMemo(() => orderStatusEnum[props?.status], [props?.status])
	return (
		<div className='flex h-8 w-max items-center rounded-full' style={{ backgroundColor: orderStatus?.background }}>
			<div className='flex h-full w-8 items-center justify-center rounded-full' style={{ backgroundColor: orderStatus?.color }}>
				<Icon path={orderStatus?.icon} size={0.9} className='text-white' />
			</div>
			<p className='mx-2 text-sm font-medium text-gray-800'>{orderStatus?.text}</p>
			{/* <Icon path={mdiInformationOutline} size={0.8} className='mr-2 text-gray-500' /> */}
		</div>
	)
}

export default OrderStatusPill
