import React from 'react'
import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'

const Error = (props) => {
	const { headline, message, showButton } = props
	return (
		<div className='flex w-2/5 flex-col items-center rounded-lg bg-white p-12'>
			<Icon size={3} className='mb-4 text-red-600' path={mdiAlertCircleOutline} />
			<p className='mb-4 text-3xl font-semibold'>{headline}</p>
			<p className={`text-center text-base font-medium text-gray-400 ${showButton && 'mb-6'}`}>{message}</p>
		</div>
	)
}

export default Error
