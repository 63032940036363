import { useRecoilStateCallback } from '../hooks/useRecoilStateCallback'
import { corporatesAtom, superUsersAtom, userAtom } from '../recoil/atoms'
import { authSelector } from '../recoil/selectors'

import { Navigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import Loading from '../components/utility/Loading'
import { GET_ALL_CORPORATES, GET_CLIENT } from '../graphql/queries'
import { useLazyQuery } from '@apollo/client'

const getClientCorporates = async ({ superUsers, getAllCorporates, getClients, setCorporatesAtom }) => {
	const keepSignedIn = JSON.parse(localStorage.getItem('keepSignedIn'))
	const userData = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))

	const isSuperUser = superUsers?.includes(userData?.User?.email)
	const clientQuery = await getClients({ variables: { where: { User: { _id: userData?.User?._id } } } })
	const corporatesData = isSuperUser ? (await getAllCorporates())?.data?.corporates : clientQuery?.data?.clients?.[0]?.Corporate

	setCorporatesAtom(corporatesData)
	sessionStorage.setItem('corporates', JSON.stringify(corporatesData))
	if (keepSignedIn) {
		localStorage.setItem('corporates', JSON.stringify(corporatesData))
	}
}

const PrivateRoute = (props) => {
	const { redirect } = props
	const { pathname } = useLocation()
	const [loading, setLoading] = useState(true)
	const [_, setUserAtom] = useRecoilStateCallback(userAtom)
	const [__, setCorporatesAtom] = useRecoilStateCallback(corporatesAtom)

	const superUsers = useRecoilValue(superUsersAtom)
	const isAuthenticated = useRecoilValue(authSelector())

	const [getClients] = useLazyQuery(GET_CLIENT)
	const [getAllCorporates] = useLazyQuery(GET_ALL_CORPORATES)

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'))
		if (!!userData?._id) {
			getClientCorporates({ superUsers, getAllCorporates, getClients, setCorporatesAtom })
			setUserAtom(userData, () => setLoading(false))
		} else {
			sessionStorage.setItem('redirectTo', JSON.stringify(pathname))
			setLoading(false)
		}
	}, []) // eslint-disable-line

	return isAuthenticated?.authorized ? (
		<>{props.children}</>
	) : loading ? (
		<Loading headline='Verifying Authorization' message='Please wait while we verify your access.' showButton={false} />
	) : (
		<Navigate to={redirect} replace />
	)
}

export default PrivateRoute
