import React from 'react'
import { Form, Button, Input } from 'antd'
import { useNavigate } from 'react-router-dom'

const ForgotPassword = (props) => {
	const navigator = useNavigate()
	return (
		<div className='w-2/5 rounded-lg bg-white p-12'>
			<p className='mb-3 text-3xl font-semibold'>Reset your password</p>
			<p className='mb-6 text-base font-medium text-gray-400'>We’ll email you instructions to reset your password.</p>
			<Form.Item
				key='email'
				name='email'
				label='Email Address'
				rules={[
					{
						required: true,
						validator: async (_, value) => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							return expression.test(value) ? Promise.resolve() : Promise.reject(new Error('Please enter a valid email address.'))
						},
						validateTrigger: 'onSubmit',
					},
				]}
			>
				<Input className='h-12 rounded' placeholder='johndoe@gmail.com' />
			</Form.Item>
			<div className='flex items-center justify-between'>
				<Form.Item className='mb-0 w-2/3'>
					<Button className='btn-primary' htmlType='submit' block loading={props?.loading}>
						NEXT
					</Button>
				</Form.Item>
				<Form.Item key='forgotPassword' name='forgotPassword' className='mb-0 text-sm' valuePropName='checked' initialValue={false}>
					<Button className='p-0' type='link' onClick={() => navigator('/auth/login')}>
						<p className='text-primary-800 underline'>Return to Login</p>
					</Button>
				</Form.Item>
			</div>
		</div>
	)
}

export default ForgotPassword
