import { useMutation } from '@apollo/client'
import { GraphQLEnums } from '../utils/Enums'

const useMutate = (props) => {
	const { state, action, type } = props
	const mutation = GraphQLEnums[type]?.[action]?.[state]?.function
	const mutationInputFunction = GraphQLEnums[type]?.[action]?.[state]?.functionInput
	const mutationOutputFunction = GraphQLEnums[type]?.[action]?.[state]?.functionOutput

	const [mutateFunction, { loading, error }] = useMutation(mutation)

	return { mutateFunction, mutationInputFunction, mutationOutputFunction, loading, error }
}

export default useMutate
