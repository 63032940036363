// REACT
import React, { useState, useEffect, useRef } from 'react'
// 3RD PARTY LIBRARIES
import { Modal, Button, Divider } from 'antd'
import Icon from '@mdi/react'
import { mdiCheck } from '@mdi/js'
import { useRecoilValue } from 'recoil'
// RECOIL
import { corporateByIdSelector, corporatesSelector } from '../../recoil/selectors'
// COMPONENTS
import Searchbar from '../utility/Searchbar'
// HELPERS
import { ascSort } from '../../utils/Functions'
// GRAPHQL
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const ChangeCorporateModal = () => {
	const { segment, activeCorporateId, userType } = useParams()
	const { pathname } = useLocation()
	const activeCorporate = useRecoilValue(corporateByIdSelector({ id: activeCorporateId }))

	const navigator = useNavigate()

	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [selectedCorporate, setSelectedCorporate] = useState()

	const element = useRef()
	const corporates = useRecoilValue(corporatesSelector())

	useEffect(() => {
		element?.current?.className === 'cursor-pointer bg-primary-50' && element.current.scrollIntoView({ block: 'end', inline: 'nearest' })
	}, [selectedCorporate])

	const onActiveCorporateChange = () => {
		const keepSignedIn = JSON.parse(localStorage.getItem('keepSignedIn'))
		sessionStorage.setItem('activeCorporate', JSON.stringify(selectedCorporate))
		if (keepSignedIn) {
			localStorage.setItem('activeCorporate', JSON.stringify(selectedCorporate))
		}
		navigator(`/${pathname.split('/')[1]}/${userType}/${selectedCorporate?._id}/${segment}`)
	}

	const onCancel = () => navigator(-1)

	const CorporatesList = () => {
		const corporatesList = ascSort(structuredClone(searchData?.data), 'businessNameCode')
		return (
			<>
				<div className='max-h-64 overflow-scroll'>
					{corporatesList?.map((corporate, index) => {
						const isSelected = selectedCorporate?.businessCode === corporate?.businessCode
						return (
							<div
								ref={element}
								key={index}
								className={`cursor-pointer ${isSelected && 'bg-primary-50'}`}
								onClick={() => {
									setSelectedCorporate(corporate)
								}}
							>
								<div className='flex items-center justify-between px-4 py-3'>
									<p className={`${isSelected && 'text-primary-800'}`}>{corporate?.businessNameCode}</p>
									{isSelected ? <Icon size={0.9} className='ml-2 text-primary-800' path={mdiCheck} /> : <div className='w-6'></div>}
								</div>
								{index !== corporates.length - 1 && <Divider className='m-0' />}
							</div>
						)
					})}
				</div>
				<Button
					className='btn-primary mt-7'
					onClick={onActiveCorporateChange}
					disabled={selectedCorporate?.businessCode === activeCorporate?.businessCode}
					block
				>
					DONE
				</Button>
			</>
		)
	}

	return (
		<Modal open={true} className='ActiveCorporateModal' footer={null} centered={true} onCancel={onCancel}>
			<div className='p-10'>
				<p className='font-medium text-gray-400'>Active Business</p>
				<p className='mb-4 text-3xl font-semibold'>{selectedCorporate ? selectedCorporate?.businessName : activeCorporate?.businessName}</p>
				<Searchbar
					data={corporates}
					collection={'dummy'}
					setSearchData={setSearchData}
					filterProperty={['businessNameCode']}
					className='mb-4 h-10 border-0 border-b hover:border-0 hover:border-b'
				/>
				<CorporatesList />
			</div>
		</Modal>
	)
}

export default ChangeCorporateModal
