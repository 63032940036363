// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import Icon from '@mdi/react'
import { mdiAccountTieHat, mdiPackageVariantClosed, mdiRayStartArrow, mdiTruckFlatbed, mdiTruckOutline, mdiCash } from '@mdi/js'
import { ExclamationCircleFilled } from '@ant-design/icons'
// HELPERS
import { orderStatusEnum, ContainerDimensionsEnum } from '../../utils/Enums'
import { prepareOrderNumber, getLastSubStatus, IconText, formatVehicleNumber } from '../../utils/Functions'
import { Tooltip } from 'antd'

const SubOrderCard = (props) => {
	const { job = {}, subOrders = {} } = props?.order || {}
	const { Driver = {}, Vehicle = {}, Journeys = [], coordinates } = job
	const { type = {}, orderCategory, orderStatus = {} } = subOrders?.[0]
	const orderStatusInfo = orderStatusEnum[orderStatus?.status]
	const preparedOrderNumber = prepareOrderNumber(subOrders)
	const preparedContainerNumbers = subOrders.map((subOrder) =>
		subOrder?.containerInfo?.containerNumber === '' ? undefined : subOrder?.containerInfo?.containerNumber
	)

	const ShipmentInfo = () => {
		const { containerInfo, vehicleInfo } = subOrders?.[0] || {}
		const containerDimensions = containerInfo?.dimension
		const finalizedRate = containerInfo?.finalizedRate
		const containerSpecifics = ContainerDimensionsEnum()[containerDimensions]?.text
		const numContainers = subOrders?.length
		const preparedVehicleInfo = ContainerDimensionsEnum(props.order.type)[vehicleInfo?.vehicleType]?.text

		const freightWeight = subOrders?.reduce((sum, subOrder) => sum + parseFloat(subOrder?.containerInfo?.freightWeight), 0)
		const shipmentWeight = subOrders?.reduce((sum, subOrder) => sum + parseFloat(subOrder?.shipmentInfo?.freightWeight), 0)
		return (
			<div className='mt-3 flex flex-col justify-start'>
				{['Shifting', 'Destuffing'].includes(orderCategory) ? (
					<div className='flex h-8 w-max items-center rounded-md bg-black-25 px-3'>
						{IconText({ icon: mdiTruckOutline, size: 0.9, text: numContainers + 'x' + containerSpecifics, textClass: 'font-medium text-xs' })}
						<Icon path={mdiRayStartArrow} size={1} className='mx-2 text-gray-500' />
						{IconText({
							icon: orderCategory === 'Shifting' ? mdiTruckOutline : mdiTruckFlatbed,
							text: orderCategory === 'Shifting' ? `${containerDimensions === 'xl20ft' ? '20 Ft' : '40 Ft'} Containerized` : '40 Ft Flatbed',
							textClass: 'font-medium text-xs',
							size: 0.9,
						})}
					</div>
				) : (
					<div className='flex h-8 w-max items-center rounded-md bg-black-25 px-3'>
						{IconText({
							icon: mdiTruckOutline,
							size: 0.9,
							text: containerSpecifics
								? (numContainers > 1 ? numContainers + 'x' : '') + ContainerDimensionsEnum()[containerDimensions]?.long
								: preparedVehicleInfo,
							divClass: 'mr-3',
							textClass: 'font-medium',
						})}
					</div>
				)}
				{(freightWeight || shipmentWeight) > 0 && (
					<div className=' ml-3 mt-3 flex flex-row'>
						<div className=''>
							{IconText({ icon: mdiPackageVariantClosed, size: 0.9, text: `${freightWeight || shipmentWeight} Tons`, textClass: 'font-medium' })}
						</div>
						<div className='ml-4'>{IconText({ icon: mdiCash, size: 0.9, text: `PKR ${finalizedRate || '---'}`, textClass: 'font-medium' })}</div>
					</div>
				)}
			</div>
		)
	}

	const DriverInfo = () => {
		const { phoneNumber } = Driver || {}
		const { registrationNumber } = Vehicle || {}
		const lastSubStatus = getLastSubStatus(Journeys)
		return (
			<div className='flex w-2/5 flex-col items-end'>
				<div
					className='mb-4 w-fit rounded-2xl py-2 px-3 text-xs font-bold '
					style={{ color: orderStatusInfo?.color, background: orderStatusInfo?.background }}
				>
					{orderStatusInfo?.text}
				</div>
				{registrationNumber ? (
					<div className='w-full rounded-[8px] bg-black-25 p-4'>
						{lastSubStatus?.status ? (
							<div>
								<p className='font-semibold text-primary-800'>{lastSubStatus?.status}</p>
								<p className='text-xs'>{lastSubStatus?.subStatus}</p>
							</div>
						) : (
							<p className='font-bold text-red-600'>Tracking not available.</p>
						)}

						<div className='mt-3 flex items-center justify-between'>
							<div>
								<p className='text-sm font-semibold text-gray-500'>Driver Number</p>
								<p className='font-semibold'>{phoneNumber}</p>
							</div>
							<div className='ml-9 rounded-md border border-gray-300 bg-white px-2 py-1 font-semibold text-primary-800'>
								{formatVehicleNumber(registrationNumber)}
							</div>
						</div>
					</div>
				) : (
					<div className='flex w-full flex-col items-center justify-center rounded-[8px] bg-black-25 p-4 py-7'>
						<Icon path={mdiAccountTieHat} size={0.8} className='mb-3 text-gray-500' />
						<p className='text-sm font-medium text-gray-500'>Pending Driver Assignment</p>
					</div>
				)}
			</div>
		)
	}

	const Actionables = () => {
		const { phoneNumber } = Driver || {}
		const { registrationNumber } = Vehicle || {}
		const showDriverTracking = Journeys?.length > 0
		const showLocation = coordinates
		const bilty = job?.bilty?.[0]
		const biltyDocument = !bilty?.isInformationComplete ? bilty?.halfDoneBiltyDocument?.[0] : bilty?.biltyDocument?.[0]

		return (
			<div className='mt-5 flex items-center'>
				<Tooltip title={!showDriverTracking && 'Not Available'} mouseEnterDelay={0.5}>
					<div>
						<p
							className={`mr-3 cursor-pointer text-xs font-[500] underline  ${
								showDriverTracking ? 'text-primary-800' : 'no-hover cursor-not-allowed text-gray-300'
							}`}
							onClick={() => props.setShowTracking({ visible: true, data: props?.order })}
						>
							TRACK ORDER
						</p>
					</div>
				</Tooltip>
				<Tooltip title={!showLocation && 'Not Available'} mouseEnterDelay={0.5}>
					<div>
						<p
							className={`mr-3 cursor-pointer text-xs font-medium  underline ${showLocation ? 'text-primary-800' : 'no-hover cursor-not-allowed text-gray-300'}`}
							onClick={() =>
								props.setSelectedVehicle({
									journeys: Journeys,
									coordinates: coordinates,
									driver: {
										driverPhone: phoneNumber,
										driverVehicleRegistration: registrationNumber,
									},
									preparedSubOrderNumber: preparedOrderNumber,
									type,
								})
							}
						>
							VIEW ON MAP
						</p>
					</div>
				</Tooltip>
				<Tooltip title={!biltyDocument && 'Not Available'} mouseEnterDelay={0.5}>
					<div>
						<p
							className={`mr-3 cursor-pointer text-xs font-[500] underline  ${biltyDocument ? 'text-primary-800' : 'no-hover cursor-not-allowed text-gray-300'}`}
							onClick={() => window.open(biltyDocument)}
							// onClick={() => downloadFile(biltyDocument)}
						>
							VIEW BILTY
						</p>
					</div>
				</Tooltip>
			</div>
		)
	}

	return subOrders?.length !== 0 ? (
		<div className='mb-5 flex w-full flex-col rounded shadow'>
			{subOrders?.length > 1 && (
				<div className='flex flex-row items-start rounded-t bg-[#E8F5E9] px-4 py-1'>
					<ExclamationCircleFilled className=' text-[#1B5E20]' />
					<span className='ml-2 text-xs font-medium text-[#1B5E20]'>These {subOrders?.length} sub-orders are being moved on a single vehicle</span>
				</div>
			)}
			<div className={'flex justify-between bg-white px-5 pb-6 pt-4' + (subOrders?.length > 1 ? ' rounded-b' : ' rounded')}>
				<div className='flex w-3/5 flex-col justify-between'>
					<div className='my-2 flex-col items-center'>
						<div className='text-xs font-bold tracking-wide text-gray-500'>{preparedOrderNumber}</div>
						{['Import', 'Export'].includes(type) && preparedContainerNumbers?.[0] ? (
							<div className='text-lg font-bold text-primary-700'>{`${preparedContainerNumbers?.join(', ')}`}</div>
						) : (
							<div className='text-md font-semibold text-gray-400'>Container Number Not Assigned</div>
						)}
						{ShipmentInfo()}
					</div>
					{Actionables()}
				</div>
				{DriverInfo()}
			</div>
		</div>
	) : (
		<div></div>
	)
}

export default SubOrderCard
