// REACT
import React, { useMemo } from 'react'
// 3RD PARTY LIBRARIES
import { Divider } from 'antd'
import Icon from '@mdi/react'
import { mdiCircle, mdiCloseCircle, mdiPhone } from '@mdi/js'
import { InfoWindow } from '@react-google-maps/api'
// HELPERS
import { SegmentsEnum } from '../../utils/Enums'
import { readableString, capitalize } from '../../utils/Functions'

import moment from 'moment'

const SelectedVehicleMarker = (props) => {
	const { selectedVehicle, setSelectedVehicle, selectedSegment } = props
	let vehicle
	try {
		vehicle = JSON.parse(selectedVehicle)
	} catch (e) {
		vehicle = selectedVehicle
	}
	const marker = useMemo(() => vehicle, [vehicle])

	const driverStatuses = useMemo(() => marker?.journeys?.map((journey) => journey?.DriverStatusHistories).flat(), [marker])
	const lastStatus = useMemo(
		() => driverStatuses?.sort((history1, history2) => moment(history2?.timestamp).diff(moment(history1?.timestamp), 'milliseconds'))?.[0],
		[driverStatuses]
	)
	const driverJourneys = useMemo(() => driverStatuses?.map((status) => status?.DriverStatusJourneys)?.flat(), [driverStatuses])
	const lastSubStatus = useMemo(() => {
		const allLocations = driverJourneys?.filter((journey) => ['location', 'subStatus'].includes(journey.type))
		return allLocations?.sort((loc1, loc2) => moment(loc2?.timestamp).diff(moment(loc1?.timestamp), 'milliseconds'))?.[0] || 'N/A'
	}, [driverJourneys])

	const VehicleInfo = () => (
		<div className='font-inter'>
			<div className='flex items-center justify-between'>
				<div className='flex items-center'>
					<p
						className='mr-2 flex h-6 w-6 items-center justify-center rounded-full font-bold text-white'
						style={{ fontSize: 8, backgroundColor: SegmentsEnum[marker?.type]?.textColor }}
						onClick={() => setSelectedVehicle(null)}
					>
						{SegmentsEnum[marker?.type]?.shortCode}
					</p>
					<p className='text-base font-semibold text-primary-800'>{marker?.preparedSubOrderNumber}</p>
				</div>
				<p className='rounded bg-primary-50 px-3 py-1 text-sm font-bold uppercase text-primary-800'>{marker?.driver?.driverVehicleRegistration}</p>
			</div>
			{lastStatus?.status && (
				<div className='mt-3 flex items-center'>
					<Icon path={mdiCircle} size={0.5} className='mr-2 text-cyan-400' />
					<p className='font-medium text-cyan-400'>{readableString(lastStatus?.status?.replace('driver', ''))}</p>
				</div>
			)}
			{lastSubStatus?.status ? (
				<p className='max-w-[15rem] text-xs text-gray-500'>{capitalize(readableString(lastSubStatus?.status))}</p>
			) : (
				<p className='max-w-[15rem] mt-2 text-xs text-gray-500'>
					Tracking unavailable right now. Tracking will be available after driver reaches the {selectedSegment === 'Import' ? 'port' : 'pickup'}.
				</p>
			)}
			<Divider className='mb-3 mt-2' />
			<div className='flex items-center'>
				<div className='mr-3 flex h-8 w-8 items-center justify-center rounded-full bg-primary-50'>
					<Icon path={mdiPhone} size={0.8} className='text-primary-800' />
				</div>
				<div className='flex flex-col'>
					<p className='text-xs text-gray-500'>Driver Number</p>
					<p className='text-sm font-bold'>{marker?.driver?.driverPhone}</p>
				</div>
			</div>
		</div>
	)

	return marker?.coordinates ? (
		<InfoWindow
			position={marker?.coordinates}
			onCloseClick={() => {
				setSelectedVehicle(null)
			}}
		>
			<VehicleInfo />
		</InfoWindow>
	) : (
		<div className='bg-black absolute flex h-full w-full items-center justify-center bg-opacity-25'>
			<div className='w- relative rounded-lg bg-white p-4'>
				<Icon
					size={1.2}
					path={mdiCloseCircle}
					onClick={() => setSelectedVehicle(null)}
					className='absolute -right-3 -top-3 cursor-pointer rounded-full bg-white'
				/>
				<VehicleInfo />
			</div>
		</div>
	)
}

export default SelectedVehicleMarker
