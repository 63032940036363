// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import { Drawer, Timeline } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
// HELPERS
import { readableString } from '../../utils/Functions'

const TrackingDrawer = (props) => {
	const { showTracking, setShowTracking } = props
	const { visible, data } = showTracking || {}
	const { Driver = {}, Vehicle = {}, Journeys = [] } = data?.job
	const driverStatusHistories = Object.assign([], Journeys)
		?.sort((a, b) => b.steps - a.steps)
		?.map((journey) => journey?.DriverStatusHistories)
		.flat()
	const SubStatusTracking = (status) => (
		<Timeline.Item color='#9F9F9F' className='capitalize'>
			<p className='font-semibold'>{status?.type === 'location' ? status?.status : readableString(status?.status)}</p>
			<p className='text-xs'>{moment(status.timestamp).format('DD-MM-YYYY hh:mm A')}</p>
		</Timeline.Item>
	)

	const StatusTracking = (status) => (
		<Timeline.Item>
			<p className='font-semibold text-primary-800'>{readableString(status?.status?.replace('driver', ''))}</p>
			<p className='text-xs'>{moment(status?.timestamp).format('DD-MM-YYYY hh:mm A')}</p>
			{status?.DriverStatusJourneys?.length > 0 && (
				<div className='mt-2 rounded-md bg-gray-50 px-4 pt-6'>
					<Timeline>
						{status?.DriverStatusJourneys?.filter((subStatus) => ['location', 'subStatus'].includes(subStatus.type))
							?.reverse()
							?.map((subStatus) => SubStatusTracking(subStatus))}
					</Timeline>
				</div>
			)}
		</Timeline.Item>
	)

	const DriverDetails = (label, value) => (
		<div className='mb-2 flex items-center'>
			<p className='w-1/2 text-gray-600'>{label}</p>
			<p className='w-full font-semibold'>{value}</p>
		</div>
	)

	return (
		<Drawer
			placement='right'
			visible={visible}
			getContainer={false}
			className='absolute w-full'
			closeIcon={
				<div className='flex h-10 items-center rounded-full bg-gray-100 px-6'>
					<LeftOutlined className='mr-4 font-bold' />
					<p className='font-medium text-gray-500'>Go Back to Map</p>
				</div>
			}
			onClose={() => setShowTracking({ ...showTracking, visible: false })}
		>
			<p className='mb-4 font-semibold text-gray-500'>Driver Details</p>
			<div className='mb-5 flex flex-col rounded-md bg-gray-100 p-4'>
				{DriverDetails('Driver Name', Driver?.name || '-')}
				{DriverDetails('Phone Number', Driver?.phoneNumber)}
				{DriverDetails('Vehicle Number', Vehicle?.registrationNumber)}
			</div>
			<p className='mb-4 text-xl font-bold text-gray-600'>Track Order</p>
			<Timeline>
				{driverStatusHistories
					?.reverse()
					?.filter((status) => status?.status !== 'firstContact')
					?.map((status) => StatusTracking(status))}
			</Timeline>
		</Drawer>
	)
}

export default TrackingDrawer
