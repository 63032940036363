import { firestore } from '../firebase/firebaseConfig'
import { Navigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Loading from '../components/utility/Loading'
import useStateCallback from '../hooks/useStateCallback'

const ValidatedRoute = (props) => {
	const { rfqId, segment, activeCorporateId } = useParams()
	const [rfqData, setRfqData] = useState({})
	const [loading, setLoading] = useState(true)
	const [validCorporate, setValidCorporate] = useState(true)
	const [isProcessed, setIsProcessed] = useStateCallback(true)

	const { redirect } = props

	const getRfqData = async () => {
		const query = await firestore.collection('rfq').doc(rfqId).get()
		const rfqData = query?.data()
		setRfqData(rfqData)
	}

	useEffect(() => {
		if (rfqId) {
			getRfqData()
		}
	}, []) //eslint-disable-line

	useEffect(() => {
		const corporatesData = JSON.parse(localStorage.getItem('corporates')) || JSON.parse(sessionStorage.getItem('corporates'))
		const corporatesDataIds = corporatesData?.map((corporate) => corporate?._id)
		if (!corporatesDataIds?.includes(activeCorporateId)) {
			setValidCorporate(false)
		}
		if (Object.keys(rfqData) !== undefined) {
			if (rfqData?.status === 'win' || rfqData?.status === 'lost') {
				setIsProcessed(true, () => setLoading(false))
			} else {
				setIsProcessed(false, () => setLoading(false))
			}
		} else if (!rfqId) {
			setIsProcessed(false, () => setLoading(false))
		}
	}, [rfqData]) // eslint-disable-line

	return !isProcessed && validCorporate ? (
		<>{props.children}</>
	) : loading ? (
		<Loading headline='Validating' message='Please wait while complete the validations.' showButton={false} />
	) : (
		<Navigate to={`${redirect}/${isProcessed ? 'processed' : 'invalidCorporate'}/${segment}/${rfqId}`} replace />
	)
}

export default ValidatedRoute
