import { gql } from '@apollo/client'
import { ORDER_FIELDS } from './fragments'

export const UPDATE_ORDER_SUBSCRIPTION = gql`
	${ORDER_FIELDS}
	subscription OrderUpdatedByTypeAndBusinessCode($businessCode: String!, $type: String!, $Import: Boolean!, $Export: Boolean!, $LongHaul: Boolean!) {
		OrderUpdatedByTypeAndBusinessCode(businessCode: $businessCode, type: $type) {
			...OrderFields
		}
	}
`

export const ORDER_UPDATED_BY_ID = gql`
	${ORDER_FIELDS}
	subscription OrderUpdatedById($id: String!, $Import: Boolean!, $Export: Boolean!, $LongHaul: Boolean!) {
		OrderUpdatedById(_id: $id) {
			...OrderFields
		}
	}
`
