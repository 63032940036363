// REACT
import React, { useEffect, useState, useMemo } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

// DESIGN
import { Form, Layout, message } from 'antd'
import Icon from '@mdi/react'
import { mdiKeyboardBackspace } from '@mdi/js'

// RECOIL
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { corporateByIdSelector, portSelector, rfqSelector } from '../recoil/selectors'
import { activeCorporateAtom, rfqAtom, shippingLinesAtom } from '../recoil/atoms'

// HOOKS
import { useFetch } from '../hooks/useFetchQuery'

// FIREBASE
import { OrderCreationEnum } from '../utils/Enums'
import Loading from '../components/utility/Loading'
import { firestore } from '../firebase/firebaseConfig'
import StepsHeader from '../components/utility/StepsHeader'
import StepsFooter from '../components/utility/StepsFooter'
import { prepareInitialValues, initialContainerSpecifics, initialCounterState, handleScroll } from '../utils/Functions'

const NewOrder = () => {
	const { pathname } = useLocation()
	const navigator = useNavigate()
	const { segment, userType, activeCorporateId, rfqId } = useParams()

	const [form] = Form.useForm()
	const watcher = Form.useWatch(undefined, form)
	const [page, setPage] = useState(0)
	const [loading, setLoading] = useState(false)
	const [messageApi, contextHolder] = message.useMessage()
	const [pointerEvents, setPointerEvents] = useState('')
	const [vehicleCounter, setVehicleCounter] = useState(1)
	const [containerCounter, setContainerCounter] = useState({})
	const [promiseLoading, setPromiseLoading] = useState(false)
	const [onFinishValues, setOnFinishValues] = useState({})

	const { rfqData, orignalRfqData } = useRecoilValue(rfqSelector({ segment, rfqId }))
	const pages = useMemo(() => OrderCreationEnum?.OrderForm({ segment, rfqData }), [segment, rfqId, rfqData]) // eslint-disable-line

	const maxPage = pages?.length

	const ports = useRecoilValue(portSelector)
	const corporate = useRecoilValue(activeCorporateAtom)
	const setRfqData = useSetRecoilState(rfqAtom)
	const [shippingLines, setShippingLinesAtom] = useRecoilState(shippingLinesAtom)
	const activeCorporate = useRecoilValue(corporateByIdSelector({ id: activeCorporateId }))

	const buttonfuncs = {
		cancel: () => navigator(-1),
		back: () => (page <= 0 ? navigator(-1) : setPage((prev) => prev - 1)),
		next: () => (page >= maxPage ? () => {} : setPage((prev) => prev + 1)),
		validateNext: () =>
			page >= maxPage
				? () => {}
				: form
						.validateFields()
						.then(() => setPage((prev) => prev + 1))
						.catch((e) => handleScroll({ scrollToClass: '.ant-form-item-has-error' })),
		submit: () => handleFinsih(),
		custom: (button) => button.function(),
	}

	const handleFinsih = () => {
		const values = form.getFieldsValue(true)
		setOnFinishValues(values)

		form
			.validateFields()
			.then(() => navigator(`${pathname}/orderSummary`))
			.catch((e) => handleScroll({ scrollToClass: '.ant-form-item-has-error' }))
	}

	const getShippingLinesAndTerminals = async () => {
		const query = await firestore.collection('config').doc('portVicinities').get()
		const sortedShippingLines = query
			?.data()
			?.shippingLines?.sort((a, b) => a?.localeCompare(b))
			?.map((shippingLine) => ({ name: shippingLine }))
		query?.data()?.shippingLines && setShippingLinesAtom(sortedShippingLines)
	}

	const getRfqData = async () => {
		setLoading(true)
		const query = await firestore.collection('rfq').doc(rfqId).get()
		const rfqData = query?.data()
		setRfqData(rfqData)
		setLoading(false)
	}

	useEffect(() => {
		setLoading(true)
		const shipment = Object.values(rfqData?.shipmentInfo || {})[page]
		const containersSpecifics = initialContainerSpecifics({
			containerInfo: shipment?.containerInfo,
		})
		const counter = initialCounterState({ rfqData, segment })
		setContainerCounter(counter)
		form.setFieldValue({ [page]: { containersSpecifics: containersSpecifics } })
		setVehicleCounter(rfqData?.costPerVehicle?.length || 1)
		setLoading(false)
	}, [page, rfqData, segment]) // eslint-disable-line

	useEffect(() => {
		if (pages?.length > 0) {
			const initialFormData = prepareInitialValues({ form, segment, ports, corporate, rfqData })
			form.setFieldsValue(initialFormData)
		}
	}, [corporate, rfqData]) // eslint-disable-line

	useEffect(() => {
		getRfqData()
		getShippingLinesAndTerminals()
	}, []) // eslint-disable-line

	// prettier-ignore
	useFetch({ type: 'Lanes', atomKey: 'lanes' })
	useFetch({ type: 'Locations', atomKey: 'port', variables: { type: 'Port' } })
	useFetch({ type: 'Locations', atomKey: 'terminal', variables: { type: 'Terminal' } })
	useFetch({ type: 'Corporates', atomKey: 'corporateData', fetchKey: 'corporateData', variables: { businessCode: activeCorporate?.businessCode } })

	return (
		<>
			{loading ? (
				<Loading headline='Loading Data' message='Please wait while the data is fetched.' showButton={false} />
			) : (
				<Layout className={'w-full'}>
					<Layout.Header className={`flex !h-16 items-center justify-between py-3 ${pages?.length === 1 ? 'px-6' : 'px-0'}`}>
						{pages?.length === 1 ? (
							<div className='flex h-full items-center'>
								<div
									className='mr-4 flex h-8 cursor-pointer items-center rounded-full bg-gray-100 px-6 shadow-[0_0_4px_0_rgba(0,0,0,0.18)]'
									onClick={() => (rfqId ? navigator(-2) : navigator(-1))}
								>
									<Icon size={1} className='text-gray-500' path={mdiKeyboardBackspace} />
								</div>
								<p className='text-base font-semibold'>{`Creating a New ${segment} Order`}</p>
							</div>
						) : (
							<StepsHeader showBackIcon={true} page={page} pages={pages} buttonfuncs={buttonfuncs} />
						)}
					</Layout.Header>
					<Layout.Content className='overflow-scroll bg-background p-6'>
						<div className={`flex items-center justify-center ${pointerEvents}`}>
							<Form layout='vertical' form={form} className='w-7/12' scrollToFirstError={{ behavior: 'smooth', block: 'center' }}>
								{pages?.[page]?.columns?.map((column) => (
									<div key={page} className={`${loading ? 'no-hover' : ''} ${column?.widthClassName} `}>
										<column.element
											{...{
												form,
												watcher,
												segment,
												userType,
												corporate,
												shippingLines,
												orderIndex: page,
												setPromiseLoading,
												vehicleCounter,
												setVehicleCounter,
												containerCounter,
												setContainerCounter,
												messageApi,
												setPointerEvents,
											}}
										/>
									</div>
								))}
							</Form>
						</div>
					</Layout.Content>
					<Layout.Footer className='h-16 bg-white py-3 shadow-[0_0_4px_0_rgba(0,0,0,0.18)]'>
						<StepsFooter page={page} pages={pages} promiseLoading={promiseLoading} buttonfuncs={buttonfuncs} />
					</Layout.Footer>
				</Layout>
			)}
			<Outlet context={{ data: { values: onFinishValues, rfqData, orignalRfqData } }} />
			{contextHolder}
		</>
	)
}

export default NewOrder
