// REACT
import React, { useEffect, useState } from 'react'

// ANTD
import { Layout } from 'antd'

// COMPONENTS
import DynamicTabs from '../components/layout/DynamicTabs'
import DynamicOrdersTable from '../components/tables/DynamicOrdersTable'

// UTILS
import { PageSizes } from '../utils/Enums'

// HOOKS
import { useFetch } from '../hooks/useFetchQuery'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

const Shipments = (props) => {
	const { segment = 'Import' } = useParams()
	const { selectedTab, activeCorporate, subscribeRef } = useOutletContext()

	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })

	const [loading, subscribe] = useFetch({
		type: 'Orders',
		atomKey: segment,
		variables: { type: segment, businessCode: activeCorporate?.businessCode },
		subscriptionVariables: { type: segment, businessCode: activeCorporate?.businessCode },
		pageInfo: { pageNo: backendPageInfo?.pageNo, pageSize: backendPageInfo?.pageSize },
	})

	useEffect(() => {
		subscribeRef.current = subscribe
	}, []) // eslint-disable-line

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize]) // eslint-disable-line

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: 50 })
	}, [selectedTab, segment]) // eslint-disable-line

	return (
		<>
			<Layout>
				<DynamicTabs type='segments' segment={segment} />
				<DynamicOrdersTable loading={loading} pageInfo={pageInfo} segment={segment} setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} />
			</Layout>
			<Outlet />
		</>
	)
}

export default Shipments
