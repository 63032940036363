import React, { useEffect, useMemo, useState } from 'react'
import { EmailSchema } from '../../utils/FormSchemas'
import { Modal, Form, notification, Layout } from 'antd'
import { RenderSchema, FormRender } from '../../utils/FormRenderer'

import { useRecoilValue } from 'recoil'
import { activeCorporateAtom } from '../../recoil/atoms'

import StepsFooter from '../utility/StepsFooter'
import StepsHeader from '../utility/StepsHeader'
import { OrderCreationEnum } from '../../utils/Enums'
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useUpdateRFQData } from '../../hooks/modalFinishHooks'

const OrderSummaryModal = () => {
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const {
		data: { values, rfqData, orignalRfqData },
	} = useOutletContext()
	const { segment, userType, rfqId } = useParams()

	const [form] = Form.useForm()

	const [page, setPage] = useState(0)
	const [failed, setFailed] = useState(false)

	const isKAM = userType === 'kam'
	const corporate = useRecoilValue(activeCorporateAtom)
	const [modalData, setModalData] = useState({}) // eslint-disable-line
	const [promiseLoading, setPromiseLoading] = useState(false)

	const pages = useMemo(() => OrderCreationEnum?.SummaryModal({ values }), [values]) // eslint-disable-line
	const [finishFunction] = pages?.[page]?.useFinishFunction() || []
	const [updateRFQFunction] = useUpdateRFQData()

	const maxPage = pages?.length

	useEffect(() => {
		setModalData(Object.values(values || {})?.filter((value) => value)[page])
	}, [page, values])

	const buttonfuncs = {
		cancel: () => navigator(-1),
		back: () => (page <= 0 ? navigator(-1) : setPage((prev) => prev - 1)),
		next: () => (page >= maxPage ? () => {} : setPage((prev) => prev + 1)),
		validateNext: () =>
			page >= maxPage
				? () => {}
				: form
						.validateFields()
						.then(() => setPage((prev) => prev + 1))
						.catch((e) => console.log(e)),
		submit: () => handleFinish(),
		custom: (button) => button.function(),
	}
	// useEffect(() => !visible && form.resetFields(), [visible]) // eslint-disable-line

	const handleFinish = () => {
		form
			.validateFields()
			.then(async () => {
				setPromiseLoading(true)
				return await finishFunction({ values: { ...form.getFieldsValue(true), ...modalData }, corporate, isKAM, rfqId, rfqData, orignalRfqData, setFailed })
					.then((response) => response)
					.catch((e) => {
						console.log(e)
						return { type: 'error', message: 'Error', description: e.message }
					})
			})
			.then(async (queryresult) => {
				if (queryresult?.type) {
					notification[queryresult?.type]({
						message: queryresult.message,
						description: queryresult.description,
						duration: '5',
					})
				}

				if (page === maxPage - 1 && rfqId) {
					if (!failed) {
						return await updateRFQFunction({ values: Object.values(values || {})?.filter((value) => value), rfqData, rfqId, orignalRfqData, corporate })
							.then((response) =>
								notification[response?.type]({
									message: response.message,
									description: response.description,
									duration: '5',
								})
							)
							.then(() => {
								setPromiseLoading(false)
								window.location.href = `${process.env.REACT_APP_RFQ_URL}/dashboard/${segment}`
							})
							.catch((e) => {
								console.log(e)
							})
					} else {
						setPromiseLoading(false)
						window.location.href = `${process.env.REACT_APP_RFQ_URL}/dashboard/${segment}`
					}
				} else if (page === maxPage - 1) {
					setPromiseLoading(false)
					navigator(`${pathname.split('/').slice(0, -2).join('/')}`)
				} else {
					setPromiseLoading(false)
					setPage((prev) => prev + 1)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}

	return (
		<Modal
			open={true}
			title={
				<>
					<p className='rounded-t bg-primary-1000 px-6 py-3 text-sm font-bold text-white'>Review Order Summary</p>
					{pages?.length > 1 && (
						<Layout.Header className={`flex !h-16 items-center justify-between border-b py-3 ${pages?.length === 1 ? 'px-6' : 'px-0'}`}>
							<StepsHeader page={page} pages={pages} />
						</Layout.Header>
					)}
				</>
			}
			closable={false}
			className={'OrderSummaryModal'}
			footer={<StepsFooter promiseLoading={promiseLoading} pages={pages} page={page} buttonfuncs={buttonfuncs} />}
			centered={true}
		>
			<div className='flex h-full flex-col'>
				<Form key='ModalForm' layout='vertical' form={form} scrollToFirstError className='h-full overflow-hidden'>
					<div className='flex bg-white px-6 pt-4'>
						<div className='mr-6 flex w-2/5 flex-col'>
							<p className='font-medium'>Client Reference Number</p>
							<p className='text-xs text-gray-400'>You can input your own reference order number </p>
							<div className='mt-4'>{FormRender({ type: 'input', name: ['specialRequests', 'refNumber'], placeholder: 'Enter number' })}</div>
						</div>
						<div className='w-3/5'>
							<p className='font-medium'>Email Addresses</p>
							<p className='text-xs text-gray-400'>These email addresses will be used to send you notifications about your order </p>
							<div className='mt-4'>{RenderSchema(EmailSchema({ clientEmails: corporate?.creationEmail || [] }))}</div>
							{isKAM && (
								<div className='my-2'>
									{FormRender({
										type: 'emails',
										name: 'clientCCEmails',
										required: false,
										placeholder: 'Add CC emails',
										mode: 'tags',
										tokenSeparators: [',', ' '],
									})}
								</div>
							)}
						</div>
					</div>
				</Form>
				<div className='flex flex-grow flex-col overflow-scroll bg-background px-2 py-2'>
					{pages?.[page]?.columns?.map((column) => (
						<div key={page} className={`${column?.widthClassName} `}>
							<column.element {...{ form, segment, values: modalData, userType, corporate, setPromiseLoading, isKAM }} />
						</div>
					))}
				</div>
			</div>
		</Modal>
	)
}

export default OrderSummaryModal
