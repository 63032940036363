import React from 'react'
import { Button } from 'antd'
import Sprite from '../../assets/icons/Sprite.svg'
import { useNavigate } from 'react-router-dom'
const ResetLinkSent = (props) => {
	const navigator = useNavigate()
	return (
		<div className='flex w-2/5 flex-col rounded-lg bg-white p-12'>
			<svg className='mb-6' style={{ width: 100, height: 90 }}>
				<use href={Sprite + '#EmailSent'} />
			</svg>
			<p className='mb-3 text-2xl font-semibold'>Reset instructions sent to your mailbox</p>
			{props?.email && (
				<p className='mb-6 text-base font-medium text-gray-400'>
					Email: <span className='text-primary-800'>{props?.email}</span>
				</p>
			)}
			<Button className='w-max p-0' type='link' onClick={() => navigator('/auth/login')}>
				<p className='text-primary-800 underline'>Return to Login</p>
			</Button>
		</div>
	)
}

export default ResetLinkSent
