import { SegmentsEnum, OrderCategoryEnum } from './Enums'
import { mdiTrayArrowUp } from '@mdi/js'
import moment from 'moment'

export const PortsSchema = (props) => {
	const { type, orderIndex } = props
	return [
		{
			type: 'radioGroup',
			label: 'Select Port',
			name: [orderIndex, type, 'formattedAddress'],
			options: ['Karachi Port', 'Port Qasim'],
			initialValue: 'Karachi Port',
			button: true,
			formReference: props.form,
			clearData: { [orderIndex]: { [type]: { additionalDetails: undefined } } },
			required: true,
			message: 'Port is required.',
		},
		{
			type: 'dependency',
			independent: [orderIndex, type, 'formattedAddress'],
			condition: ['Karachi Port'],

			successSchema: [
				{
					type: 'radioGroup',
					label: 'Select Terminal',
					name: [orderIndex, type, 'additionalDetails'],
					options: ['KICT', 'PICT', 'SAPT'],
					initialValue: 'KICT',
					required: true,
					button: true,
					message: 'Terminal is Required.',
				},
			],
			failureSchema: [
				{
					type: 'radioGroup',
					label: 'Select Terminal',
					name: [orderIndex, type, 'additionalDetails'],
					options: ['QICT'],
					initialValue: 'QICT',
					required: true,
					button: true,
					message: 'Terminal is Required.',
				},
			],
		},
		{
			title: 'Clearing Agents',
			fields: [
				{
					type: 'dynamicFields',
					label: 'Clearing Agent',
					name: [orderIndex, 'clearingAgents'],
					initialValue: [{ contactName: '', contactPhone: '' }],
					minEntries: 1,
					fields: [
						{
							type: 'input',
							label: 'Contact Name',
							name: 'contactName',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'contactPhone',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
					],
				},
			],
		},
	]
}

export const LocationsSchema = (props) => {
	const { form, orderIndex, type, index, options, terminals } = props || {}
	return [
		{
			type: 'input',
			hidden: true,
			name: [orderIndex, type, index, '_id'],
			label: 'Hidden Field',
			inputType: 'text',
			form: form,
		},
		{
			type: 'recentSelect',
			label: 'Address',
			name: type === 'terminal' ? [orderIndex, type, index, '_id'] : [orderIndex, type, index, 'formattedAddress'],
			required: true,
			message: 'Address is required.',
			options: type === 'terminal' ? terminals : options || [],
			valueProperty: '_id',
			displayProperty: 'formattedAddress',
			setField: [orderIndex, type, index],
			form: form,
			showSearch: true,
			allowClear: true,
		},
		type !== 'terminal' && {
			type: 'input',
			label: 'Additional Details',
			name: [orderIndex, type, index, 'additionalDetails'],
			inputType: 'text',
		},
		{
			title: type === 'terminal' ? 'Emergency Contact Information' : 'Point of Contact',
			fields: [
				{
					type: 'dynamicFields',
					label: type === 'terminal' ? 'Emergency Contact' : 'Contact',
					name: [orderIndex, type, index, 'contacts'],
					initialValue: type !== 'terminal' && [{ contactName: '', contactPhone: '' }],
					minEntries: type === 'terminal' ? 0 : 1,
					fields: [
						{
							type: 'input',
							label: 'Contact Name',
							name: 'contactName',
							required: true,
							message: 'Contact name is required.',
							inputType: 'text',
						},
						{
							type: 'phoneNumber',
							label: 'Phone Number',
							name: 'contactPhone',
							required: true,
							message: 'Phone number is required.',
							addonBefore: '+92',
						},
					],
				},
			],
		},
	]
}

export const DoIssuanceSchema = (props) => {
	const { orderIndex, segment, shippingLines, terminals } = props
	return [
		{
			type: 'radioGroup',
			label: 'I want Bridgelinx to handle Delivery Order (DO) Issuance?',
			className: 'mb-3 font-semibold text-sm text-primary-800',
			name: [orderIndex, 'specialRequests', 'doIssuance'],
			displayProperty: 'label',
			valueProperty: 'key',
			options: [
				{ key: true, label: 'Yes' },
				{ key: false, label: 'No' },
			],
			initialValue: true,
			button: true,
		},
		[
			{
				type: 'input',
				label: `${segment === 'Import' ? 'BL' : 'CRO'} Number`,
				name: [orderIndex, 'shipmentInfo', 'documentNumber'],
				required: true,
				message: `${segment === 'Import' ? 'BL' : 'CRO'} Number is required.`,
				placeholder: `Enter ${segment === 'Import' ? 'BL' : 'CRO'} Number`,
			},
			{
				type: 'select',
				label: 'Shipping Line',
				name: [orderIndex, 'shipmentInfo', 'shippingLine'],
				options: shippingLines,
				showSearch: true,
				required: true,
				valueProperty: 'name',
				displayProperty: 'name',
				message: 'Shipping Line is required.',
				placeholder: 'Select Shipping Line',
			},
		],
		[
			{
				type: 'dateTime',
				label: 'Loading Date',
				name: [orderIndex, 'loadingDT'],
				placeholder: 'Select Loading Date',
				message: 'Loading date is required.',
				format: 'DD-MM-YYYY',
				showTime: false,
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			},
			{
				type: 'dateTime',
				label: 'Client Committed Return Deadline',
				name: [orderIndex, 'clientCommittedReturnDeadline'],
				placeholder: 'Select Return Deadline',
				message: 'Return deadline is required.',
				format: 'DD-MM-YYYY',
				showTime: false,
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			},
		],
		{ type: 'divider', className: 'mt-0 mb-4' },
		[
			{
				type: 'input',
				label: 'Free Days',
				name: [orderIndex, 'terminal', 'freeDays'],
				inputType: 'number',
				addonAfter: 'Days',
				placeholder: 'Number of Free Days',
			},
			{
				type: 'select',
				label: 'Empty Container Return Terminal',
				name: [orderIndex, 'terminal', 0, '_id'],
				options: terminals,
				displayProperty: 'formattedAddress',
				valueProperty: '_id',
				showSearch: true,
				allowClear: true,
				placeholder: 'Select address from the list',
			},
		],
        [
            {
				type: 'dateTime',
				label: 'EIR Submission Deadline',
				name: [orderIndex, 'eirSubmissionDeadline'],
				placeholder: 'Select EIR Submission Deadline',
				message: 'EIR submission deadline is required.',
				format: 'DD-MM-YYYY',
				showTime: false,
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			},
            {},
        ],
	]
}

export const DocumentInfoSchema = (props) => {
	const { orderIndex, segment, shippingLines } = props || {}
	return [
		{
			type: 'radioGroup',
			label: 'Is the CRO forwarded to the terminal?',
			name: [orderIndex, 'specialRequests', 'emailForwarded'],
			initialValue: true,
			options: [
				{ key: true, label: 'Yes' },
				{ key: false, label: 'No' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			button: true,
		},
		[
			{
				type: 'input',
				label: `${segment === 'Import' ? 'BL' : 'CRO'} Number`,
				name: [orderIndex, 'shipmentInfo', 'documentNumber'],
				required: true,
				message: `${segment === 'Import' ? 'BL' : 'CRO'} Number is required.`,
				placeholder: `Enter ${segment === 'Import' ? 'BL' : 'CRO'} Number`,
			},
			{
				type: 'select',
				label: 'Shipping Line',
				name: [orderIndex, 'shipmentInfo', 'shippingLine'],
				options: shippingLines,
				showSearch: true,
				required: true,
				valueProperty: 'name',
				displayProperty: 'name',
				message: 'Shipping Line is required.',
				placeholder: 'Select Shipping Line',
			},
		],
		[
			{
				type: 'dateTime',
				label: 'Loading Date',
				name: [orderIndex, 'loadingDT'],
				required: true,
				message: 'Loading date is required.',
				format: 'DD-MM-YYYY',
				showTime: false,
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
				initialValue: moment(),
			},
			{
				type: 'dateTime',
				label: 'Expected Vessel Cut-off Time',
				name: [orderIndex, 'vesselCutoffDT'],
				required: true,
				message: 'Vessel cutt-off time is required.',
				format: 'DD-MM-YYYY HH:mm',
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			},
		],
		[
            {
				type: 'dateTime',
				label: 'Client Committed Delivery Date',
				name: [orderIndex, 'clientCommittedDeliveryDate'],
				required: false,
				message: 'Delivery date is required.',
				format: 'DD-MM-YYYY',
				disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
			},
			{
				type: 'input',
				label: 'Comodity',
				name: [orderIndex, 'shipmentInfo', 'freightType'],
				required: true,
				message: 'Comodity is required.',
				placeholder: 'Add Comodity',
			},
		],
	]
}

export const DocumentUpload = (props) => {
	const { orderIndex, segment, documentId, setLoading, messageApi, setPointerEvents } = props || {}
	return [
		{
			type: 'upload',
			name: [orderIndex, 'shipmentInfo', 'document'],
			uploadType: 'button',
			uploadLink: documentId,
			collection: SegmentsEnum[segment]?.collectionName,
			icon: mdiTrayArrowUp,
            setLoading,
            messageApi,
            setPointerEvents,
		},
	]
}

export const OrderTypeSchema = (props) => {
	const { orderIndex } = props
	return [
		{
			type: 'radioGroup',
			label: 'Please specify your order nature',
			name: [orderIndex, 'specialRequests', 'orderCategory'],
			options: Object.values(OrderCategoryEnum),
			valueProperty: 'key',
			displayProperty: 'text',
			button: true,
			initialValue: 'directSeal',
			required: true,
			message: 'Order Category is required',
		},
	]
}

export const VehiclesCardUnitSchema = (props) => {
	const { orderIndex } = props
	return [
		{
			type: 'radioGroup',
			name: [orderIndex, 'pricingInfo', 'quotedRateUnit'],
			initialValue: 'Tonne',
			options: [
				{ key: 'Tonne', label: 'Per Tonne' },
				{ key: 'Vehicle', label: 'Per Vehicle' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			required: true,
			message: 'Rate Unit is required.',
		},
	]
}

export const ShiftingRateCalculationSchema = (props) => {
	const { orderIndex } = props
	return [
		{
			type: 'radioGroup',
			name: [orderIndex, 'pricingInfo', 'finalizedRateUnit'],
			initialValue: 'Tonne',
			options: [
				{ key: 'Tonne', label: 'Per Tonne' },
				{ key: 'Container', label: 'Per Container' },
				{ key: 'Vehicle', label: 'Per Vehicle' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			required: true,
			message: 'Rate Unit is required.',
		},
	]
}

export const LotRateCalculationSchema = ({ orderIndex }) => {
	return [
		[
			{
				type: 'number',
				label: 'Rate Per Tonne - Supply',
				name: [orderIndex, 'pricingInfo', 'vehicleCost'],
				inputType: 'number',
				required: true,
				message: 'Supply Quote is required.',
				minValue: 1,
				addonAfter: 'PKR / Tonne',
			},
			{},
		],
		[
			{
				type: 'number',
				label: 'Revenue Per Tonne',
				name: [orderIndex, 'pricingInfo', 'finalizedRate'],
				inputType: 'number',
				required: true,
				message: 'Revenue is required.',
				minValue: 1,
				addonAfter: 'PKR / Tonne',
			},
			{},
		],
	]
}

export const AddRequestsSchema = (props) => {
	const { isKAM, orderIndex } = props
	return [
		...(isKAM
			? [
					{
						type: 'radioGroup',
						label: 'Are the finalized rates inclusive of GST?',
						name: [orderIndex, 'specialRequests', 'gstInclusive'],
						initialValue: true,
						options: [
							{ key: true, label: 'Yes' },
							{ key: false, label: 'No' },
						],
						valueProperty: 'key',
						displayProperty: 'label',
						button: true,
						required: true,
						message: 'Commodity is required.',
					},
			  ]
			: [{}]),
		{
			type: 'radioGroup',
			label: 'Select your desired special request',
			name: [orderIndex, 'specialRequests', 'request'],
			valueProperty: 'value',
			displayProperty: 'title',
			options: [
				{ title: 'Fast Lane - Express Delivery', value: 'Fast Lane - Express Delivery', tooltip: 'Inaam Delivery' },
				{ title: 'Other', value: 'Other' },
			],
			button: true,
		},
		{
			type: 'dependency',
			independent: [orderIndex, 'specialRequests', 'request'],
			condition: ['Other'],
			successSchema: [
				{
					type: 'input',
					label: 'Additional Requests',
					name: [orderIndex, 'specialRequests', 'otherRequests'],
					inputType: 'textArea',
				},
			],
			failureSchema: [],
		},
	]
}

export const OtherRequestsSchema = (props) => {
	const { isKAM, orderIndex } = props
	return [
		...(isKAM
			? [
					{
						type: 'radioGroup',
						label: 'Are the finalized rates inclusive of GST?',
						name: [orderIndex, 'specialRequests', 'gstInclusive'],
						initialValue: true,
						options: [
							{ key: true, label: 'Yes' },
							{ key: false, label: 'No' },
						],
						valueProperty: 'key',
						displayProperty: 'label',
						button: true,
						required: true,
						message: 'Commodity is required.',
					},
			  ]
			: []),
		{
			type: 'input',
			label: 'Additional Requests',
			name: [orderIndex, 'specialRequests', 'otherRequests'],
			inputType: 'textArea',
		},
	]
}

export const FreightDetailsSchema = (props) => {
	const { lotOrder, orderIndex } = props
	return lotOrder
		? [
				[
					{
						type: 'radioGroup',
						label: 'Select Commodity',
						name: [orderIndex, 'shipmentInfo', 'freightType'],
						options: ['Cement', 'Coal', 'Seed', 'Steel', 'Other'],
						button: true,
						required: true,
						message: 'Commodity is required.',
					},
				],
				[
					{
						type: 'number',
						label: 'Cargo Weight',
						name: [orderIndex, 'shipmentInfo', 'freightWeight'],
						inputType: 'number',
						required: true,
						message: 'Cargo weight is required.',
						minValue: 1,
						addonAfter: 'Tons',
					},
					{
						type: 'dateTime',
						label: 'Expected Loading Date',
						name: [orderIndex, 'loadingDT'],
						required: true,
						message: 'Loading date is required.',
						format: 'DD-MM-YYYY',
						showTime: false,
						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
						initialValue: moment(),
					},
				],
		  ]
		: [
				[
					{
						type: 'input',
						label: 'Comodity',
						name: [orderIndex, 'shipmentInfo', 'freightType'],
						required: true,
						message: 'Comodity is required.',
						placeholder: 'Add Comodity',
					},
					{
						type: 'dateTime',
						label: 'Expected Loading Date',
						name: [orderIndex, 'loadingDT'],
						required: true,
						message: 'Loading date is required.',
						format: 'DD-MM-YYYY',
						showTime: false,
						disabledDate: (current) => current && current < moment().subtract(1, 'days').endOf('day'),
						initialValue: moment(),
					},
				],
		  ]
}

export const VehicleInfoSchema = ({ orderIndex }) => {
	return [
		{
			type: 'radioGroup',
			label: 'Select Movement Type.',
			name: [orderIndex, 'specialRequests', 'lotOrder'],
			initialValue: true,
			options: [
				{ key: true, label: 'Bulk' },
				{ key: false, label: 'Dispatch' },
			],
			valueProperty: 'key',
			displayProperty: 'label',
			button: true,
			required: true,
			message: 'Movement type is required.',
		},
	]
}

export const EmailSchema = (props) => {
	const { clientEmails } = props || {}
	return [
		{
			type: 'emails',
			name: 'clientEmails',
			required: true,
			message: 'Atleast 1 email is required.',
			placeholder: 'Select from dropdown or add a new email',
			options: clientEmails ?? [],
			mode: 'tags',
			tokenSeparators: [',', ' '],
			initialValue: clientEmails,
		},
	]
}
