import { gql } from '@apollo/client'
import { LANE_FIELDS, LOCATION_FIELDS, ORDER_FIELDS } from './fragments'

export const GET_CLIENT = gql`
	query Clients($where: ClientWhere) {
		clients(where: $where) {
			_id
			isKAM
			Corporate {
				_id
				businessCode
				businessName
			}
			User {
				_id
				firstName
				lastName
				email
				phoneNumber
			}
		}
	}
`

export const GET_ALL_LOCATIONS_BY_TYPE = gql`
	${LOCATION_FIELDS}
	query Locations($where: LocationWhere) {
		locations(where: $where) {
			...LocationFields
		}
	}
`

export const GET_CORPORATE_BY_BUSINESS_CODE = gql`
	query Corporates($where: CorporateWhere) {
		corporates(where: $where) {
			_id
			businessCode
			businessName
			sector
			clearingAgents {
				phoneNumber
				name
			}
			CorporateLocations {
				Location {
					formattedAddress
					additionalDetails
					Region {
						name
						_id
					}
					coordinates {
						lat
						lng
					}
					_id
				}
				contacts {
					name
					phoneNumber
				}
				dropoffFrequency
				loadingFrequency
				_id
			}
			creationEmail
			freightType
			updateEmail
		}
	}
`

export const GET_ALL_CORPORATES = gql`
	query Corporates {
		corporates {
			_id
			businessCode
			businessName
		}
	}
`

export const GET_ALL_ORDERS = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions, $Import: Boolean!, $Export: Boolean!, $LongHaul: Boolean!) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`

export const GET_USER_TYPES = gql`
	query UserTypes {
		userTypes {
			_id
			userType
		}
	}
`

export const GET_ALL_LANES = gql`
	${LANE_FIELDS}
	query Lanes($where: LaneWhere) {
		lanes(where: $where) {
			...LaneFields
		}
	}
`
