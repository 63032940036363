import React from 'react'
import { Layout, Tabs } from 'antd'
import Icon from '@mdi/react'

import { mdiCrosshairsGps } from '@mdi/js'
import PlaceOrderButton from '../utility/PlaceOrderButton'

const DashboardHeader = (props) => {
	const { selectedTab } = props || {}

	const TabHeader = (tabEntry) => {
		return (
			<div className='m-0 flex items-center px-4'>
				<Icon size={1} className='mr-3 text-primary-800' path={tabEntry?.icon} />
				<p className='font-medium'>{tabEntry?.long}</p>
			</div>
		)
	}
	return (
		<Layout.Header className='flex !h-14 items-center justify-between !bg-background !p-0'>
			<Tabs id='DashboardTabs' type='card' className='h-full' activeKey={selectedTab}>
				<Tabs.TabPane className='!bg-white' tab={TabHeader({ icon: mdiCrosshairsGps, long: 'Live Tracking' })} key={'tracking'} />
				{/* <Tabs.TabPane tab={TabHeader({ icon: mdiPoll, long: 'Insights' })} key={'insights'} /> */}
			</Tabs>
			<PlaceOrderButton {...props} />
		</Layout.Header>
	)
}

export default DashboardHeader
