import { gql } from '@apollo/client'

export const LOCATION_FIELDS = gql`
	fragment LocationFields on Location {
		_id
		formattedAddress
		additionalDetails
		coordinates {
			lat
			lng
		}
		Region {
			ParentRegion {
				name
				_id
			}
			name
			_id
		}
		type
		Region {
			_id
			name
		}
	}
`
export const CONTACT_FIELDS = gql`
	fragment ContactFields on Contacts {
		name
		phoneNumber
	}
`
export const USER_FIELDS = gql`
	fragment UserFields on User {
		_id
		firstName
		lastName
		phoneNumber
		email
		cnic
	}
`

export const DO_STATUS_FIELDS = gql`
	fragment DoStatusFields on DoStatus {
		_id
		status
		comment
		documents {
			payOrder
			underTakings
			blStamped
			loloReceipt
			goodsDeclaration
		}
	}
`

export const QUOTATION_FIELDS = gql`
	fragment QuotationFields on Quotation {
		_id
		JobId
		BrokerId
		SupplierId
		cost
		driver {
			name
			vehicleRegistrationNumber
			phoneNumber
		}
		approved
		slipped
		bidWon
	}
`

export const VENDOR_PAYMENT_FIELDS = gql`
	fragment VendorPaymentFields on vendorPayments {
		costBreakdown {
			amanat
			kantaCharges
			loloCharges
			offLoadingCharges
			placementCharges
			repairingCharges
			rotationalCharges
			beneficiary
			cost
			deductions
			detentions
			dropoff
			extraWeight
			inaam
			otherCharges
			supplyComments
		}
	}
`
export const SHIPMENT_INFO_FIELDS = gql`
	fragment ShipmentInfoFields on ShipmentInfo {
		freightCount
		freightType
		freightWeight
		estimatedWeight
		estimatedCount
	}
`
export const IMPORT_EXPORT_PREFERENCES_FIELDS = gql`
	fragment ImportExportPreferencesFields on SpecialRequest {
		movementType
		doIssuance
		numberFlatbeds
		otherRequests
		fastLane
		refNumber
	}
`
export const LONGHAUL_PREFERENCES_FIELDS = gql`
	fragment LongHaulPreferencesFields on LongHaulSpecialRequest {
		lotOrder
		numberVehicles
		otherRequests
		refNumber
	}
`
export const LANE_FIELDS = gql`
	fragment LaneFields on Lane {
		_id
		from {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		to {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
	}
`
export const LANE_WITH_RATE_FIELDS = gql`
	fragment LaneRateFields on LaneWithLaneRate {
		_id
		from {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		to {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		LaneRate {
			_id
			Rate
			WeightRange
			createdAt
		}
	}
`
export const COMMENT_FIELDS = gql`
	${USER_FIELDS}
	fragment CommentFields on Comment {
		_id
		Author {
			...UserFields
		}
		comment
		createdAt
	}
`

export const SUPPLIER_FIELDS = gql`
	${USER_FIELDS}
	fragment SupplierFields on Supplier {
		_id
		isBlocked
		code
		isSuspended
		User {
			...UserFields
		}
		type
	}
`

export const BROKER_FIELDS = gql`
	${USER_FIELDS}
	fragment BrokerFields on Broker {
		_id
		User {
			...UserFields
		}
		type
	}
`
export const VEHICLE_FIELDS = gql`
	fragment VehicleFields on Vehicle {
		_id
		vehicleType
		vehicleSubType
		BrokerId
		SupplierId
		registrationNumber
	}
`
export const DRIVER_FIELDS = gql`
	fragment DriverFields on Driver {
		name
		phoneNumber
	}
`
export const BILTY_FIELDS = gql`
	fragment BiltyFields on Bilty {
		createdAt
		biltyNumber
		biltyDocument
		biltyCreatedAt
		kantaDocument
		isInformationComplete
		halfDoneBiltyDocument
	}
`
export const JOURNEY_FIELDS = gql`
	fragment JourneyFields on Journey {
		_id
		steps
		DriverStatusHistories {
			_id
			status
			timestamp
			DriverStatusJourneys {
				_id
				type
				createdAt
				formattedAddress
				coordinates {
					lat
					lng
				}
				status
				comment
				resolvedComments
				timestamp
			}
		}
	}
`
export const CORPORATE_FIELDS = gql`
	fragment CorporateFields on Corporate {
		_id
		businessCode
		businessName
	}
`

export const CLIENT_FIELDS = gql`
	${USER_FIELDS}
	fragment ClientFields on Client {
		_id
		User {
			...UserFields
		}
	}
`
export const ORDER_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment OrderLocationFields on OrderLocation {
		_id
		steps
		locationType
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`
export const CORPORATE_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment CorporateLocationFields on CorporateLocation {
		_id
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`

export const JOB_FIELDS = gql`
	${VEHICLE_FIELDS}
	${DRIVER_FIELDS}
	${BILTY_FIELDS}
	${JOURNEY_FIELDS}
	${LANE_FIELDS}
	fragment JobFields on Job {
		jobNumber
		_id
		status
		cost
		OrderDetails
		Vehicle {
			...VehicleFields
		}
		Driver {
			...DriverFields
		}
		bilty {
			...BiltyFields
		}
		Journeys {
			...JourneyFields
		}
		Lane {
			...LaneFields
		}
		SuperLane {
			...LaneFields
		}
	}
`
export const SUB_IMPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${DO_STATUS_FIELDS}
	fragment SubImportFields on ChildImport {
		_id
		loadingDT
		subOrderNumber
		status
		freeDays
		finalizedRate
		finalizedRateUnit
		containerNumber
		dimension
		freightType
		freightWeight
		eir {
			eirDocument
		}
		DoStatuses {
			...DoStatusFields
		}
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_EXPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	fragment SubExportFields on ChildExport {
		_id
		finalizedRate
		finalizedRateUnit
		loadingDT
		subOrderNumber
		status
		containerNumber
		dimension
		freightType
		freightWeight
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_LONGHAUL_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	fragment SubLongHaulFields on ChildLongHaul {
		_id
		loadingDT
		finalizedRate
		finalizedRateUnit
		subOrderNumber
		status
		vehicleType
		vehicleSubType
		OrderLocations {
			...OrderLocationFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`

export const MOVEMENT_FIELDS = gql`
	${JOB_FIELDS}
	${SUB_IMPORT_FIELDS}
	${SUB_EXPORT_FIELDS}
	${SUB_LONGHAUL_FIELDS}
	fragment MovementFields on Movement {
		ChildOrder {
			SubImport @include(if: $Import) {
				...SubImportFields
			}
			SubExport @include(if: $Export) {
				...SubExportFields
			}
			SubLongHaul @include(if: $LongHaul) {
				...SubLongHaulFields
			}
		}
		movementType
		subMovementType
		status
		Job {
			...JobFields
		}
	}
`

export const PARENT_IMPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${DO_STATUS_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentImportFields on ParentImport {
		_id
		orderNumber
		status
		document
		documentNumber
		shippingLine
		clearingAgents {
			name
			phoneNumber
		}
		DoStatuses {
			...DoStatusFields
		}
		Comments {
			...CommentFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_EXPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentExportFields on ParentExport {
		_id
		document
		documentNumber
		shippingLine
		orderNumber
		status
		vesselCutoffDT
		clearingAgents {
			name
			phoneNumber
		}
		Comments {
			...CommentFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_LONGHAUL_FIELDS = gql`
	${LONGHAUL_PREFERENCES_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentLongHaulFields on ParentLongHaul {
		_id
		orderNumber
		status
		SubLongHauls {
			...SubLongHaulFields
		}
		expectedMovementDeadline

		Comments {
			...CommentFields
		}
		preferences {
			...LongHaulPreferencesFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`

export const ORDER_FIELDS = gql`
	${PARENT_IMPORT_FIELDS}
	${PARENT_EXPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${MOVEMENT_FIELDS}
	${CLIENT_FIELDS}
	${CORPORATE_FIELDS}
	fragment OrderFields on Order {
		_id
		type
		createdAt
		businessCode
		clientEmails
		clientCCEmails
		Client {
			...ClientFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentImport @include(if: $Import) {
				...ParentImportFields
			}
			ParentExport @include(if: $Export) {
				...ParentExportFields
			}
			ParentLongHaul @include(if: $LongHaul) {
				...ParentLongHaulFields
			}
			Movements {
				...MovementFields
			}
		}
	}
`
