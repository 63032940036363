import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Form } from 'antd'

import { useAuth } from '../hooks/auth'
import Login from '../components/authentication/Login'
import ForgotPassword from '../components/authentication/ForgotPassword'
import ResetLinkSent from '../components/authentication/ResetLinkSent'

const Authentication = (props) => {
	const [form] = Form.useForm()
	const { action } = useParams()

	const [loading, setLoading] = useState(false)
	const [forgotPassword, setForgotPassword] = useState(false)
	const { authorization } = useAuth(setLoading)

	useEffect(() => {
		if (action === 'login') {
			setForgotPassword(false)
		} else if (action === 'forgotPassword') {
			setForgotPassword(true)
		}
	}, [action])

	return (
		<div className='h-full w-full overflow-scroll bg-background'>
			<Form
				key='Authentication'
				layout='vertical'
				className='flex-column-box h-full w-full'
				form={form}
				onFinish={(values) => {
					return authorization({
						forgotPassword,
						password: values.password,
						firstName: values?.firstName,
						lastName: values?.lastName,
						keepSignedIn: values?.keepSignedIn,
						email: values.email,
					})
				}}
			>
				{action === 'login' && <Login loading={loading} />}
				{action === 'forgotPassword' && <ForgotPassword loading={loading} />}
				{action === 'resetLinkSent' && <ResetLinkSent email={form.getFieldValue('email')} />}
			</Form>
		</div>
	)
}

export default Authentication
