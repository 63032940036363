import { Form, Input } from 'antd'

const ContainerCosts = (props) => {
	const { form, orderIndex, segment, doubleTwenty, orderCategory, containerKey, doIssuance } = props
	const FormItem = (props) => (
		<Form.Item
			key={props.name}
			name={props.name}
			label={props.label}
			className={props.className}
			style={props?.style}
			rules={[
				{
					required: props?.required,
					validator: (_, value = '') => {
						if (!value) return Promise.reject(new Error(props?.message))
						else return Promise.resolve()
					},
				},
			]}
		>
			<Input
				className='h-10 rounded'
				type='number'
				placeholder={props.placeholder}
				onWheel={(event) => event.currentTarget.blur()}
				addonBefore={props?.addonBefore}
				addonAfter={props?.addonAfter}
			/>
		</Form.Item>
	)

	const quotedVehicleCost = parseFloat(
		form?.getFieldValue(
			segment === 'LongHaul' ? [orderIndex, 'pricingInfo', 'vehicleCost'] : [orderIndex, 'containersSpecifics', containerKey, 'vehicleCost']
		)
	)
	const quotedDoCost = parseFloat(
		form?.getFieldValue([orderIndex, 'containersSpecifics', containerKey === 'xl2020ft' ? 'xl20ft' : containerKey, 'doCost'])
	)
	const totalQuotedCost = quotedVehicleCost + (quotedDoCost || 0)
	const totalRevenue = parseFloat(
		form?.getFieldValue(
			segment === 'LongHaul' ? [orderIndex, 'pricingInfo', 'finalizedRate'] : [orderIndex, 'containersSpecifics', containerKey, 'finalizedRate']
		)
	)
	const percentageMargin = ((totalRevenue - totalQuotedCost) * 100) / totalQuotedCost

	return (
		// prettier-ignore
		<>
			<div className={`mb-1 text-base font-semibold ${doubleTwenty ? 'text-gray-500' : 'text-primary-800'}`}>Costs</div>
			<div className='flex flex-row items-end'>
				{!['shifting', 'destuffing'].includes(orderCategory) ? (
					<>
						{FormItem({
							name: segment === 'LongHaul' ? [orderIndex,'pricingInfo', 'finalizedRate'] : [orderIndex,'containersSpecifics', containerKey, 'finalizedRate'],
							label: 'Rate Per Container',
							message: 'Rate is Required',
							placeholder: 'Enter Rate',
							className: 'w-52',
							addonAfter: 'Rs',
							required: true,
						})}
						{FormItem({
							name: segment === 'LongHaul' ? [orderIndex, 'pricingInfo', 'vehicleCost'] : [orderIndex, 'containersSpecifics', containerKey, 'vehicleCost'],
							label: 'Quoted Vehicle Cost Per Container',
							message: 'Vehicle Cost is Required',
							placeholder: 'Quoted Vehicle Cost',
							className: 'ml-10 w-52',
							addonAfter: 'Rs',
							required: true,
						})}
					</>
				) : (
					<>
						{FormItem({
							name: [orderIndex, 'containersSpecifics', containerKey, 'shiftingCost'],
							label: 'Local Vehicle Cost Per Container',
							message: 'Local Vehicle Cost is Required',
							placeholder: 'Quoted Local Vehicle Cost',
							className: 'w-52',
							addonAfter: 'Rs',
							required: true,
						})}
						{FormItem({
							name: [orderIndex, 'containersSpecifics', containerKey, 'labourCost'],
							label: 'Labour Cost Per Container',
							message: 'Labour Cost is Required',
							placeholder: 'Quoted Labour Cost',
							className: 'ml-10 w-52',
							addonAfter: 'Rs',
							required: true,
						})}
					</>
				)}
				{segment === 'Import' && doIssuance && (doubleTwenty && containerKey === 'xl20ft' ? false : true) &&
					FormItem({
						name: [orderIndex, 'containersSpecifics', containerKey === 'xl2020ft' ? 'xl20ft' : containerKey, 'doCost'],
						label: 'DO Cost Per Container',
						message: 'DO Cost is Required',
						placeholder: 'Quoted DO Cost',
						className: 'ml-10 w-52',
						addonAfter: 'Rs',
						required: true,
					})}
				{!['shifting', 'destuffing'].includes(orderCategory) && 
					<>
						<div className='self-center mt-6'>
							<div className='mx-2 mb-1 self-end font-medium'>@</div>
						</div>
						<div className='self-center mt-6'>
							<div
								className={`mx-2 rounded-lg border  px-2 py-[1px] font-medium ${
									percentageMargin > 0 ? 'bg-green-200 text-green-700 border-green-500' : percentageMargin < 0 ? 'bg-red-200 text-red-700 border-red-500' : 'bg-yellow-200 text-yellow-700 border-yellow-500'
								}`}
							>
								{percentageMargin ? parseFloat(percentageMargin)?.toFixed(1) : 0}%
							</div>
						</div>
					</>}
			</div>
		</>
	)
}

export default ContainerCosts
