// REACT
import React from 'react'
// 3RD PARTY LIBRARIES
import { Timeline } from 'antd'
import Icon from '@mdi/react'
import { mdiAccount, mdiMapMarker, mdiPhone } from '@mdi/js'
// HELPERS
import { IconText, prepareContacts } from '../../utils/Functions'
import { locationsEnum } from '../../utils/Enums'

const RouteTimeline = (props) => {
	const { clearingAgents, loading, dropoff, terminal, type } = props.record || {}

	const TimelineItem = (Location) => {
		const locationType = locationsEnum[type]?.[Location?.type]
		const contacts = Location?.location?.contacts || clearingAgents
		const preparedContacts = prepareContacts(contacts) || []
		return (
			<Timeline.Item
				dot={
					<div className={`flex h-4 w-4 items-center justify-center rounded-full ${locationType?.color}`}>
						<Icon path={mdiMapMarker} size={0.4} className='text-white' />
					</div>
				}
			>
				<div>
					<p className='font-semibold text-gray-500'>{locationType?.text}</p>
					<p className='font-medium'>{Location?.location?.formattedAddress}</p>
				</div>
				{preparedContacts?.length > 0 && (
					<div className='mt-3'>
						<p className='text-xs font-semibold text-gray-500'>{locationType?.pocType}</p>
						{preparedContacts?.map((contact) => (
							<div className='mt-3 flex items-center'>
								{IconText({
									icon: mdiAccount,
									size: 0.9,
									text: contact?.contactName,
									divClass: 'mr-4 w-2/5',
									iconClass: 'bg-gray-300 rounded-full text-gray-500 p-1',
									textClass: 'font-normal',
								})}
								{IconText({
									icon: mdiPhone,
									size: 0.9,
									text: contact?.contactPhone,
									iconClass: 'bg-gray-300 rounded-full text-gray-500 p-1',
									textClass: 'font-normal',
								})}
							</div>
						))}
					</div>
				)}
			</Timeline.Item>
		)
	}
	return (
		<Timeline>
			{type === 'Export' && terminal && terminal?.map((location) => TimelineItem({ location, type: 'terminal' }))}
			{loading
				?.slice()
				?.sort((a, b) => a.steps - b.steps)
				?.map((location) => TimelineItem({ location, type: 'loading' }))}
			{dropoff
				?.slice()
				?.sort((a, b) => a.steps - b.steps)
				?.map((location) => TimelineItem({ location, type: 'dropoff' }))}
			{type === 'Import' && terminal && terminal?.map((location) => TimelineItem({ location, type: 'terminal' }))}
		</Timeline>
	)
}

export default RouteTimeline
