import React from 'react'
import { Form, Input, Select, DatePicker, Upload, Divider, Radio, Cascader, Checkbox, Button, Tooltip } from 'antd'
import { PaperClipOutlined, PlusOutlined, MinusCircleTwoTone, LoadingOutlined, WarningFilled, CheckCircleFilled } from '@ant-design/icons'

import { storage } from '../firebase/firebaseConfig'
import { checkArray } from '../utils/Functions'
import Icon from '@mdi/react'

const normFile = (e) => {
	if (Array.isArray(e)) return e
	return e && e.fileList
}

export const FormRender = (field) => {
	const DocumentUpload = async ({ file, onProgress, onSuccess, onError }) => {
        field.setPointerEvents && field.setPointerEvents('pointer-events-none')
		field.setLoading && field.setLoading(true)
        field.messageApi && field.messageApi.open({
            key: 'updatable',
            content: (
                <div className='flex flex-row items-center'>
                    <LoadingOutlined spin className='flex text-[#1C75BC]'/>
                    <div className='font-medium'> Document upload in progress. Form will be unavailable for some time. </div>
                </div>
            ),
            duration: 0,
        })
		const response = storage.ref().child(`public/images/${field.collection}/${field.uploadLink}-${file.name}`).put(file)
		response.on(
			'state_changed',
			(snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
			(error) => {
                field.setPointerEvents && field.setPointerEvents('')
                field.messageApi && field.messageApi.open({
                    key: 'updatable',
                    content: (
                        <div className='flex flex-row items-center'>
                            <WarningFilled className='flex text-[#B71C1C]'/>
                            <div className='font-medium'> Document upload failed. Please try again. </div>
                        </div>
                    ),
                })
                return onError(error)
            },
			() => onSuccess(null, response.metadata_),
		)
	}

	const ChangeFileList = async ({ fileList }) => {
		if (fileList.length > 0) {
			fileList.forEach((file, index) => {
				if (!file.url && file.status === 'done') {
					const response = storage.ref().child(`public/images/${field.collection}/${field.uploadLink}-${file.name}`)
					response.getDownloadURL().then((result) => {
						fileList[index].url = result
                        field.setPointerEvents && field.setPointerEvents('')
                        field.messageApi && field.messageApi.open({
                            key: 'updatable',
                            content: (
                                <div className='flex flex-row items-center'>
                                    <CheckCircleFilled className='flex text-[#467D48]'/>
                                    <div className='font-medium'> Document upload successful. Please continue with data addition. </div>
                                </div>
                            ),
                        })
						field.setLoading && field.setLoading(false)
					})
				}
			})
		} else {
			field.setLoading && field.setLoading(false)
            field.setPointerEvents && field.setPointerEvents('')
		}
	}

	if (field.type === 'input') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				hidden={field.hidden}
			>
				{field.inputType === 'textArea' ? (
					<Input.TextArea className='InputField TextAreaField' rows={field.rows} />
				) : (
					<Input
						className='InputField AddOn'
						type={field.inputType}
						placeholder={field.placeholder}
						disabled={field.action === 'view' || field.disabled === true ? true : false}
						addonBefore={field.addonBefore}
						addonAfter={field.addonAfter}
					/>
				)}
			</Form.Item>
		)
	} else if (field.type === 'number') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[
					{
						required: field.required,
						validator: (_, value = '') => {
							if (value?.toString().length > 0) {
								if (value < 0) {
									return Promise.reject(new Error(`This value cannot be negative.`))
								} else if (parseInt(value) < field.minValue) {
									return Promise.reject(new Error(`This value cannot be less than ${field.minValue}`))
								} else if (parseInt(value) > field.maxValue) {
									return Promise.reject(new Error(`This value cannot be greater than ${field.maxValue}`))
								} else {
									return Promise.resolve()
								}
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				shouldUpdate={field.shouldUpdate || false}
			>
				<Input
					className={field.addonBefore || field.addonAfter ? `InputField ${field.className}` : `InputField w-full ${field.className}`}
					type='number'
					// onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
					disabled={field.action === 'view' || field.disabled === true ? true : false}
					addonBefore={field.addonBefore}
					addonAfter={field.addonAfter}
					onWheel={(event) => event.currentTarget.blur()}
				/>
			</Form.Item>
		)
	} else if (field.type === 'password') {
		return (
			<Form.Item
				key={field.name}
				name={field.name}
				label={field.label}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
			>
				<Input.Password
					className='InputField'
					addonAfter={field.addonAfter}
					addonBefore={field.addonBefore}
					disabled={field.disabled}
					placeholder={field.placeholder}
					type={field.inputType}
				/>
			</Form.Item>
		)
	} else if (field.type === 'phoneNumber') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[
					{
						required: field.required,
						validator: async (_, value = '') => {
							if (value.toString().length > 0) {
								if (value < 0) {
									return Promise.reject(new Error(`Phone Number cannot be negative.`))
								} else if (value.toString().length !== 10) {
									return Promise.reject(new Error(`Phone Number must be of 10 digits.`))
								} else {
									return Promise.resolve()
								}
							} else if (!field.required) {
								return Promise.resolve(value)
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				tooltip={field.tooltip}
			>
				<Input
					className='InputField AddOn'
					onInput={(e) => (e.target.value = e.target.value.replace(/[^\d]/g, '').slice(0, 10))}
					disabled={field.action === 'view' || field.disabled === true ? true : false}
					addonBefore={field.addonBefore}
					addonAfter={field.addonAfter}
				/>
			</Form.Item>
		)
	} else if (field.type === 'upload') {
		const UploadProps = {
			listType: 'picture',
			maxCount: field.maxCount,
			multiple: field.multiple,
			accept: 'image/png, image/jpg, image/jpeg, application/pdf',
			customRequest: DocumentUpload,
			onChange: ChangeFileList,
			className: 'FullWidth FlexColumnBox',
			onPreview: (file) => file?.url && window.open(file?.url),
		}
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				valuePropName='fileList'
				getValueFromEvent={normFile}
				style={{ textAlign: 'center' }}
			>
				{field.uploadType === 'button' ? (
					<Upload {...UploadProps} style={{ width: 'auto' }}>
						<Button className='btn-primary-highlight' style={{ height: 48 }} block>
							<div className='flex items-center'>
								{field?.icon ? (
									<Icon path={field?.icon} size={1} className='text-primary-800' />
								) : (
									<p className='NoMargin'>
										<PaperClipOutlined style={{ fontSize: 24, color: '#0066CC' }} />
									</p>
								)}
								<p className='BoldFont FontSize12 PrimaryColor' style={{ marginLeft: 12 }}>
									UPLOAD
								</p>
							</div>
						</Button>
					</Upload>
				) : (
					<Upload.Dragger {...UploadProps}>
						<p className='NoMargin'>
							<PaperClipOutlined style={{ fontSize: 36, color: '#0066CC' }} />
						</p>
						<p className='SectionTitle'>Upload Document</p>
					</Upload.Dragger>
				)}
			</Form.Item>
		)
	} else if (field.type === 'radioGroup') {
		return (
			<Form.Item
				key={field.label}
				name={field.name}
				label={field.label}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
			>
				{field.button === true ? (
					<Radio.Group
						onChange={(e) => {
							if (field.clearData) {
								field.formReference.setFieldsValue(field.clearData)
							}
						}}
						buttonStyle='solid'
					>
						{field.options?.map((option, index) => {
							return field.valueProperty && field.displayProperty ? (
								<Tooltip title={option.tooltip} mouseEnterDelay={1}>
									<Radio.Button key={`radio-${field.name}-${index}`} value={option[field.valueProperty]}>
										{option[field.displayProperty]}
									</Radio.Button>
								</Tooltip>
							) : (
								<Radio.Button key={option} value={option}>
									{option}
								</Radio.Button>
							)
						})}
					</Radio.Group>
				) : (
					<Radio.Group>
						{field.options.map((option) => (
							<Radio key={option} value={option.key}>
								{option.label}
							</Radio>
						))}
					</Radio.Group>
				)}
			</Form.Item>
		)
	} else if (field.type === 'dateTime') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				dependencies={field.dependencies}
				initialValue={field.initialValue}
			>
				<DatePicker
					className={`InputField ${field.className}`}
					disabled={field.disabled === true ? true : false}
					disabledDate={field.disabledDate}
					disabledTime={field.disabledTime}
					format={field.format}
					showNow={field.showNow}
					defaultPickerValue={field.defaultPickerValue}
					showTime={field.showTime !== false ? { defaultValue: field.defaultTimeValue } : false}
				/>
			</Form.Item>
		)
	} else if (field.type === 'select') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				initialValue={field.initialValue}
				tooltip={field.tooltip}
				noStyle={field.noStyle}
			>
				<Select
					className='SelectField'
					// suffixIcon={<CaretDownOutlined />}
					mode={field.mode}
					disabled={field.action === 'view' || field.disabled === true}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
						+(field.options.findIndex((option) => option.name?.toLowerCase().includes(input)) === -1 && option.children === 'Other')
					}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					maxTagCount='responsive'
					tokenSeparators={field.tokenSeparators}
					allowClear={field.allowClear}
				>
					{field.options?.map((option, index) => {
						return field.valueProperty && field.displayProperty ? (
							<Select.Option key={`select-${field.name}-${index}`} value={option[field.valueProperty]}>
								{option[field.displayProperty]}
							</Select.Option>
						) : (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						)
					})}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'recentSelect') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
			>
				<Select
					className='SelectField'
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					onChange={(event) => {
						if (field.displayProperty === 'formattedAddress' && field.setField[1] !== 'terminal') {
							const setField = field.setField
							const parentField = field.form?.getFieldValue(setField[0])
							parentField[setField[1]][setField[2]] = { ...parentField[setField[1]][setField[2]], ...JSON.parse(event) }
							field.form?.resetFields([setField[0]])
							field.form?.setFieldsValue({ [setField[0]]: parentField })
						}
					}}
					allowClear={field.allowClear}
				>
					{field.options?.map((option, index) =>
						[...field?.name].includes('terminal') ? (
							<Select.Option key={index} value={option[field.valueProperty]}>
								{option[field.displayProperty]}
							</Select.Option>
						) : (
							<Select.Option key={index} value={JSON.stringify(option)}>
								{option[field.displayProperty]}
							</Select.Option>
						)
					)}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'emails') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				tooltip={field.tooltip}
				rules={[
					{
						required: field.required,
						validator: (_, value) => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							if (value?.toString().length > 0) {
								if (expression.test(value[value.length - 1])) {
									return Promise.resolve()
								} else {
									return Promise.reject(new Error('Please enter valid emails.'))
								}
							} else if (!field.required) {
								return Promise.resolve()
							} else {
								return Promise.reject(new Error(field.message))
							}
						},
					},
				]}
				initialValue={field.initialValue}
				style={{ margin: 0 }}
			>
				<Select
					className='EmailsField'
					mode={field.mode}
					disabled={field.disabled}
					showSearch={field.showSearch}
					placeholder={field.placeholder}
					maxTagCount='responsive'
					tokenSeparators={field.tokenSeparators}
					onChange={(value) => {
						const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
						if (value && !expression.test(value[value.length - 1])) {
							value.pop()
						}
					}}
					// tagRender={({ label }) => (
					// 	<Tag closeIcon={<CloseCircleFilled className='ml-1 flex items-center justify-center' />} closable={true} className='flex items-center font-inter'>
					// 		{label}
					// 	</Tag>
					// )}
				>
					{field.options?.map((option) => (
						<Select.Option key={option} value={option}>
							{option}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		)
	} else if (field.type === 'cascader') {
		return (
			<Form.Item
				key={field.label}
				label={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
			>
				<Cascader className='CascaderField' options={field.options} expandTrigger='hover' />
			</Form.Item>
		)
	} else if (field.type === 'checkbox') {
		return (
			<Form.Item
				key={field.label}
				name={field.name}
				rules={[{ required: field.required, message: field.message }]}
				tooltip={field.tooltip}
				initialValue={field.initialValue}
				className='m-0'
				valuePropName='checked'
			>
				<Checkbox
					onChange={(e) => {
						if (field.fillData && Object.values(field.fillData).length > 0) {
							if (e.target.checked) {
								field.formReference.setFieldsValue(field.fillData)
							} else {
								field.formReference.setFieldsValue(field.clearData)
							}
						}
					}}
					className={field.className}
					defaultChecked={field.initialValue}
				>
					{field.label}
				</Checkbox>
			</Form.Item>
		)
	} else if (field.type === 'dynamicFields') {
		return (
			<Form.List key={field.label} name={field.name} label={field.label} initialValue={field.initialValue}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((singleEntryField, singleEntryFieldIndex) =>
							field.groups ? (
								<div key={singleEntryField.name}>
									<div className='mb-6 flex items-center'>
										<Divider className='SectionTitle' orientation='left' plain>
											{field.label} # {singleEntryFieldIndex + 1}
										</Divider>
										{fields.length > field.minEntries && (
											<Button
												className='ml-6 bg-red-100'
												onClick={() => {
													remove(singleEntryField.name)
												}}
											>
												Delete Entry
											</Button>
										)}
									</div>
									{field.groups.map((groupEntry) => (
										<div className='flex items-start'>
											{groupEntry?.map((item, index) => (
												<div className={`w-full ${index !== 0 ? 'ml-6' : ''}`}>{FormRender({ ...item, name: [singleEntryField.name, item.name] })}</div>
											))}
										</div>
									))}
								</div>
							) : (
								<div key={singleEntryField.key} className='flex items-center'>
									{field.fields?.map((item, index) => (
										<div className={`w-full ${index !== 0 ? 'ml-6' : ''}`}>{FormRender({ ...item, name: [singleEntryField.name, item.name] })}</div>
									))}
									{fields.length > field.minEntries && (
										<MinusCircleTwoTone
											twoToneColor='#FF0000'
											className='ml-6'
											onClick={() => {
												remove(singleEntryField.name)
											}}
										/>
									)}
								</div>
							)
						)}
						<Form.Item noStyle>
							<Button icon={<PlusOutlined style={{ fontSize: '18px' }} />} className='btn-ghost' onClick={() => add()}>
								{fields.length === 0 ? 'Add ' : 'Add Another'} {field.label}
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		)
	} else if (field.type === 'dependency') {
		return (
			<Form.Item
				key={field.name + 'dependency'}
				shouldUpdate={(prevValues, currentValues) => {
					if (Array.isArray(field.independent)) {
						return (
							field?.independent?.reduce((prev, curr) => prev?.[`${curr}`], prevValues) !==
							field?.independent?.reduce((prev, curr) => prev?.[`${curr}`], currentValues)
						)
					} else return prevValues[field.independent] !== currentValues[field.independent]
				}}
				noStyle
			>
				{({ getFieldValue }) => {
					if (Array.isArray(field.independent)) {
						const fieldValue = getFieldValue(field.independent[0])
						return field.condition.includes(field?.independent?.slice(1)?.reduce((prev, curr) => prev?.[`${curr}`], fieldValue))
							? RenderSchema(field.successSchema)
							: RenderSchema(field.failureSchema)
					} else return field.condition.includes(getFieldValue(field.independent)) ? RenderSchema(field.successSchema) : RenderSchema(field.failureSchema)
				}}
			</Form.Item>
		)
	} else if (field.type === 'divider') {
		return <Divider key={'divider'} className={`font-bold text-gray-500 ${field.className}`} />
	}
}

export const RenderSchema = (schemaJSON) =>
	schemaJSON.map((schema, index) =>
		schema.fields ? (
			<div key={`RenderSchema_${index}`}>
				<div className='mb-3'>
					<p className='mt-6 text-base font-bold text-gray-400'>{schema.title}</p>
					{schema.description && <p className='text-xs text-gray-500'>{schema.description}</p>}
				</div>
				{schema.fields.map((field) => (checkArray(field) ? RowRenderer(field, index) : FormRender(field)))}
			</div>
		) : checkArray(schema) ? (
			RowRenderer(schema, index)
		) : (
			FormRender(schema)
		)
	)

export const RowRenderer = (fields, index) => {
	const visibleFields = fields?.filter((field) => !field.hidden)
	const hiddenFields = fields?.filter((field) => field.hidden)
	return (
		<div key={`RowRenderer_${index}`} className='flex items-start'>
			{visibleFields.map((field, index) => (
				<div key={field.name} className={`${index > 0 && 'ml-6'} ${field.className}`} style={{ width: `${100 / visibleFields.length}%` }}>
					{FormRender(field)}
				</div>
			))}
			{hiddenFields.map((field) => (
				<div key={field.name} className='hidden'>
					{FormRender(field)}
				</div>
			))}
		</div>
	)
}
