import { mdiAlertCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Layout } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../recoil/atoms'

const RedirectRfqPage = () => {
	const { segment, errorType, rfqId } = useParams()

	const navigate = useNavigate()
	const user = useRecoilValue(userAtom)
	const activeCorporate = JSON.parse(localStorage.getItem('activeCorporate')) || JSON.parse(sessionStorage.getItem('activeCorporate'))

	return (
		<Layout.Content className='flex h-full items-center justify-center overflow-y-hidden rounded-lg !bg-white pb-2 shadow-[0_1px_8px_rgba(0,0,0,0.16)]'>
			<div className='flex w-2/5 flex-col items-center rounded border border-dashed border-black-200 px-8 py-32'>
				<Icon size={3} className='mb-8 text-red-600' path={mdiAlertCircleOutline} />
				<div className='mb-6 text-center text-base font-medium text-gray-400'>
					{errorType === 'processed'
						? 'This RFQ has already been processed. Kindly redirect back to the RFQ portal.'
						: 'You do not have access to create order for this specific client. Please contact relevant team for the access.'}
				</div>
				<div className='flex flex-row items-center gap-4'>
					<Button className='btn-back' onClick={() => (window.location.href = `${process.env.REACT_APP_RFQ_URL}/dashboard/${segment}`)}>
						GO BACK
					</Button>
					{errorType !== 'processed' && (
						<Button
							className='btn-delete'
							onClick={() => {
								navigate(`/shipments/${user?.isKAM ? 'kam' : 'client'}/${activeCorporate?._id}/${segment}/create/${rfqId}`, { replace: true })
							}}
						>
							RETRY
						</Button>
					)}
				</div>
			</div>
		</Layout.Content>
	)
}

export default RedirectRfqPage
