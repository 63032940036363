import { doc, updateDoc } from 'firebase/firestore'
import { firestore } from '../firebase/firebaseConfig'
import useMutate from './useMutate'
import { useRecoilValue } from 'recoil'
import { lanesAtom, userAtom } from '../recoil/atoms'
import { useParams } from 'react-router-dom'
import { GraphQLEnums } from '../utils/Enums'
import { getBaseOrder, removeNullUndefined } from '../utils/Functions'
import { portSelector } from '../recoil/selectors'
import { mutateGraphQL } from '../services/MutateGraphQl'
import { useLazyFetch } from './useFetchLazyQuery'

export const useCreateOrder = () => {
	const { segment } = useParams()

	const user = useRecoilValue(userAtom)
	const ports = useRecoilValue(portSelector)
	const createOrderMutation = useMutate({ state: segment, type: 'Orders', action: 'create' })
	const updateEmailsMutation = useMutate({ state: 'corporateEmails', type: 'Corporates', action: 'update' })

	const createOrderFunction = async (props) => {
		const {
			values,
			isKAM,
			corporate,
			rfqId,
			rfqData,
			orignalRfqData,
			setFailed,
			values: { clientCCEmails, clientEmails },
		} = props
		console.log({ props })

		const order = getBaseOrder({ clientEmails, clientCCEmails, isKAM, segment, values, user, corporate, ports, rfqId, rfqData })
		const promises = []
		promises.push(
			mutateGraphQL({
				mutationData: { _id: corporate?._id, creationEmail: [...new Set(clientEmails.concat(corporate.creationEmail))] },
				mutateFunction: updateEmailsMutation?.mutateFunction,
				mutationInputFunction: updateEmailsMutation?.mutationInputFunction,
				mutationOutputFunction: updateEmailsMutation?.mutationOutputFunction,
			}),
			mutateGraphQL({
				mutationData: order,
				mutateFunction: createOrderMutation?.mutateFunction,
				mutationInputFunction: createOrderMutation?.mutationInputFunction,
				mutationOutputFunction: createOrderMutation?.mutationOutputFunction,
			})
		)
		console.log({ order })
		return await Promise.all(promises)
			.catch((error) => {
				console.log(error)
				setFailed(true)
				return { type: 'error' }
			})
			.then(async (response) => {
				if (response?.type === 'error') {
					let updatedRFQ = { ...orignalRfqData, internalStatus: 'failed' }
					updatedRFQ = removeNullUndefined(updatedRFQ)
					await updateDoc(doc(firestore, 'rfq', rfqId), {
						...updatedRFQ,
					})
					return {
						type: 'error',
						message: `Order Creation was unsuccessful`,
						description: 'Order creation has failed. Kindly reach out to the BridgeLinx product team for assistance.',
						response,
					}
				} else {
					try {
						if (rfqId) {
							let updatedRFQ = { ...orignalRfqData }
							if (segment !== 'LongHaul') {
								updatedRFQ['shipmentInfo'] = updatedRFQ?.shipmentInfo?.map((shipment) => ({
									...shipment,
									shipmentStatus: 'processed',
									...(segment === 'Import' &&
										shipment?.documentNumber === response?.[1]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.documentNumber && {
											shipmentStatus: response?.[1]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber ? 'processed' : 'unProcessed',
											orderNumber: response?.[1]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber || null,
										}),
								}))
							} else {
								updatedRFQ['orderNumber'] = response?.[1]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber
							}
							updatedRFQ = removeNullUndefined(updatedRFQ)
							await updateDoc(doc(firestore, 'rfq', rfqId), {
								...updatedRFQ,
							})
						}

						return {
							type: 'success',
							message: `Order # ${response?.[1]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber} created successfully`,
							description: `Order created successfully. Please check orders table and verify.`,
							response,
						}
					} catch (error) {
						console.log(error)
						return {
							type: 'error',
							message: `Order Creation was unsuccessful`,
							description: 'Order creation has failed. Kindly reach out to the BridgeLinx product team for assistance.',
							response,
						}
					}
				}
			})
	}
	return [createOrderFunction]
}

export const useUpdateRFQData = () => {
	const { segment } = useParams()
	const lanes = useRecoilValue(lanesAtom)
	const [fetchOrders] = useLazyFetch({ type: 'Orders' })

	const formatRocData = ({ rfqData, rfqId }) => {
		let EdittedValues = {}
		const lane = lanes?.find((lane) => lane?._id === rfqData?.subLaneName)
		if (rfqData.orderType === 'LongHaul') {
			EdittedValues = {
				rfqId: rfqId,
				lane: {
					to: lane?.to?.name,
					from: lane?.from?.name,
				},
				supplyStatus: rfqData?.supplyStatus,
				supplyComment: rfqData?.supplyComment,
				segment: rfqData.orderType,
				orderLocations: rfqData?.orderLocations,
				comment: rfqData?.comment,
				clientPT: rfqData?.clientPT,
				revenue: rfqData?.revenue,
				revenueUnit: rfqData?.revenueUnit,
				status: rfqData?.status,
				movementType: rfqData.movementType,
				orderNumber: rfqData?.orderNumber,
				businessCode: rfqData.businessCode,
				expectedMovementDate: rfqData.expectedMovementDate,
				bidCutoffDateTime: rfqData.bidCutoffDateTime,
				totalWeightPerRFQ: rfqData?.totalWeightPerRFQ,
				costPerVehicle: rfqData?.vehicleInfos,
				quotedRate: rfqData?.quotedRate,
				quotedRateUnit: rfqData?.quotedRateUnit,
				paymentTerms: rfqData?.paymentTerms,
			}
		} else {
			const updatedShipmentInfo = rfqData?.shipmentInfo?.map((shipment) => {
				const shipmentObj = {
					...shipment,
					// documentNumber: shipment?.documentNumber,
					containerInfo: shipment.containerInfo.map((container) => {
						const containerDetails = []
						for (var key in container) {
							if (key !== 'containerDimensions') {
								containerDetails.push({
									...container[key],
									revenue: container[key].revenue,
									revenueUnit: container[key].revenueUnit,
									quotedRate: container[key].vehicleCost,
									paymentTerms: container[key].vendorPaymentTerms,
									labourCost: container[key].labourCost,
									localVehicleCost: container[key].localVehicleCost,
									localVehicleType: container[key].localVehicleType,
									doCost: rfqData.shipmentInfo?.find((val) => shipment.documentNumber === val.documentNumber)?.containerInfo?.[key]?.doCost,
								})
							}
						}
						return {
							containerDetails,
							containerDimensions: container.containerDimensions,
						}
					}),
				}
				return shipmentObj
			})

			EdittedValues = {
				rfqId: rfqId,
				lane: {
					to: lane?.to?.name,
					from: lane?.from?.name,
				},
				status: rfqData?.status,
				revenue: rfqData?.revenue,
				comment: rfqData?.comment,
				segment: rfqData.orderType,
				clientPT: rfqData?.clientPT,
				revenueUnit: rfqData?.revenueUnit,
				shipmentInfo: updatedShipmentInfo,
				movementType: rfqData.movementType,
				businessCode: rfqData.businessCode,
				supplyStatus: rfqData?.supplyStatus,
				supplyComment: rfqData?.supplyComment,
				orderLocations: rfqData?.orderLocations,
				costPerVehicle: rfqData?.costPerVehicle,
				bidCutoffDateTime: rfqData.bidCutoffDateTime,
				totalWeightPerRFQ: rfqData?.totalWeightPerRFQ,
				expectedMovementDate: rfqData.expectedMovementDate,
			}
		}

		removeNullUndefined(EdittedValues)
		console.log({ EdittedValues })
		return EdittedValues
	}

	const updateRfqFunction = async (props) => {
		const { values, rfqData, orignalRfqData, rfqId, corporate } = props
		console.log({ values, rfqData, rfqId, corporate })

		try {
			let updatedRFQ = { ...orignalRfqData, status: 'win', internalStatus: 'completed' }
			const { where, options, conditions } = GraphQLEnums?.Orders?.fetch?.default?.getVariables({
				type: segment,
				rfqId: rfqId,
				businessCode: corporate?.businessCode,
				statuses: ['Order Pending', 'Order Confirmed'],
			})
			const orders = (await fetchOrders({ variables: { where, options, ...conditions } }))?.data?.orders
			console.log({ orders })
			if (segment !== 'LongHaul') {
				orders?.forEach((order) => {
					updatedRFQ['shipmentInfo'] = updatedRFQ?.shipmentInfo?.map((shipment) => ({
						...shipment,
						...(segment === 'Import' &&
							shipment?.documentNumber === order?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.documentNumber && {
								shipmentStatus: order?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber ? 'processed' : 'unProcessed',
								orderNumber: order?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber || null,
							}),
					}))
				})
			} else {
				updatedRFQ['orderNumber'] = orders?.[0]?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]?.orderNumber
			}

			console.log({ updatedRFQ })
			const EdittedValues = formatRocData({ rfqData: updatedRFQ, rfqId })
			await fetch(`${process.env.REACT_APP_ROC_URL}/closerfq`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					...EdittedValues,
				}),
			}).then(async (response) => {
				const ROCdata = await response.json()
				console.log({ ROCdata })
				updatedRFQ = removeNullUndefined(updatedRFQ)
				await updateDoc(doc(firestore, 'rfq', rfqId), {
					...updatedRFQ,
					ROCdata,
				})
			})

			return {
				type: 'success',
				message: `RFQ updated successfully`,
				description: `RFQ updated successfully. Please check RFQ table and verify.`,
			}
		} catch (error) {
			console.log({ error })
			let updatedRFQ = { ...orignalRfqData, internalStatus: 'failed' }
			await updateDoc(doc(firestore, 'rfq', rfqId), {
				...updatedRFQ,
			})

			return {
				type: 'error',
				message: `RFQ Updation was unsuccessful`,
				description: 'RFQ Updation has failed. Kindly reach out to the BridgeLinx product team for assistance.',
				response: error,
			}
		}
	}

	return [updateRfqFunction]
}
