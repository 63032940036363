import { Spin } from 'antd'
import moment from 'moment'
import { SegmentsEnum } from './Enums'
import Icon from '@mdi/react'
import firebase from 'firebase/compat/app'

import { GraphQLEnums } from '../utils/Enums'
import { mutateGraphQL } from '../services/MutateGraphQl'
import scrollIntoView from 'scroll-into-view'

/* ============================================================================================== */
/* ===================================== UTILITY FUNCTIONS ====================================== */
/* ============================================================================================== */
export const check = (Param) => Param !== null && Param !== undefined
export const checkArray = (props) => props?.constructor === Array
export const pathLocation = (path, index) => path.split('/')[index]
export const readableString = (string) => string?.replace(/([A-Z])/g, ' $1')
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
export const capitalize = (string) => string?.[0].toUpperCase() + string.slice(1)
export const getDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY') : '')
export const get12HourDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY hh:mm A') : '')
export const get24HourDate = (time) => (time ? moment(time, 'x').format('DD-MM-YYYY HH:mm') : '')
export const Loader = ({ tip }) => (
	<Spin className='Spinner' tip={tip && <p className='BoldFont FontSize16 PrimaryColor MarginTop'>Loading...</p>} size='large' />
)
export const ascSort = (array, property) => array?.sort((a, b) => a?.[property]?.localeCompare(b?.[property], 'en', { numeric: true }))
export const descSort = (array, property) => array?.sort((a, b) => b?.[property]?.localeCompare(a?.[property], 'en', { numeric: true }))
export function range(start, end) {
	let array = new Array(end - start + 1)
	for (let i = 0; i < end - start + 1; i++) array[i] = start + i
	return array
}
export const handleScroll = async ({ scrollToClass }) => {
	await delay(100)
	scrollIntoView(document.querySelector(scrollToClass), {
		align: {
			top: 0,
		},
	})
}
export const groupByProperty = (array, property) => {
	return array.reduce((previous, current) => {
		if (!previous[current[property]]) {
			previous[current[property]] = []
		}
		previous[current[property]].push(current)
		return previous
	}, {})
}

export const groupContainers = (array) => {
	return array.reduce((previous, current) => {
		if (!previous[current[0]?.containerDimensions]) {
			previous[current[0]?.containerDimensions] = []
		}
		previous[current[0]?.containerDimensions].push(current)
		return previous
	}, {})
}

export const removeNullUndefined = (object) => {
	// this version handles empty arrays and nested empty objects
	// taken from https://stackoverflow.com/a/52368116
	// added a check to remove updatedAt if that is the only updated key
	Object.entries(object).forEach(([k, v]) => {
		if (v && typeof v === 'object') {
			removeNullUndefined(v)
		}
		if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined) {
			if (Array.isArray(object)) {
				object.splice(k, 1)
			} else {
				delete object[k]
			}
		}
	})
	if (Object.keys(object).length === 1 && object.updatedAt) {
		delete object.updatedAt
	}
	return object
}

/* ============================================================================================= */
/* ===================================== COMMON FUNCTIONS ====================================== */
/* ============================================================================================= */
export const formatContacts = (contacts) => {
	return contacts?.length > 0 && contacts?.map((contact) => formatContact(contact))
}
export const formatContact = (contact) => {
	return { contactName: contact.name, contactPhone: contact.phoneNumber?.replace('+92', '') }
}

export const prepareContacts = (contacts) => {
	return contacts?.length > 0 && contacts?.map((contact) => prepareContact(contact))?.filter(Boolean)
}
export const prepareContact = (contact) => {
	if (contact.name && contact.phoneNumber) {
		const contactPhone = contact.phoneNumber?.replace('+92', '')
		return { contactName: contact.name, contactPhone: `+92${contactPhone}` }
	} else if (contact.contactName && contact.contactPhone) {
		const contactPhone = contact.contactPhone?.replace('+92', '')
		return { contactName: contact.contactName, contactPhone: `+92${contactPhone}` }
	}
}

export const formatAddresses = (addresses, contactRequired = true) => {
	return addresses?.map((address) => ({
		_id: address?.Location?._id,
		formattedAddress: address?.Location?.formattedAddress,
		additionalDetails: `${address.Location?.additionalDetails}, ${address?.Location?.Region?.name || ''}`,
		contacts:
			address.contacts?.map((contact) => ({ contactName: contact.name, contactPhone: contact.phoneNumber.replace('+92', '') })) ||
			(contactRequired ? [{ contactName: '', contactPhone: '' }] : []),
	}))
}

/* ============================================================================================= */
/* ===================================== INITIAL RFQ DATA ====================================== */
/* ============================================================================================= */

export const initialOrderLocations = ({ isRFQOrder, ports, segment, corporate, rfqData }) => {
	const orderLocations = {}
	rfqData?.orderLocations?.forEach((location) => {
		orderLocations[location?.locationType] = [...(orderLocations?.[location?.locationType] || []), location]
	})

	const loadingAddresses = formatAddresses(
		isRFQOrder
			? corporate?.CorporateLocations?.filter((cropLocation) =>
					orderLocations?.loading?.some((orderLocation) => orderLocation?.LocationId === cropLocation?.Location?._id)
			  )
			: corporate?.CorporateLocations?.[0]
			? [corporate?.CorporateLocations?.[0]]
			: [{}]
	)
	const dropoffAddresses = formatAddresses(
		isRFQOrder
			? corporate?.CorporateLocations?.filter((cropLocation) =>
					orderLocations?.dropoff?.some((orderLocation) => orderLocation?.LocationId === cropLocation?.Location?._id)
			  )
			: corporate?.CorporateLocations?.[0]
			? [corporate?.CorporateLocations?.[0]]
			: [{}]
	)

	const portAddress =
		segment === 'Import' && isRFQOrder
			? Object.values(ports || {})?.filter((cropLocation) =>
					orderLocations?.loading?.some((orderLocation) => orderLocation?.LocationId === cropLocation?._id)
			  )[0]
			: segment === 'Export' && isRFQOrder
			? Object.values(ports || {})?.filter((cropLocation) =>
					orderLocations?.dropoff?.some((orderLocation) => orderLocation?.LocationId === cropLocation?._id)
			  )[0]
			: {
					formattedAddress: ports?.KICT?.formattedAddress,
					additionalDetails: ports?.KICT?.additionalDetails,
					_id: ports?.KICT?._id,
			  }

	const terminalAddress =
		segment === 'Import'
			? orderLocations?.['Empty Container Dropoff']?.[0]?.LocationId
			: segment === 'Export' && orderLocations?.['Empty Container Pickup']?.[0]?.LocationId
	return { loadingAddresses, dropoffAddresses, portAddress, terminalAddress }
}

export const initialContainerSpecifics = ({ containerInfo, movementType, type }) => {
	let containersSpecifics = {
		xl20ft: { quantity: 0 },
		xl40ft: { quantity: 0 },
		xl40ftHC: { quantity: 0 },
		xl40ftOT: { quantity: 0 },
		other: { quantity: 0 },
	}
	const groupedContainerInfos = containerInfo?.reduce((previous, current) => {
		if (!previous[current['containerDimensions']]) {
			previous[current['containerDimensions']] = []
		}
		previous[current['containerDimensions']].push({ ...current, doCost: current?.doCost })
		return previous
	}, {})

	Object.entries(groupedContainerInfos || {})?.forEach(([key, entries]) => {
		entries?.forEach((entry) => {
			Object.keys(entry)
				?.filter((value) => !isNaN(parseFloat(value)) && isFinite(value))
				?.forEach((entryKey) => {
					const entryQuantity = parseInt(entry?.[entryKey]?.acceptedCount || entry?.[entryKey]?.quantity)
					containersSpecifics[key] = {
						quantity: (containersSpecifics[key]?.quantity || 0) + entryQuantity,
						finalizedRate: (containersSpecifics[key]?.finalizedRate || 0) + parseInt(entry?.[entryKey]?.revenue) * entryQuantity,
						vehicleCost: (containersSpecifics[key]?.vehicleCost || 0) + parseInt(entry?.[entryKey]?.vehicleCost) * entryQuantity,
						doCost: entry?.doCost,
						labourCost: entry?.[entryKey]?.labourCost,
						shiftingCost: entry?.[entryKey]?.localVehicleCost,
					}
				})
		})
	})

	Object?.keys(containersSpecifics)?.forEach((key) => {
		containersSpecifics[key] = {
			...containersSpecifics?.[key],
			finalizedRate: containersSpecifics?.[key]?.finalizedRate / containersSpecifics?.[key]?.quantity,
			vehicleCost: containersSpecifics?.[key]?.vehicleCost / containersSpecifics?.[key]?.quantity,
		}
	})

	return containersSpecifics
}

export const initialCounterState = ({ rfqData, segment }) => {
	const shipmentInfo = rfqData?.shipmentInfo || {}
	const vehicleInfos = rfqData?.vehicleInfos || {}
	let counter = {}
	if (segment !== 'LongHaul') {
		Object.values(shipmentInfo || {}).forEach((_, index) => {
			counter[index] = {
				xl20ft: 0,
				xl40ft: 0,
				xl40ftHC: 0,
				xl40ftOT: 0,
				other: 0,
			}
		})
		Object.values(shipmentInfo || {})?.forEach((shipment, index) => {
			const groupedContainerInfos = groupByProperty(shipment?.containerInfo, 'containerDimensions')

			Object.entries(groupedContainerInfos || {})?.forEach(([key, entries]) => {
				entries?.forEach((entry) => {
					Object.keys(entry)
						?.filter((value) => !isNaN(parseFloat(value)) && isFinite(value))
						?.forEach((entryKey) => {
							const entryQuantity = parseInt(entry?.[entryKey]?.acceptedCount || entry?.[entryKey]?.quantity)
							counter[index][key] = (counter[index][key] || 0) + entryQuantity
						})
				})
			})
		})
	} else {
		counter[0] = {
			xl20ft: 0,
			xl40ft: 0,
			xl40ftHC: 0,
			xl40ftOT: 0,
			flatbed: 0,
			dumper: 0,
			halfBody: 0,
			other: 0,
		}
		counter[0][vehicleInfos?.[0]?.vehicleType] = parseInt(vehicleInfos?.[0]?.acceptedCount || vehicleInfos?.[0]?.quantity)
	}
	return counter
}

export const initialVehicleDetails = ({ costPerVehicle }) => {
	return costPerVehicle?.map((vehicle) => ({
		vehicleType: vehicle?.vehicleType,
		maxWeight: vehicle?.maxWeight,
		minWeight: vehicle?.minWeight,
		quantity: vehicle?.acceptedCount || vehicle?.quantity,
		quotedRate: vehicle?.quotedRate.toString(),
	}))
}

export const prepareInitialValues = ({ form, segment, ports, corporate, rfqData }) => {
	let initialData = {}
	const isRFQOrder = Object.keys(rfqData || {})?.length > 0

	const vehicleDetails = initialVehicleDetails({ costPerVehicle: rfqData?.costPerVehicle })
	const { loadingAddresses, dropoffAddresses, portAddress, terminalAddress } = initialOrderLocations({ isRFQOrder, ports, segment, corporate, rfqData })

	Object.values(rfqData?.shipmentInfo || { 0: {} })?.forEach((shipment, index) => {
		initialData[index] = {
			...(Object.keys(shipment).length > 0 && segment !== 'LongHaul'
				?   
                {
                    freightType: corporate?.freightType?.[0],
                    loadingDT: moment(rfqData?.expectedMovementDate || moment(), 'DD-MM-YYYY'),
                    ...(segment === 'Import' && {
                        clientCommittedReturnDeadline: moment(rfqData?.clientCommittedReturnDeadline || moment(), 'DD-MM-YYYY'),
                        eirSubmissionDeadline: moment(rfqData?.eirSubmissionDeadline || moment(), 'DD-MM-YYYY'),
                    }),
                    ...(segment === 'Export' && {
                        clientCommittedDeliveryDate: moment(rfqData?.clientCommittedDeliveryDate || moment(), 'DD-MM-YYYY'),
                    }),
                    vehicleDetails: vehicleDetails,
                    containersSpecifics: initialContainerSpecifics({
                        containerInfo: shipment?.containerInfo,
                        movementType: rfqData?.movementType,
                        type: segment,
                    }),
                    pricingInfo: {
                        finalizedRate: rfqData?.revenue,
                        ...(rfqData?.revenueUnit && { finalizedRateUnit: capitalize(rfqData?.revenueUnit) }),
                        ...(rfqData?.costPerVehicle && { quotedRateUnit: capitalize(rfqData?.costPerVehicle?.[0]?.quotedRateUnit) }),
                    },
                    shipmentInfo: {
                        ...(shipment?.document?.[0] && { document: [{ uid: 1, name: 'Shipment Document', status: 'done', url: shipment?.document?.[0] }] }),
                        shippingLine: shipment?.shippingLine,
                        documentNumber: shipment?.documentNumber,
                    },
                    specialRequests: {
                        orderCategory: rfqData?.movementType,
                    },
				}
				: 
                {
                    pricingInfo: {
                        vehicleCost: rfqData?.vehicleInfos?.[0]?.quotedRate || rfqData?.quotedRate,
                        finalizedRate: rfqData?.revenue,
                        ...(rfqData?.revenueUnit && { finalizedRateUnit: capitalize(rfqData?.revenueUnit) }),
                    },
                    loadingDT: moment(rfqData?.expectedMovementDate || moment(), 'DD-MM-YYYY'),
                    shipmentInfo: {
                        freightType: rfqData?.commodity,
                        freightWeight: rfqData?.totalWeightPerRFQ,
                    },
                    specialRequests: {
                        lotOrder: rfqData?.movementType === 'Lot',
                        numberVehicles: parseInt(rfqData?.vehicleInfos?.[0]?.acceptedCount || rfqData?.vehicleInfos?.[0]?.quantity),
                    },
                    vehicleInfo: {
                        vehicleType: rfqData?.vehicleInfos?.[0]?.vehicleType,
                    },
				}),

			clearingAgents: formatContacts(corporate?.clearingAgents) || [{ contactName: '', contactPhone: '' }],
			loading: segment === 'Import' ? portAddress : loadingAddresses,
			dropoff: segment === 'Export' ? portAddress : dropoffAddresses,
			terminal: [{ _id: terminalAddress }],
		}
	})
	return initialData
}

/* ============================================================================================= */
/* ===================================== COMMON FUNCTIONS ====================================== */
/* ============================================================================================= */
export const CardHeader = (title, description) => (
	<div className='flex flex-col'>
		<p className='text-sm font-bold text-white'>{title}</p>
		{description && <p className='text-xs text-white'>{description}</p>}
	</div>
)

export const SpecialCategoryPill = (category) => (
	<div
		className={`${
			// prettier-ignore
			['Shifting', 'Lot Order'].includes(category) ? 'bg-yellow-400' : category === 'Double Twenty' ? 'bg-gray-400 text-white' : 'bg-cyan-100'
		} rounded-full px-2 py-0.5 font-semibold capitalize`}
		style={{ fontSize: 10 }}
	>
		{category}
	</div>
)

export const IconText = ({ icon, text, size, divClass, iconClass, iconColor, textClass }) => (
	<div className={`flex items-center ${divClass || ''}`}>
		<Icon path={icon} size={size || 1} color={iconColor} className={`mr-2 text-gray-500 ${iconClass || ''}`} />
		<p className={`font-semibold ${textClass || ''}`}>{text}</p>
	</div>
)

export const prepareOrderNumber = (orders) => {
	return orders.reduce(
		(orderNo, order) =>
			(orderNo === '' ? '' : orderNo + ', ') +
			`${['ImportShifting'].includes(order?.preferences?.movementType) ? 'I' : SegmentsEnum[order?.type]?.initial}-${
				order?.subOrderNumber || order?.orderNumber
			}`,
		''
	)
}

export const getLastSubStatus = (journeys) => {
	const driverStatuses = journeys?.map((journey) => journey?.DriverStatusHistories).flat()
	const driverJourneys = driverStatuses?.map((status) => status?.DriverStatusJourneys)?.flat()

	const lastStatus = driverStatuses?.sort((history1, history2) => moment(history2?.timestamp).diff(moment(history1?.timestamp), 'milliseconds'))?.[0]
	const allSubStatuses = driverJourneys?.filter((journey) => ['location', 'subStatus'].includes(journey?.type))
	const lastSubStatus = allSubStatuses?.sort((loc1, loc2) => moment(loc2?.timestamp).diff(moment(loc1?.timestamp), 'milliseconds'))?.[0]

	return {
		status: formatDriverStatus(lastStatus?.status),
		subStatus: lastSubStatus?.type !== 'location' ? formatSubStatus(lastSubStatus?.status) : lastSubStatus?.status,
	}
}

export const formatDriverStatus = (status) => {
	return status
		?.replace('driver', '')
		?.split(/(?=[A-Z])/)
		?.join(' ')
}

export const formatSubStatus = (status) => {
	return status
		?.split(/(?=[A-Z])/)
		?.map((element) => element.charAt(0).toUpperCase() + element.substring(1).toLowerCase())
		?.join(' ')
}

export const formatVehicleNumber = (vehicleNumber) => {
	return vehicleNumber
		.match(/[a-zA-Z]+|[0-9]+/g)
		?.join('-')
		?.toUpperCase()
}

export const getParentOrderStatus = (subOrders) => {
	let status = 'Order Ongoing'
	if (subOrders?.every((subOrder) => [' ', 'Order Processing', 'Order Confirmed'].includes(subOrder?.status))) {
		status = 'Order Pending'
	} else if (subOrders?.every((subOrder) => subOrder?.status === 'Order Completed')) {
		status = 'Order Completed'
	} else if (subOrders?.every((subOrder) => subOrder?.status === 'Order Cancelled')) {
		status = 'Order Cancelled'
	}
	return status
}

export const calculateAlphabet = (columnNumber) => {
	let subOrderAlphabet = []
	while (columnNumber > 0) {
		let remainder = columnNumber % 26
		if (remainder === 0) {
			subOrderAlphabet.push('Z')
			columnNumber = Math.floor(columnNumber / 26) - 1
		} else {
			subOrderAlphabet.push(String.fromCharCode(remainder - 1 + 'A'.charCodeAt(0)))
			columnNumber = Math.floor(columnNumber / 26)
		}
	}
	return subOrderAlphabet.reverse().join('')
}

/* ============================================================================================= */
/* ================================== CREATE ORDER FUNCTIONS =================================== */
/* ============================================================================================= */

export const getBaseOrder = ({ clientEmails, clientCCEmails, isKAM, segment, values, user, corporate, ports, rfqId, rfqData }) => {
	const emptyContainer = [values?.terminal]?.flat()
	const freeDays = values?.terminal?.freeDays ? moment(values?.loadingDT)?.add(values?.terminal?.freeDays, 'days')?.format('x') : null

	const vehicleInfos = ['shifting', 'destuffing'].includes(values?.specialRequests?.orderCategory)
		? values?.vehicleDetails?.map((vehicle) => ({
				quantity: vehicle?.quantity,
				quotedRate: vehicle?.quotedRate.toString(),
				vehicleType: vehicle?.vehicleType,
				quotedRateUnit: vehicle?.quotedRateUnit,
				weightRange: vehicle?.minWeight && vehicle?.maxWeight ? `${vehicle?.minWeight}-${vehicle?.maxWeight}` : null,
		  }))
		: segment !== 'LongHaul' &&
		  prepareVehicleDetails({ containersSpecifics: values?.containersSpecifics, specialRequests: values?.specialRequests, isKAM })

	const containerInfos =
		segment !== 'LongHaul' &&
		prepareContainerInfo({
			containersSpecifics: values?.containersSpecifics,
			specialRequests: values?.specialRequests,
			shipmentInfo: values?.shipmentInfo,
			pricingInfo: values?.pricingInfo,
			isKAM,
		})

	const totalContainers = segment === 'LongHaul' ? 0 : containerInfos?.length
	const totalVehicles = segment === 'LongHaul' ? 0 : vehicleInfos?.reduce((prev, curr) => prev + parseInt(curr?.quantity), 0)
	return {
		...(segment !== 'LongHaul'
			? {
					document: values?.shipmentInfo?.document?.[0]?.url ?? null,
					documentNumber: values?.shipmentInfo?.documentNumber ?? null,
					clearingAgents: values?.clearingAgents.map((agent) => ({ name: agent.contactName, phoneNumber: agent.contactPhone })),
					shippingLine: values?.shipmentInfo?.shippingLine ?? null,
					...(segment === 'Import' && freeDays && { freeDays: freeDays }),
                    ...(segment === 'Import' && values?.clientCommittedReturnDeadline && {
                        committedReturnDeadline: moment(values.clientCommittedReturnDeadline).format('x'),
                    }),
                    ...(segment === 'Import' && values?.eirSubmissionDeadline && {
                        eir: {
                            submissionDeadline: moment(values.eirSubmissionDeadline).format('x'),
                        },
                    }),
					...(segment === 'Export' && {
						vesselCutoffDT: moment(values?.vesselCutoffDT).format('x'),
					}),
                    ...(segment === 'Export' && values?.clientCommittedDeliveryDate && {
                        clientCommittedDeliveryDate: moment(values.clientCommittedDeliveryDate).format('x'),
                    }),
					containerInfos: containerInfos,
					VehicleInfos: vehicleInfos,
			  }
			: {
					VehicleInfos: {
						vehicleType: !values?.specialRequests?.lotOrder ? `Trolly-${values?.vehicleInfo?.vehicleType}` : values?.vehicleInfo?.vehicleType,
						quantity: values?.specialRequests?.numberVehicles?.toString() || null,
						quotedRate: values?.pricingInfo?.vehicleCost?.toString() || null,
						quotedRateUnit: values?.specialRequests?.lotOrder ? 'Tonne' : 'Vehicle',
					},
					vehicleType: values?.vehicleInfo?.vehicleType,
					vehicleSubType: values?.vehicleInfo?.vehicleSubtype,
					...(values?.pricingInfo?.finalizedRate && {
						finalizedRate: values?.pricingInfo?.finalizedRate?.toString(),
						finalizedRateUnit: values?.specialRequests?.lotOrder ? 'Tonne' : 'Vehicle',
					}),
					expectedMovementDeadline: values?.specialRequests?.movementCutoffDT ? moment(values?.specialRequests?.movementCutoffDT).format('x') : null,
					shipmentInfo: {
						freightType: values?.shipmentInfo?.freightType,
						freightWeight: values?.specialRequests?.lotOrder
							? values?.shipmentInfo?.freightWeight
							: (values?.shipmentInfo?.freightWeight * values?.specialRequests?.numberVehicles)?.toString(),
						freightCount: values?.shipmentInfo?.freightCount,
					},
			  }),
		businessCode: corporate.businessCode,
		clientEmails: clientEmails,
		clientCCEmails: clientCCEmails,
		ClientId: user?._id,
		createdThrough: {
			userPlatform: 'Web - Shipper Portal',
		},
		RfqId: rfqId,
		OrderLocations: prepareOrderLocations({ loading: values?.loading, dropoff: values?.dropoff, emptyContainer: emptyContainer, segment, ports }),
		loadingDT: values?.loadingDT ? moment(values?.loadingDT).format('x') : null,
		preferences: preparePreferences({
			isKAM,
			segment,
			totalVehicles,
			totalContainers,
			specialRequests: values?.specialRequests,
		}),

        // bonded order fields
        ...(segment === 'Import' && {
            bonded: rfqData?.bonded,
            ...(rfqData?.bonded && {
                bondedChargesPerBL: rfqData?.bondedChargesPerBL,
                bondedChargesPerContainer: rfqData?.bondedChargesPerContainer,
            }),
        }),
	}
}

const prepareVehicleDetails = (props) => {
	const { containersSpecifics = null, isKAM, specialRequests = null } = props
	const vehicleDetails = []
	Object.keys(containersSpecifics)?.forEach((dimension) => {
		if (containersSpecifics[dimension]?.quantity > 0) {
			const containerQuantity =
				dimension === 'xl20ft' && specialRequests?.doubleTwenty && isKAM
					? containersSpecifics[dimension]?.quantity - containersSpecifics['xl2020ft']?.quantity * 2
					: containersSpecifics[dimension]?.quantity
			const vehicle = {
				vehicleType: `Trolly-${dimension}`,
				quantity: containerQuantity?.toString(),
				quotedRate: containersSpecifics[dimension]?.vehicleCost?.toString(),
				quotedRateUnit: 'Container',
			}
			vehicleDetails?.push(vehicle)
		}
	})
	return vehicleDetails
}

const prepareContainerInfo = (props) => {
	const { containersSpecifics, specialRequests, isKAM, shipmentInfo, pricingInfo } = props
	const shiftingOrder = ['shifting', 'destuffing'].includes(specialRequests?.orderCategory)
	const containerInfo = []
	Object.keys(containersSpecifics)?.forEach((dimension) => {
		const containerQuantity =
			dimension === 'xl2020ft'
				? containersSpecifics[dimension]?.quantity * 2
				: dimension === 'xl20ft' && specialRequests?.doubleTwenty && isKAM
				? containersSpecifics[dimension]?.quantity - containersSpecifics['xl2020ft']?.quantity * 2
				: containersSpecifics[dimension]?.quantity

		for (let i = 0; i < containerQuantity; i++) {
			const container = {
				freightType: shipmentInfo?.freightType,
				quotedDoCost: specialRequests?.doIssuance ? containersSpecifics[dimension === 'xl2020ft' ? 'xl20ft' : dimension]?.doCost : null,
				dimension: dimension === 'xl2020ft' ? 'xl20ft' : dimension,
				finalizedRate: shiftingOrder ? pricingInfo?.finalizedRate?.toString() : containersSpecifics[dimension]?.finalizedRate?.toString(),
				quotedLabourCost: containersSpecifics[dimension]?.labourCost,
				quotedShiftingCost: containersSpecifics[dimension]?.shiftingCost,
				freightWeight: containersSpecifics[dimension === 'xl2020ft' ? 'xl20ft' : dimension]?.freightWeight,
				finalizedRateUnit: shiftingOrder ? pricingInfo?.finalizedRateUnit : 'Container',
			}
			containerInfo.push(container)
		}
	})
	return containerInfo
}

const preparePreferences = (props) => {
	const { specialRequests, segment, totalContainers, isKAM, totalVehicles } = props

	return {
		gstInclusive: specialRequests?.gstInclusive,
		...(segment === 'Import' && { doIssuance: specialRequests?.doIssuance }),
		...(specialRequests?.refNumber && { refNumber: specialRequests?.refNumber }),
		...(specialRequests?.numberVehicles && { numberVehicles: specialRequests?.numberVehicles?.toString() }),
		...(['shifting', 'destuffing'].includes(specialRequests?.orderCategory) && {
			numberFlatbeds:
				specialRequests?.orderCategory === 'shifting'
					? totalContainers
					: specialRequests?.orderCategory === 'destuffing' && isKAM
					? totalVehicles
					: Math.ceil(totalContainers / 2.5),
		}),
		...(['LongHaul'].includes(segment) && { lotOrder: specialRequests?.lotOrder }),
		...(['Import', 'Export'].includes(segment) && {
			fastLane: specialRequests?.request === 'Fast Lane - Express Delivery',
			...(segment === 'Export' && { emailForwarded: specialRequests?.emailForwarded }),
			...((specialRequests?.request === 'Other' || specialRequests?.otherRequests) && { otherRequests: specialRequests?.otherRequests }),
			movementType: ['shifting', 'destuffing'].includes(specialRequests?.orderCategory)
				? 'ImportShifting'
				: specialRequests?.doubleTwenty
				? 'DoubleTwenty'
				: capitalize(segment),
		}),
	}
}

const prepareOrderLocations = (props) => {
	let { loading, dropoff, emptyContainer, segment, ports } = props
	let index = 0
	loading = segment === 'Import' ? [loading]?.map((loading) => ({ ...loading, _id: ports[loading?.additionalDetails]?._id })) : loading
	dropoff = segment === 'Export' ? [dropoff]?.map((dropoff) => ({ ...dropoff, _id: ports[dropoff?.additionalDetails]?._id })) : dropoff

	return [
		...(segment === 'Export'
			? emptyContainer?.map((location) => ({
					LocationId: location?._id,
					locationType: 'Empty Container Pickup',
					contacts: location?.contacts ? location?.contacts?.map((contact) => ({ name: contact.contactName, phoneNumber: contact.contactPhone })) : null,
					steps: ++index,
			  }))
			: []),
		...loading?.map((location) => ({
			LocationId: location?._id,
			locationType: 'loading',
			contacts: location?.contacts?.map((contact) => ({ name: contact.contactName, phoneNumber: contact.contactPhone })),
			steps: ++index,
		})),
		...dropoff?.map((location) => ({
			LocationId: location?._id,
			locationType: 'dropoff',
			contacts: location?.contacts?.map((contact) => ({ name: contact.contactName, phoneNumber: contact.contactPhone })),
			steps: ++index,
		})),
		...(segment === 'Import'
			? [
					{
						LocationId: emptyContainer?.[0]?._id,
						locationType: 'Empty Container Dropoff',
						steps: ++index,
					},
			  ]
			: []),
	]
}

/* ============================================================================================= */
/* ================================== GOOGLE MAPS FUNCTIONS ==================================== */
/* ============================================================================================= */

export function getZoomLevel(bounds) {
	var WORLD_DIM = { height: 256, width: 256 }
	var ZOOM_MAX = 14

	function latRad(lat) {
		var sin = Math.sin((lat * Math.PI) / 180)
		var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
	}

	function zoom(mapPx, worldPx, fraction) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
	}

	var ne = bounds.getNorthEast()
	var sw = bounds.getSouthWest()

	var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

	var lngDiff = ne.lng() - sw.lng()
	var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

	var latZoom = zoom(450, WORLD_DIM.height, latFraction)
	var lngZoom = zoom(450, WORLD_DIM.width, lngFraction)

	return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

/* ============================================================================================= */
/* ================================== SUBCRIPTION FUNCTION ===================================== */
/* ============================================================================================= */

export const updateData = (props) => {
	const { type, prev, subscriptionData, setAtom, subscriptionKey, fetchKey } = props

	const segmentQuery = GraphQLEnums[`${type}`]?.fetch?.[fetchKey ? fetchKey : 'default']?.key
	const segmentSubscription = subscriptionKey
		? GraphQLEnums[`${type}`]?.subscribe?.[subscriptionKey]?.key
		: GraphQLEnums[`${type}`]?.subscribe?.default?.key

	const order = subscriptionData?.data?.[segmentSubscription]
	let existing = {}
	let orders = []
	// console.log('subscriptionData', order)
	Object.assign(existing, prev)
	Object.assign(orders, existing[segmentQuery])

	const index = orders.findIndex((oldOrder) => oldOrder?._id === order?._id)
	if (index >= 0) {
		orders[index] = order
	} else {
		orders.push(order)
	}

	existing[segmentQuery] = orders
	setAtom(orders)
	return existing
}

/* ============================================================================================= */
/* ============================ Slack Integration FUNCTION ===================================== */
/* ============================================================================================= */

export const retryCatch = async ({ delay = 0, maxRetries = 0, executionFunction }) => {
	let count = 0
	while (true) {
		try {
			const token = JSON.parse(localStorage.getItem('token'))?.token
			const response = await executionFunction(token)
			if (response?.status === 200 || response?.status === 201) {
				return Promise.resolve(response)
			} else {
				throw Error(response)
			}
		} catch (error) {
			count += 1
			if (count === maxRetries) {
				return Promise.reject(error)
			} else {
				const token = await firebase.auth().currentUser?.getIdToken()
				localStorage.setItem('token', JSON.stringify({ token }))
				await new Promise((r) => setTimeout(r, delay))
				continue
			}
		}
	}
}

export const sendShipperIntakeSlack = (data) => {
	return retryCatch({
		maxRetries: 1,
		delay: 500,
		executionFunction: (token) =>
			// `https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendShipperIntakeSlack`
			fetch(`http://127.0.0.1:5001/bridgelinx-dev/asia-south1/sendShipperIntakeSlack`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					order: data,
				}),
			}),
	})
}

export const sendOrderConfirmationEmail = (data) => {
	return retryCatch({
		maxRetries: 1,
		delay: 500,
		executionFunction: (token) =>
			// `https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendOrderConfirmationEmail`
			fetch(`http://127.0.0.1:5001/bridgelinx-dev/asia-south1/sendOrderConfirmationEmail`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					order: data,
				}),
			}),
	})
}
