import { RenderSchema } from '../../utils/FormRenderer'
import { OtherRequestsSchema, AddRequestsSchema } from '../../utils/FormSchemas'

const OtherRequestsInfo = (props) => {
	const { segment, orderIndex, isKAM } = props
	return (
		<>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Special Requests</p>
			<div className='mb-12 rounded-lg border bg-white'>
				<div className='px-6 pt-6'>
					{RenderSchema(segment === 'Export' ? AddRequestsSchema({ orderIndex, isKAM }) : OtherRequestsSchema({ orderIndex, isKAM }))}
				</div>
			</div>
		</>
	)
}

export default OtherRequestsInfo
