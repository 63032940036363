// 3RD PARTY LIBRARIES
import { selectorFamily, selector } from 'recoil'
// RECOIL
import * as AllAtoms from './atoms'
// HELPERS
import { GraphQLEnums, SegmentsEnum } from '../utils/Enums'
import { getParentOrderStatus, prepareOrderNumber } from '../utils/Functions'

export const segmentOrdersSelector = selectorFamily({
	key: 'segmentOrdersSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, pageNo, pageSize, keyword } = props
			const atomName = SegmentsEnum[segment]?.atomName
			const orders = AllAtoms[atomName] && get(AllAtoms[atomName])?.filter((order) => order?.ParentOrder)
			let returnOrders = []
			if (orders?.length > 0 && segment === 'Import') {
				returnOrders = getImportOrders({ orders, segment })?.sort((a, b) => b?.orderNumber - a?.orderNumber)
			} else if (orders?.length > 0 && segment === 'Export') {
				returnOrders = getExportOrders(orders)?.sort((a, b) => b?.orderNumber - a?.orderNumber)
			} else if (orders?.length > 0 && segment === 'LongHaul') {
				returnOrders = getLongHaulOrders({ orders, segment })?.sort((a, b) => b?.orderNumber - a?.orderNumber)
			}
			return {
				totalCount: returnOrders?.length,
				collectionData: keyword?.length <= 0 ? returnOrders.slice(pageNo * pageSize - pageSize, pageNo * pageSize) : returnOrders,
			}
		},
})

export const getOrderByIdSelector = selectorFamily({
	key: 'getOrderByIdSelector',
	get:
		(props) =>
		({ get }) => {
			const { id, segment } = props
			return get(segmentOrdersSelector({ segment }))?.collectionData?.find((order) => order?._id === id)
		},
})

export const corporatesSelector = selectorFamily({
	key: 'corporatesSelector',
	get:
		(props) =>
		({ get }) => {
			const mappedCorporates = get(AllAtoms.corporatesAtom)?.map((corporate) => ({
				...corporate,
				businessNameCode: `${corporate.businessName} (${corporate.businessCode})`,
			}))
			return mappedCorporates
		},
})

export const corporateByIdSelector = selectorFamily({
	key: 'corporateByIdSelector',
	get:
		(props) =>
		({ get }) => {
			const { id } = props
			return get(corporatesSelector())?.find((corporate) => corporate?._id === id)
		},
})

export const authSelector = selectorFamily({
	key: 'authSelector',
	get:
		(props) =>
		({ get }) => {
			const user = get(AllAtoms.userAtom)
			const authorized = !!user?._id
			return user ? { user: user?._id ? user : 'no user', authorized } : undefined
		},
})

export const rfqSelector = selectorFamily({
	key: 'rfqSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment, rfqId } = props
			let rfqData = get(AllAtoms.rfqAtom)
			const orignalRfqData = get(AllAtoms.rfqAtom)
			if (segment !== 'LongHaul' && rfqId) {
				rfqData = { ...rfqData, shipmentInfo: rfqData?.shipmentInfo?.filter((shipment) => !['processed', 'lost']?.includes(shipment?.shipmentStatus)) }
			}
			return { rfqData, orignalRfqData }
		},
})

/* ============================================================================================= */
/* ================================ SELECTORS UTILITY FUNCTIONS ================================ */
/* ============================================================================================= */
const getImportOrders = ({ orders, segment }) => {
	const parentOrders = orders?.map((order) => {
		// order
		const parentOrder = order?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]
		const movements = order?.ParentOrder?.Movements
		const subImport = movements?.[0]?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]
		const subOrders = Object.values(
			movements?.reduce((subOrders, movement) => {
				subOrders[movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]?._id] =
					movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]
				return subOrders
			}, {})
		)
		let jobs = {}

		// parent order info
		const type = order?.type
		const orderNumber = parentOrder?.orderNumber
		const loadingDT = parentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childrenType]?.[0]?.loadingDT
		const preferences = parentOrder?.preferences
		const preparedOrderNumber = `${SegmentsEnum?.[segment]?.initial}-${orderNumber}`
		const orderStatus = parentOrder?.status
		const refNumber = parentOrder?.preferences?.refNumber
		const clearingAgents = parentOrder?.clearingAgents?.map((agent) => ({ contactName: agent.name, contactPhone: agent.phoneNumber }))

		// order locations
		const orderLocations = subImport?.OrderLocations?.slice(0)
			?.sort((a, b) => a?.steps - b?.steps)
			?.map((location) => ({
				...location,
				formattedAddress: location?.Location?.formattedAddress || 'None',
				additionalDetails: location?.Location?.additionalDetails || 'None',
			}))
		const terminal = orderLocations?.filter((location) => location?.locationType === 'Empty Container Dropoff')
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')

		// order category
		const isSDOrder = parentOrder?.preferences?.movementType === 'ImportShifting'
		const orderCategory = isSDOrder
			? parentOrder?.preferences?.numberFlatbeds === subOrders.length
				? 'Shifting'
				: 'Destuffing'
			: parentOrder?.preferences?.movementType
		// TODO: Optimize
		movements?.forEach((movement) => {
			const relevantSubOrder = prepareImportExportSubOrder(
				movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType],
				parentOrder,
				order,
				orderCategory
			)
			const latestDriverCoordinates = movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
				?.flat()
				?.map((statusHistory) => statusHistory?.DriverStatusJourneys)
				?.flat()
				?.map((statusJourney) => statusJourney)
				?.flat()
				?.filter((statusJourney) => statusJourney.type === 'location')
				?.sort((statusJourney1, statusJourney2) => statusJourney2?.createdAt - statusJourney1?.createdAt)?.[0]?.coordinates
			if (movement?.subMovementType !== 'ImportShortHaul') {
				jobs[`${movement?.Job?._id}`] = {
					job: {
						...movement?.Job,
						matched: movement?.status,
						coordinates: latestDriverCoordinates,
					},
					...(relevantSubOrder && { subOrders: { ...jobs[`${movement?.Job?._id}`]?.subOrders, [relevantSubOrder?._id]: relevantSubOrder } }),
				}
			}
		})

		const totalChildOrders = subOrders?.length
		const containerNumber = subOrders?.map((subOrder) => subOrder?.containerNumber)
		const containerNumbers = subOrders?.length
		const shippingLine = parentOrder?.shippingLine
		const docNumber = parentOrder?.documentNumber
		const freeDays = subOrders?.[0]?.freeDays
		const freightType = subOrders?.[0]?.freightType

		const relevantJobs = Object.values(jobs)?.filter((job) => job?.job?.matched !== 'NonMatched')
		const driverName = relevantJobs?.map((job) => job?.job?.Driver?.User?.firstName + job?.job?.Driver?.User?.lastName)
		const driverPhone = relevantJobs?.map((job) => job?.job?.Driver?.User?.phoneNumber)
		const driverVehicleRegistration = relevantJobs?.map((job) => job?.job?.Vehicle?.registrationNumber)
		const biltyNumber = relevantJobs?.map((job) => job?.job?.bilty?.[0])?.biltyNumber

		const eirDocuments = subOrders?.map((subOrder) => subOrder?.eirDocument?.map((doc) => ({ document: doc, text: 'EIR Document' })))?.flat() || []
		const finalizedRate = subOrders?.reduce((prev, curr) => prev + parseFloat(curr?.finalizedRate), 0)
		const biltyDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.biltyDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Bilty Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].biltyDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			?.flat()
		const kantaDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.kantaDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Kanta Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].kantaDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			.flat()
		const blDocument = [{ document: parentOrder?.document, text: 'BL Document' }]?.filter((document) => document?.document?.length > 0)
		const documents = [...blDocument, ...eirDocuments, ...biltyDocuments, ...kantaDocuments]?.filter((document) => document?.document)

		return {
			_id: order?._id,
			createdAt: order?.createdAt,
			subOrders,
			freeDays,
			clearingAgents,
			containerNumber,
			containerNumbers,
			docNumber,
			driverName,
			driverPhone,
			totalChildOrders,
			finalizedRate,
			freightType,
			driverVehicleRegistration,
			biltyNumber,
			documents,
			terminal,
			dropoff,
			isSDOrder,
			loading,
			loadingDT,
			orderCategory,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			shippingLine,
			preferences,
			jobs,
			type,
		}
	})
	return parentOrders
}

const getExportOrders = (orders) => {
	const parentOrders = orders?.map((order) => {
		const parentOrder = order?.ParentOrder?.ParentExport
		const movements = order?.ParentOrder?.Movements
		const subOrder = movements?.[0]?.ChildOrder?.SubExport
		const subOrders = Object.values(
			movements?.reduce((subOrders, movement) => {
				subOrders[movement?.ChildOrder?.SubExport?._id] = movement?.ChildOrder?.SubExport
				return subOrders
			}, {})
		)
		const type = order?.type
		const orderNumber = parentOrder?.orderNumber
		const orderStatus = getParentOrderStatus(subOrders)
		const refNumber = parentOrder?.preferences?.refNumber
		const clearingAgents = parentOrder?.clearingAgents.map((agent) => ({ contactName: agent.name, contactPhone: agent.phoneNumber }))
		let jobs = {}
		const loadingDT = parentOrder?.SubExports?.[0]?.loadingDT
		const preferences = parentOrder?.preferences
		const preparedOrderNumber = `E-${orderNumber}`
		const orderLocations = subOrder?.OrderLocations?.slice(0)
			?.sort((a, b) => a?.steps - b?.steps)
			?.map((location) => ({
				...location,
				formattedAddress: location?.Location?.formattedAddress || 'None',
				additionalDetails: location?.Location?.additionalDetails || 'None',
			}))
		const terminal = orderLocations?.filter((location) => location?.locationType === 'Empty Container Pickup')
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')

		movements?.forEach((movement) => {
			const relevantSubOrder = prepareImportExportSubOrder(movement?.ChildOrder?.SubExport, parentOrder, order, 'Export')
			jobs[`${movement?.Job?._id}`] = {
				job: {
					...movement?.Job,
					matched: movement?.status,
					latestDriverCoordinates: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
						?.flat()
						?.filter((statusHistory) => !statusHistory?.deletedAt)
						?.map((statusHistory) => ({
							...statusHistory,
							DriverStatusJourneys: statusHistory?.DriverStatusJourneys?.filter(
								(statusJounrey) => !(statusJounrey?.deletedAt && statusJounrey?.type === 'location')
							),
						})),
				},
				...(relevantSubOrder && { subOrders: { ...jobs[`${movement?.Job?._id}`]?.subOrders, [relevantSubOrder?._id]: relevantSubOrder } }),
			}
		})

		const relevantJobs = Object.values(jobs)?.filter((job) => job?.job?.matched !== 'NonMatched')
		const driverName = relevantJobs?.map((job) => job?.job?.Driver?.User?.firstName + job?.job?.Driver?.User?.lastName)
		const driverPhone = relevantJobs?.map((job) => job?.job?.Driver?.User?.phoneNumber)
		const driverVehicleRegistration = relevantJobs?.map((job) => job?.job?.Vehicle?.registrationNumber)
		const biltyNumber = relevantJobs?.map((job) => job?.job?.bilty?.[0])?.biltyNumber

		const containerNumbers = subOrders?.length
		const totalChildOrders = subOrders?.length
		const containerNumber = subOrders?.map((subOrder) => subOrder?.containerNumber)
		const finalizedRate = subOrders?.reduce((prev, curr) => prev + parseFloat(curr?.finalizedRate), 0)
		const freightType = subOrders?.[0]?.freightType

		const shippingLine = parentOrder?.shippingLine
		const docNumber = parentOrder?.documentNumber
		const biltyDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.biltyDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Bilty Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].biltyDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			?.flat()
		const kantaDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.kantaDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Kanta Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].kantaDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			.flat()
		const croDocument = [{ document: parentOrder?.document, text: 'CRO Document' }]?.filter((document) => document?.document?.length > 0)
		const documents = [...croDocument, ...biltyDocuments, ...kantaDocuments]?.filter((document) => document?.document)

		return {
			_id: parentOrder?._id,
			createdAt: order?.createdAt,
			subOrders,
			clearingAgents,
			containerNumbers,
			containerNumber,
			docNumber,
			documents,
			driverName,
			driverPhone,
			totalChildOrders,
			finalizedRate,
			freightType,
			driverVehicleRegistration,
			biltyNumber,
			terminal,
			dropoff,
			loading,
			loadingDT,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			shippingLine,
			preferences,
			jobs,
			type,
		}
	})
	return parentOrders
}

const getLongHaulOrders = ({ orders, segment }) => {
	const parentOrders = orders?.map((order) => {
		const parentOrder = order?.ParentOrder?.ParentLongHaul
		const movements = order?.ParentOrder?.Movements
		const subOrder = parentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childrenType]?.[0]
		const subOrders = Object.values(
			movements?.reduce((subOrders, movement) => {
				subOrders[movement?.ChildOrder?.SubLongHaul?._id] = movement?.ChildOrder?.SubLongHaul
				return subOrders
			}, {})
		)
		let jobs = {}
		const type = order?.type
		const preferences = parentOrder?.preferences
		const orderNumber = parentOrder?.orderNumber
		const refNumber = preferences?.refNumber
		const isLotOrder = preferences?.lotOrder
		const loadingDT = subOrder?.loadingDT
		const preparedOrderNumber = `L-${orderNumber}`
		const orderStatus = getParentOrderStatus(subOrders)
		const finalizedRate = subOrders?.reduce((prev, curr) => prev + parseFloat(curr?.finalizedRate), 0)
		const freightType = parentOrder?.shipmentInfo?.freightType

		const orderLocations = subOrder?.OrderLocations?.slice(0)
			?.sort((a, b) => a?.steps - b?.steps)
			?.map((location) => ({
				...location,
				formattedAddress: location?.Location?.formattedAddress || 'None',
				additionalDetails: location?.Location?.additionalDetails || 'None',
			}))
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')

		movements?.forEach((movement) => {
			const relevantSubOrder = prepareLongHaulSubOrder(movement?.ChildOrder?.SubLongHaul, parentOrder, order)
			jobs[`${movement?.Job?._id}`] = {
				job: { ...movement?.Job, matched: movement?.status },
				...(relevantSubOrder && { subOrders: { ...jobs[`${movement?.Job?._id}`]?.subOrders, [relevantSubOrder?._id]: relevantSubOrder } }),
			}
		})
		const relevantJobs = Object.values(jobs)?.filter((job) => job?.job?.matched !== 'NonMatched')
		const driverName = relevantJobs?.map((job) => job?.job?.Driver?.User?.firstName + job?.job?.Driver?.User?.lastName)
		const driverPhone = relevantJobs?.map((job) => job?.job?.Driver?.User?.phoneNumber)
		const driverVehicleRegistration = relevantJobs?.map((job) => job?.job?.Vehicle?.registrationNumber)
		const biltyNumber = relevantJobs?.map((job) => job?.job?.bilty?.[0])?.biltyNumber

		const totalChildOrders = subOrders?.length
		const biltyDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.biltyDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Bilty Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].biltyDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			?.flat()
		const kantaDocuments = relevantJobs
			?.map((job) =>
				job.job?.bilty?.[0]?.kantaDocument
					?.map?.((doc, index) => ({
						document: doc,
						text: `Kanta Document${Object?.values(job.subOrders)?.reduce((prev, curr) => `${prev}-${curr?.subOrderNumber}`, '')}${
							job.job?.bilty?.[0].kantaDocument?.length > 1 ? ' (' + (index + 1) + ')' : ''
						}`,
					}))
					?.flat()
			)
			.flat()
		const documents = [...biltyDocuments, ...kantaDocuments]?.filter((document) => document?.document)

		return {
			_id: parentOrder?._id,
			createdAt: order?.createdAt,
			subOrders,
			documents,
			dropoff,
			isLotOrder,
			loading,
			driverName,
			driverPhone,
			biltyNumber,
			totalChildOrders,
			finalizedRate,
			freightType,
			driverVehicleRegistration,
			loadingDT,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			preferences,
			jobs,
			type,
		}
	})
	return parentOrders
}

const prepareImportExportSubOrder = (subOrder, parentOrder, order, orderCategory) => ({
	_id: subOrder?._id,
	freeDays: subOrder?.freeDays,
	orderNumber: parentOrder?.orderNumber,
	subOrderNumber: subOrder?.subOrderNumber,
	type: order?.type,
	orderCategory: orderCategory,
	eirDocument: subOrder?.eir?.eirDocument,
	containerInfo: {
		finalizedRate: subOrder?.finalizedRate,
		containerNumber: subOrder?.containerNumber,
		dimension: subOrder?.dimension,
		freightType: subOrder?.freightType,
		freightWeight: subOrder?.freightWeight,
	},
	vehicleInfo: { vehicleType: subOrder?.vehicleType, vehicleSubtype: subOrder?.vehicleSubType },
	preferences: parentOrder?.preferences,
	orderStatus: { status: subOrder?.status },
})

const prepareLongHaulSubOrder = (subOrder, parentOrder, order) => ({
	_id: subOrder?._id,
	orderNumber: parentOrder?.orderNumber,
	subOrderNumber: subOrder?.subOrderNumber,
	type: order?.type,
	shipmentInfo: subOrder?.shipmentInfo,
	vehicleInfo: { vehicleType: subOrder?.vehicleType, vehicleSubtype: subOrder?.vehicleSubType },
	preferences: parentOrder?.preferences,
	orderStatus: { status: subOrder?.status },
})

/* ============================================================================================= */
/* ==================================== DASHBOARD FUNCTIONS ==================================== */
/* ============================================================================================= */
export const dashboardSelector = selectorFamily({
	key: 'dashboardSelector',
	get:
		(props) =>
		({ get }) => {
			const { segment } = props
			const atomName = SegmentsEnum[segment]?.atomName
			const orders = AllAtoms[atomName] && get(AllAtoms[atomName])?.filter((order) => order?.ParentOrder)
			if (orders?.length > 0) {
				return [...getTrackingOrders(orders, segment)]?.sort((a, b) => b?.orderNumber - a?.orderNumber)
			}
		},
})

const getTrackingOrders = (orders, segment) => {
	return orders?.map((order) => {
		const jobs = {}
		const childOrders = {}
		const parentOrder = order?.ParentOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.parentType]
		const type = order?.type
		const orderNumber = parentOrder?.orderNumber
		const preferences = parentOrder?.preferences
		const preparedOrderNumber = prepareOrderNumber([{ orderNumber: parentOrder?.orderNumber, type: order?.type, preferences: parentOrder?.preferences }])

		const movements = order?.ParentOrder?.Movements?.filter(
			(movement) =>
				movement?.subMovementType !== 'ImportShortHaul' &&
				movement?.subMovementType !== 'ExportShortHaul' &&
				movement?.subMovementType !== 'ImportDoubleTwentyShortHaul' &&
				movement?.subMovementType !== 'ExportDoubleTwentyShortHaul'
		)

		movements?.forEach((movement) => {
			childOrders[movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]?._id] =
				movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]
			jobs[movement?.ChildOrder?.[GraphQLEnums?.Orders?.types?.[segment]?.childType]?._id] = movement?.Job && {
				...movement?.Job,
				driverStatusHistory: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
					?.flat()
					?.filter((statusHistory) => !statusHistory?.deletedAt)
					?.map((statusHistory) => ({
						...statusHistory,
						DriverStatusJourneys: statusHistory?.DriverStatusJourneys?.filter((statusJounrey) => !statusJounrey?.deletedAt),
					})),
				coordinates: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
					?.flat()
					?.map((statusHistory) => statusHistory?.DriverStatusJourneys)
					?.flat()
					?.map((statusJourney) => statusJourney)
					?.flat()
					?.filter((statusJourney) => statusJourney.type === 'location')
					?.sort((statusJourney1, statusJourney2) => statusJourney2?.createdAt - statusJourney1?.createdAt)?.[0]?.coordinates,
			}
		})

		const subOrders = Object?.values(childOrders)?.map((subOrder) => {
			return {
				_id: subOrder?._id,
				type: order?.type,
				orderNumber: orderNumber,
				orderStatus: subOrder?.status,
				preferences: parentOrder?.preferences,
				subOrderNumber: subOrder?.subOrderNumber,
				journeys: jobs?.[subOrder?._id]?.Journeys,
				preparedSubOrderNumber: prepareOrderNumber([{ subOrderNumber: subOrder?.subOrderNumber, orderNumber: orderNumber, type, preferences }]),
				containerInfo: {
					containerNumber: subOrder?.containerNumber,
					dimension: subOrder?.dimension,
					freightType: subOrder?.freightType,
					freightWeight: subOrder?.freightWeight,
				},
				vehicleInfo: { vehicleType: subOrder?.vehicleType, vehicleSubtype: subOrder?.vehicleSubType },
				driver: {
					driverVehicleRegistration: jobs?.[subOrder?._id]?.Vehicle?.registrationNumber,
					driverPhone: jobs?.[subOrder?._id]?.Driver?.phoneNumber,
				},
				coordinates: jobs?.[subOrder?._id]?.coordinates,
				driverStatusHistory: jobs?.[subOrder?._id]?.driverStatusHistory,
			}
		})

		return {
			_id: parentOrder?._id,
			docNumber: parentOrder?.documentNumber,
			loadingDT: childOrders?.[0]?.loadingDT,
			preparedOrderNumber,
			refNumber: preferences?.refNumber,
			shippingLine: parentOrder?.shippingLine,
			type: ['ImportShifting'].includes(preferences?.movementType) ? 'Import' : type,
			subOrders,
		}
	})
}

export const selectedTrackingOrderSelector = selectorFamily({
	key: 'selectedTrackingOrderSelector',
	get:
		(props) =>
		({ get }) => {
			const { type, orderNumber } = props
			const segmentOrders = get(dashboardSelector({ segment: type }))
			const selectedRecord = segmentOrders?.find((order) => order?.orderNumber === orderNumber)
			return selectedRecord
		},
})

/* ============================================================================================== */
/* =========================================== Lanes ============================================ */
/* ============================================================================================== */

export const lanesSelector = selectorFamily({
	key: 'lanesSelector',
	get:
		(props) =>
		({ get }) => {
			const lanes = get(AllAtoms.lanesAtom)?.map((lane) => ({ ...lane, subLaneName: `${lane.from?.name} --> ${lane.to?.name}` }))
			return lanes
		},
})

/* ============================================================================================== */
/* ============================= Empty Container Terminals ====================================== */
/* ============================================================================================== */

export const emptyContainerSelector = selector({
	key: 'emptyContainerSelector',
	get: ({ get }) => {
		const mappedTerminals = [...get(AllAtoms.terminalsAtom)]?.sort((a, b) => {
			return a?.additionalDetails?.localeCompare(b?.additionalDetails)
		})
		return mappedTerminals
	},
})

export const portSelector = selector({
	key: 'portSelector',
	get: ({ get }) => {
		const allPorts = get(AllAtoms.portsAtom)
		const mappedPorts = {}
		allPorts?.forEach((portAddress) => {
			mappedPorts[portAddress?.additionalDetails] = { ...portAddress }
		})
		return mappedPorts
	},
})
