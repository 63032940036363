import { RenderSchema } from '../../utils/FormRenderer'
import { LotRateCalculationSchema } from '../../utils/FormSchemas'

const LotRateCalculationCard = (props) => {
	return (
		<>
			<div className='mb-3 text-base font-medium leading-5 text-gray-500'>Rate Calculation</div>
			<div className='mb-12 rounded-lg border bg-white'>
				<div className='m-6 flex flex-col justify-items-center'>{RenderSchema(LotRateCalculationSchema(props))}</div>
			</div>
		</>
	)
}

export default LotRateCalculationCard
