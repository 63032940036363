import React, { useEffect, useMemo, useState } from 'react'
import { Layout } from 'antd'
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'
import { getZoomLevel, prepareOrderNumber } from '../../utils/Functions'
import SelectedVehicleMarker from '../utility/SelectedVehicleMarker'

const TrackingMap = (props) => {
	const { records, selectedVehicle, setSelectedVehicle, selectedSegment } = props || {}
	const libraries = useMemo(() => ['geometry', 'drawing', 'places', 'visualization'], [])
	const { isLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_API_KEY, libraries })
	let bounds = useMemo(() => isLoaded && new window.google.maps.LatLngBounds(), [isLoaded])
	const [boundsCenter, setBoundsCenter] = useState({ lat: 30.3753, lng: 69.3451, zoomlevel: 5 })

	const [locations, setLocations] = useState([])
	const openInfoBox = (record) => setSelectedVehicle(record)

	useEffect(() => {
		const recordLocations =
			records &&
			Object.values(records)
				?.filter((record) => record?.job?.coordinates)
				?.map((record) => {
					return {
						...record,
						_id: record?.job?._id,
						coordinates: record?.job?.coordinates,
						journeys: record?.job?.Journeys,
						preparedSubOrderNumber: prepareOrderNumber(Object?.values(record?.subOrders)),
						type: Object?.values(record?.subOrders)?.[0]?.type,
						driver: {
							driverPhone: record?.job?.Driver?.phoneNumber,
							driverVehicleRegistration: record?.job?.Vehicle?.registrationNumber,
						},
					}
				})
		if (isLoaded && recordLocations?.length > 0) {
			setLocations(recordLocations)
			bounds = new window.google.maps.LatLngBounds() // eslint-disable-line
			recordLocations?.forEach((record) => bounds.extend(record?.coordinates)) // eslint-disable-line
			setBoundsCenter({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: getZoomLevel(bounds) })
		} else {
			setBoundsCenter({ lat: 30.3753, lng: 69.3451, zoomlevel: 5 })
		}
	}, [isLoaded, records])

	return (
		<Layout.Content className='relative bg-gray-500'>
			{isLoaded && (
				<GoogleMap
					mapContainerClassName='relative h-full w-full'
					bounds={bounds}
					zoom={boundsCenter && boundsCenter.zoomlevel}
					center={boundsCenter && { lat: boundsCenter.lat, lng: boundsCenter.lng }}
					options={{ disableDefaultUI: true }}
					onMouseMove={(e) => selectedVehicle && setBoundsCenter({ lat: 30.3753, lng: 69.3451, zoomlevel: 5 })}
					onClick={() => {
						setSelectedVehicle(null)
					}}
				>
					{locations?.map((record) => {
						return (
							<Marker
								key={`Marker_${record?._id}`}
								position={record?.coordinates}
								onMouseOver={(e) => {
									openInfoBox(record)
									setBoundsCenter({ lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng()), zoomlevel: 7 })
								}}
								icon={{
									url:
										'https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2Fblip.png?alt=media&token=00b468a4-de84-43c3-bbc6-751505288582',
								}}
							/>
						)
					})}
					{selectedVehicle && (
						<SelectedVehicleMarker selectedSegment={selectedSegment} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />
					)}
				</GoogleMap>
			)}
		</Layout.Content>
	)
}

export default TrackingMap
