import React from 'react'
import { Layout, Tabs } from 'antd'
import Icon from '@mdi/react'

import { SegmentsEnum } from '../../utils/Enums'

import { mdiTextBoxOutline, mdiListBoxOutline, mdiSwapVerticalVariant } from '@mdi/js'
import { useNavigate, useLocation } from 'react-router-dom'

const DynamicTabs = (props) => {
	const { type, record, segment } = props
	const { pathname } = useLocation()
	const navigator = useNavigate()

	const tabsEnum = {
		segments: SegmentsEnum,
		orderDetails: {
			details: {
				key: 'details',
				text: 'Sub Order Details',
				long: 'Sub Order Details',
				icon: mdiListBoxOutline,
			},
			route: {
				key: 'route',
				text: 'Route',
				long: 'Route',
				icon: mdiSwapVerticalVariant,
			},
			...(record?.documents?.length > 0 && {
				documents: {
					key: 'documents',
					text: 'Documents',
					long: 'Documents',
					icon: mdiTextBoxOutline,
				},
			}),
		},
	}

	const TabHeader = (tabEntry) => {
		return (
			<div className='m-0 flex items-center px-5 text-primary-800'>
				<Icon size={1} className='mr-3' path={tabEntry?.icon || ''} />
				<div className='font-semibold'>{tabEntry?.long}</div>
				{tabEntry?.key === 'details' && record?.totalChildOrders !== 0 && (
					<div className='py-[0.5px] ml-3 flex items-center justify-center rounded-xl bg-gray-100 px-2 text-sm font-semibold'>
						{record?.totalChildOrders < 10 ? `0${record?.totalChildOrders}` : record?.totalChildOrders}
					</div>
				)}
			</div>
		)
	}
	return (
		<Layout.Header className='flex !h-12 items-center justify-between p-0'>
			<Tabs
				className='m-0 h-full w-full'
				activeKey={segment}
				onChange={(selectedSegment) => {
					navigator(`${pathname.split('/').slice(0, -1).join('/')}/${selectedSegment}`)
				}}
			>
				{tabsEnum[type] && Object.values(tabsEnum[type])?.map((entry) => <Tabs.TabPane tab={TabHeader(entry)} key={entry.key} />)}
			</Tabs>
		</Layout.Header>
	)
}

export default DynamicTabs
