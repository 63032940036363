/* ============================================================================================== */
/* ====================================== GRAPHQL mutation ====================================== */
/* ============================================================================================== */

export const mutateGraphQL = async (props) => {
	const { mutationData, mutationData2 = null, mutateFunction, mutationInputFunction, mutationInputFunction2 = null, mutationOutputFunction } = props
	try {
		const apolloData = await mutateFunction({
			variables: {
				[mutationInputFunction]: mutationData,
				...(mutationInputFunction2 && { [mutationInputFunction2]: mutationData2 }),
			},
		})
		return apolloData?.data?.[mutationOutputFunction]
	} catch (e) {
		return console.error()
	}
}
