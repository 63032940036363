import React from 'react'
import Loader from '../../assets/icons/Loader.gif'
import { Layout } from 'antd'

const Loading = (props) => {
	const { headline, message } = props

	return (
		<Layout className='h-full w-full items-center justify-center rounded'>
			<div className='flex h-full w-2/5 flex-col items-center justify-center rounded-lg bg-white p-12'>
				<img src={Loader} className='mb-6 w-24' alt='loading...' />
				<p className='mb-4 text-xl font-semibold'>{headline}</p>
				<p className='text-center text-base font-medium text-gray-400'>{message}</p>
			</div>
		</Layout>
	)
}

export default Loading
