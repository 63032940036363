/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Input, Select } from 'antd'
import { SegmentsEnum, SearchoptionsEnum } from '../../utils/Enums'
import { SearchOutlined } from '@ant-design/icons'

const Searchbar = (props) => {
	const { data, setSearchData, filterProperty, collection, placeholder, keyword, setKeyword, setFilterProperty, segment } = props

	const [searchOptions, setSearchOptions] = useState([])

	useEffect(() => {
		const newSearchOptions = SegmentsEnum[segment]?.filterProperties?.map((property) => ({ key: property, value: SearchoptionsEnum[property].value }))
		setSearchOptions(newSearchOptions)
		setFilterProperty && setFilterProperty(newSearchOptions?.[0]?.key)
	}, [segment])

	useEffect(() => {
		setKeyword && setKeyword('')
	}, [collection])

	useEffect(() => {
		setSearchData && setSearchData({ data: searchFields(), collection: collection })
	}, [data, keyword])

	const searchFields = () => {
		if (keyword?.length > 0) {
			if (typeof filterProperty === 'object') {
				const AllPropertiesData = filterProperty.map((prop) => {
					if (typeof prop === 'string') {
						return data.filter((singleObj) => {
							if (singleObj[prop]) {
								return (singleObj[prop] + '').toLowerCase().includes(keyword.toLowerCase())
							}
						})
					} else if (typeof prop === 'object') {
						return data.filter((singleObj) => filterObject(singleObj, prop, 0))
					}
				})
				let FilteredData = []
				for (let i = 0; i < AllPropertiesData.length; i++) {
					FilteredData = FilteredData.concat(AllPropertiesData[i])
				}
				const UniqueFilteredData = [...new Set(FilteredData)]
				return UniqueFilteredData
			} else {
				const FilteredData = data.filter((singleObj) => {
					if (singleObj[filterProperty]) {
						return (singleObj[filterProperty] + '').toLowerCase().includes(keyword.toLowerCase())
					}
				})
				return FilteredData
			}
		} else {
			return data
		}
	}

	const filterObject = (singleObj, prop, index) => {
		let flag = false
		if (singleObj[prop[index]] !== null && singleObj[prop[index]] !== undefined) {
			if (typeof singleObj[prop[index]] === 'object') {
				flag = filterObject(singleObj[prop[index]], prop, index + 1)
			} else if (typeof singleObj[prop[index]] === 'string' || typeof singleObj[prop[index]] === 'number') {
				return (singleObj[prop[index]] + '').toLowerCase().includes(keyword.toLowerCase())
			}
		}
		return flag
	}

	const SearchBarAddOn = () => (
		<Select
			className='flex h-[2rem] w-[11rem] flex-col items-center justify-center rounded'
			defaultValue={searchOptions?.[0]?.key}
			onChange={(value) => setFilterProperty(value)}
			value={filterProperty}
		>
			{searchOptions?.map((option) => (
				<Select.Option key={option.key} value={option.key}>
					{option.value}
				</Select.Option>
			))}
		</Select>
	)

	return (
		<Input
			className={'w-1/2 rounded border-0 bg-gray-100 align-middle'}
			prefix={<SearchOutlined className='mr-3 font-bold' />}
			value={keyword}
			placeholder={placeholder || 'Search'}
			addonBefore={<SearchBarAddOn />}
			onChange={(e) => setKeyword(e.target.value)}
		/>
	)
}

export default Searchbar
