import React from 'react'
import Icon from '@mdi/react'
import { useRecoilValue } from 'recoil'
import { useAuth } from '../../hooks/auth'
import BridgelinxIcon from '../../logo.png'
import { userAtom } from '../../recoil/atoms'
import { Layout, Dropdown, Menu, Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { mdiAccountCircleOutline, mdiAccountPlusOutline, mdiChevronDown, mdiDomain, mdiPencil } from '@mdi/js'

const Header = (props) => {
	const { selectedTab, activeCorporate } = props
	const { userType, segment, activeCorporateId } = useParams()

	const navigator = useNavigate()

	const { logout } = useAuth()
	const user = useRecoilValue(userAtom)

	const DropdownMenu = () => (
		<Menu className='m-0 w-60 rounded-lg p-0'>
			<Menu.Item
				key='1'
				id='CorporateInfo'
				className='h-48 p-0'
				onClick={() => navigator(`/${selectedTab}/${userType}/${activeCorporateId}/${segment}/changeCorporate`)}
			>
				<div className='rounded-full bg-gray-100 p-2'>
					<Icon path={mdiDomain} size={0.8} className='text-gray-400' />
				</div>

				<p className='mt-4 text-xs font-medium text-gray-500'>Active Business</p>
				<p className='px-6 text-center text-base font-bold'>{activeCorporate?.businessName}</p>
				<div className='mt-6 flex items-center'>
					<Icon path={mdiPencil} size={0.8} className='mr-1 text-primary-800' />
					<p className='text-base font-bold text-primary-800'>Edit</p>
				</div>
			</Menu.Item>
			<Menu.Item key='2' danger onClick={logout} className='h-11 rounded-b-lg bg-red-50 px-4'>
				<p className='font-bold'>Log Out</p>
			</Menu.Item>
		</Menu>
	)

	return (
		<Layout.Header className='flex !h-16 items-center justify-between border-b px-8 py-4'>
			<div className='flex h-full items-center'>
				<img
					className='mr-6 h-8 cursor-pointer'
					src={BridgelinxIcon}
					alt='Bridgelinx Logo'
					onClick={() => navigator(`/dashboard/${userType}/${activeCorporateId}/${segment}`)}
				/>
				<Button
					className={selectedTab === 'dashboard' ? 'btn-header-active' : 'btn-header'}
					onClick={() => navigator(`/dashboard/${userType}/${activeCorporateId}/${segment}`)}
				>
					Dashboard
				</Button>
				<Button
					className={selectedTab === 'shipments' ? 'btn-header-active' : 'btn-header'}
					onClick={() => navigator(`/shipments/${userType}/${activeCorporateId}/${segment}`)}
				>
					Your Shipments
				</Button>
			</div>
			<div className='flex h-full items-center'>
				<div
					className='mr-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-primary-50'
					onClick={() => navigator(`/${selectedTab}/${userType}/${activeCorporateId}/${segment}/inviteMembers`)}
				>
					<Icon title='Invite Members' path={mdiAccountPlusOutline} size={0.8} className='text-primary-800' />
				</div>
				<Dropdown overlay={DropdownMenu()} trigger={['click']} className='cursor-pointer'>
					<div title='Profile' className='flex h-full w-max items-center rounded-full bg-primary-50 px-3 py-0'>
						<Icon path={mdiAccountCircleOutline} size={1} className='text-primary-800' />
						<p className='px-3 font-bold text-primary-800'>{`${user?.User?.firstName} ${user?.User?.lastName}`}</p>
						<Icon path={mdiChevronDown} size={1} className='text-primary-800' />
					</div>
				</Dropdown>
			</div>
		</Layout.Header>
	)
}

export default Header
