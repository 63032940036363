import { Layout } from 'antd'
import Icon from '@mdi/react'
import { mdiRestart, mdiAlertCircle } from '@mdi/js'

const { Content } = Layout

const TableErrorBoundary = ({ error, resetErrorBoundary }) => {
	console.log('*************************************************************************************')
	console.log(error?.networkError?.result?.errors?.[0]?.extensions?.code)
	console.log(error?.networkError?.result?.errors?.[0]?.extensions?.exception?.stacktrace?.join('\n'))
	console.log('*************************************************************************************')

	return (
		<Content className='w-full items-center rounded-lg bg-white'>
			<div className='flex flex-col content-center justify-center pt-[100px]'>
				<Icon path={mdiAlertCircle} size={7} color='#dd2222' className='mx-auto' />
				<div className='my-6 mx-auto w-[300px] flex-wrap text-center text-xl font-bold'>{error?.networkError?.result?.errors?.[0]?.extensions?.code}</div>
				<div className='my-6 mx-auto w-[300px] flex-wrap text-center text-xl font-bold'>
					{error?.networkError?.result?.errors?.[0]?.message || error?.message}
				</div>
				<div
					className='btn-primary my-2 mx-auto flex h-[60px] w-[150px] cursor-pointer flex-row items-center justify-center pl-[10] text-lg'
					onClick={() => resetErrorBoundary()}
				>
					<Icon className=' mr-2' path={mdiRestart} color='#ffffff' size={2} />
					<div className='text-xl'>Retry</div>
				</div>
			</div>
		</Content>
	)
}

export default TableErrorBoundary
