// 3RD PARTY LIBRARIES
import Icon from '@mdi/react'
import { mdiMapMarker, mdiRayStartArrow } from '@mdi/js'
// COMPONENTS
import OrderStatusPill from '../components/utility/OrderStatusPill'
// HELPERS
import { SpecialCategoryPill } from '../utils/Functions'

export const ordersColumn = {
	title: 'ORDERS',
	key: 'orders',
	width: 210,
	ellipsis: true,
	defaultSortOrder: 'descend',
	sortDirections: ['ascend', 'descend', 'ascend'],
	sorter: (a, b) =>
		(a?.subOrderNumber || a?.orderNumber?.toString())?.localeCompare(b?.subOrderNumber || b?.orderNumber?.toString(), 'en', { numeric: true }),
	render: (_, record) => {
		const { isSDOrder, isLotOrder, orderCategory, preparedOrderNumber, refNumber, freightType } = record || {}
		return (
			<div className='pl-3'>
				<div className='flex w-full flex-wrap items-center'>
					<p className='mr-3 text-sm font-bold text-primary-800'>{preparedOrderNumber}</p>
					{isSDOrder
						? SpecialCategoryPill(orderCategory)
						: isLotOrder
						? SpecialCategoryPill('Lot Order')
						: 'DoubleTwenty' === orderCategory && SpecialCategoryPill('Double Twenty')}
				</div>
				{refNumber && <p className='mt-2 font-semibold'>{refNumber}</p>}
				{freightType && (
					<p className='mt-1 text-xs text-gray-500'>
						Commodity:<span> {freightType}</span>
					</p>
				)}
			</div>
		)
	},
}

export const shipmentInfoColumn = {
	title: 'SHIPMENT INFO',
	key: 'status',
	ellipsis: true,
	render: (_, record) => {
		const { docNumber, containerNumbers, shippingLine, containerInfo } = record || {}
		const freightType = containerInfo?.[0]?.[0]?.freightType || containerInfo?.[0]?.[0]?.freightType || record?.shipmentInfo?.freightWeight
		return (
			<div className='w-full overflow-hidden'>
				<div className='flex items-center'>
					<p className='mr-3 text-sm font-semibold'>{docNumber}</p>
					{containerNumbers !== 0 && <p className='rounded bg-gray-100 p-1 font-medium'>{containerNumbers} Containers</p>}
				</div>
				<p className='mt-1 text-xs text-gray-500'>Shipping line: {shippingLine}</p>
				{freightType && <p className='mt-1 text-xs text-gray-500'>Commodity Type: {freightType}</p>}
			</div>
		)
	},
}

export const routeColumn1 = (segment) => ({
	title: 'ROUTE',
	key: 'route',
	width: segment === 'Import' ? '13%' : '25%',
	maxWidth: '25%',
	// ellipsis: true,
	render: (_, record) => {
		return (
			<div className='flex w-full items-start pr-4'>
				<div className='mr-2 h-full'>
					<div className={`flex h-4 w-4 items-center justify-center rounded-full bg-primary-800`}>
						<Icon path={mdiMapMarker} size={0.4} className='text-white' />
					</div>
				</div>
				<div>
					<p className='font-semibold text-gray-500' style={{ fontSize: 10 }}>
						LOADING PORT
					</p>
					<p className='text-sm'>{record?.loading?.[0]?.formattedAddress}</p>
				</div>
			</div>
		)
	},
})

export const routeColumn2 = (segment) => ({
	key: 'route',
	width: 60,
	className: '!px-0',
	render: (_, record) => {
		const stops = segment === 'Export' ? record?.loading?.length - 1 : record?.dropoff?.length - 1
		return (
			<div className='flex w-full flex-col items-center'>
				<Icon path={mdiRayStartArrow} size={1} className='text-gray-500' />
				{!!stops && (
					<p className='rounded bg-primary-50 p-1 font-bold text-primary-800'>
						{stops} {stops > 1 ? 'Stops' : 'Stop'}
					</p>
				)}
			</div>
		)
	},
})

export const routeColumn3 = (segment) => ({
	key: 'route',
	width: segment === 'Export' ? '13%' : '25%',
	maxWidth: '25%',
	// ellipsis: true,
	render: (_, record) => {
		return (
			<div className='flex w-full items-start pr-4'>
				<div className='mr-2 h-full'>
					<div className={`flex h-4 w-4 items-center justify-center rounded-full bg-red-500`}>
						<Icon path={mdiMapMarker} size={0.4} className='text-white' />
					</div>
				</div>
				<div className='w-full'>
					<p className='font-semibold text-gray-500' style={{ fontSize: 10 }}>
						DROP-OFF POINT
					</p>
					<p className='text-sm'>{record?.dropoff?.[record?.dropoff?.length - 1]?.formattedAddress}</p>
				</div>
			</div>
		)
	},
})

export const statusColumn = {
	title: 'STATUS',
	key: 'status',
	width: 290,
	ellipsis: true,
	filters: [
		{ text: 'Pending Assignment', value: 'Order Pending' },
		{ text: 'In Transit', value: 'Order Ongoing' },
		{ text: 'Completed', value: 'Order Completed' },
		{ text: 'Cancelled', value: 'Order Cancelled' },
	],
	onFilter: (value, record) => record?.orderStatus === value,
	render: (_, record) => (
		<div className='w-full overflow-hidden'>
			<OrderStatusPill status={record?.orderStatus} />
		</div>
	),
}
