import { Layout } from 'antd'
import { useMemo, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import Header from '../components/layout/Header'
import { ErrorBoundary } from 'react-error-boundary'
import TableErrorBoundary from '../components/errors/TableError'
import { corporateByIdSelector } from '../recoil/selectors'

const { Content } = Layout

const HomePage = () => {
	const { segment, activeCorporateId } = useParams()
	const { pathname } = useLocation()

	const subscribeRef = useRef()
	const selectedTab = useMemo(() => pathname?.split('/')?.[1], [pathname])
	const activeCorporate = useRecoilValue(corporateByIdSelector({ id: activeCorporateId }))

	return (
		<Layout>
			<ErrorBoundary
				fallbackRender={({ error, resetErrorBoundary }) => <TableErrorBoundary error={error} resetErrorBoundary={resetErrorBoundary} />}
				resetKeys={[segment]}
			>
				<Header selectedTab={selectedTab} activeCorporate={activeCorporate} />
			</ErrorBoundary>
			<Content className='!bg-secondaryBackground h-full'>
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => <TableErrorBoundary error={error} resetErrorBoundary={resetErrorBoundary} />}
					resetKeys={[segment]}
				>
					<Outlet context={{ selectedTab, subscribeRef, activeCorporate }} />
				</ErrorBoundary>
			</Content>
		</Layout>
	)
}

export default HomePage
