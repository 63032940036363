import React from 'react'
import { Form } from 'antd'
import { RenderSchema } from '../../utils/FormRenderer'
import { OrderTypeSchema } from '../../utils/FormSchemas'
import { ContainerDimensionsEnum } from '../../utils/Enums'
import ContainerQuantity from './ContainerQuantity'

const ContainerInfo = (props) => {
	const { orderIndex, segment, form, containerCounter, setContainerCounter } = props
	return (
		<>
			<p className='mb-3 text-base font-medium leading-5 text-gray-500'>Specify Container Information</p>
			<div className='mb-12 rounded-lg border bg-white '>
				{segment === 'Import' && <div className='px-6 pt-4'>{RenderSchema(OrderTypeSchema({ orderIndex }))}</div>}
				{(segment === 'Export' || segment === 'Import') && (
					<div id='containerSpecifics'>
						<p className='mb-1 mt-6 px-6 text-xs font-semibold text-gray-500'>Select Container Dimensions</p>
						<Form.Item
							id='hello'
							key={[orderIndex, 'containersSpecifics']}
							name={[orderIndex, 'containersSpecifics']}
							rules={[
								{
									validator: async () => {
										if (Object.values(containerCounter?.[orderIndex]).some((count) => count > 0)) Promise.resolve()
										else throw new Error('Container specifics are required.')
									},
								},
							]}
							className='m-0'
						>
							<Form.Item shouldUpdate={(prevValues, currentValues) => prevValues?.specialRequests?.reefer !== currentValues?.specialRequests?.reefer} noStyle>
								{({ getFieldValue }) => {
									const dimensions = Object.values(ContainerDimensionsEnum())
									return dimensions?.map((container) => (
										<ContainerQuantity
											form={form}
											segment={segment}
											key={container.key}
											container={container}
											orderIndex={orderIndex}
											containerCounter={containerCounter}
											setContainerCounter={setContainerCounter}
										/>
									))
								}}
							</Form.Item>
						</Form.Item>
					</div>
				)}
			</div>
		</>
	)
}

export default ContainerInfo
