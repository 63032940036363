import { gql } from '@apollo/client'

export const CREATE_IMPORT_ORDER = gql`
	mutation CreateImportOrder($createImportOrderInput: CreateImportOrderInput!) {
		createImportOrder(createImportOrderInput: $createImportOrderInput) {
			_id
			type
			createdAt
			clientCCEmails
			clientEmails
			Corporate {
				businessName
				businessCode
			}
			businessCode
			Client {
				User {
					_id
					firstName
					lastName
					email
				}
			}
			ParentOrder {
				_id
				ParentImport {
					_id
					documentNumber
					orderNumber
					SubImports {
						_id
						loadingDT
						dimension
						finalizedRate
						finalizedRateUnit
						OrderLocations {
							_id
							locationType
							steps
							Location {
								_id
								formattedAddress
							}
							contacts {
								name
								phoneNumber
							}
						}
					}
					preferences {
						_id
						movementType
						numberFlatbeds
						gstInclusive
						refNumber
					}
					clearingAgents {
						name
						phoneNumber
					}
					shippingLine
				}
			}
		}
	}
`
export const CREATE_EXPORT_ORDER = gql`
	mutation CreateExportOrder($createExportOrderInput: CreateExportOrderInput!) {
		createExportOrder(createExportOrderInput: $createExportOrderInput) {
			_id
			type
			createdAt
			businessCode
			clientCCEmails
			clientEmails
			Corporate {
				businessName
				businessCode
			}
			Client {
				User {
					_id
					firstName
					lastName
					email
				}
			}
			ParentOrder {
				_id
				ParentExport {
					_id
					documentNumber
					orderNumber
					vesselCutoffDT
					SubExports {
						_id
						loadingDT
						dimension
						finalizedRate
						finalizedRateUnit
						OrderLocations {
							_id
							locationType
							steps
							Location {
								_id
								formattedAddress
							}
							contacts {
								name
								phoneNumber
							}
						}
					}
					preferences {
						_id
						movementType
						gstInclusive
						refNumber
					}
					clearingAgents {
						name
						phoneNumber
					}
					shippingLine
				}
			}
		}
	}
`
export const CREATE_LONGHAUL_ORDER = gql`
	mutation CreateLongHaulOrder($createLongHaulOrderInput: CreateLongHaulOrderInput!) {
		createLongHaulOrder(createLongHaulOrderInput: $createLongHaulOrderInput) {
			_id
			type
			createdAt
			businessCode
			clientCCEmails
			clientEmails
			Corporate {
				businessCode
				businessName
			}
			Client {
				User {
					_id
					firstName
					lastName
					email
				}
			}
			ParentOrder {
				_id
				ParentLongHaul {
					_id
					orderNumber
					SubLongHauls {
						_id
						loadingDT
						vehicleType
						finalizedRate
						finalizedRateUnit
						OrderLocations {
							_id
							locationType
							steps
							Location {
								_id
								formattedAddress
							}
							contacts {
								name
								phoneNumber
							}
						}
					}
					preferences {
						_id
						lotOrder
						numberVehicles
						gstInclusive
						refNumber
					}
					shipmentInfo {
						_id
						freightType
						freightWeight
					}
				}
			}
		}
	}
`

export const UPDATE_ORDER_LOCATIONS = gql`
	mutation UpdateOrderLocation($updateOrderLocationInput: UpdateOrderLocationInput!) {
		updateOrderLocation(updateOrderLocationInput: $updateOrderLocationInput) {
			_id
		}
	}
`

export const CREATE_USER = gql`
	mutation CreateUser($createUserInput: CreateUserInput!) {
		createUser(createUserInput: $createUserInput) {
			_id
			firstName
			lastName
			email
		}
	}
`

export const CREATE_CLIENT = gql`
	mutation CreateClient($createClientInput: CreateClientInput!) {
		createClient(createClientInput: $createClientInput) {
			_id
		}
	}
`

export const CONNECT_CLIENT_CORPORATES = gql`
	mutation ConnectClientCorporates($connectClientCorporatesInput: ConnectClientCorporatesInput) {
		connectClientCorporates(connectClientCorporatesInput: $connectClientCorporatesInput) {
			_id
		}
	}
`

export const UPDATE_CORPORATE_EMAILS = gql`
	mutation UpdateCorporate($updateCorporateInput: UpdateCorporateInput!) {
		updateCorporate(updateCorporateInput: $updateCorporateInput) {
			_id
		}
	}
`
