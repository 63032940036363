import React, { useEffect, useMemo, useState } from 'react'
import { Layout } from 'antd'
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'
import { getZoomLevel } from '../../utils/Functions'
import MapVehiclesList from './MapVehiclesList'
import SelectedVehicleMarker from '../utility/SelectedVehicleMarker'
import TrackingCountCard from './TrackingCountCard'

const TrackingMap = (props) => {
	const { records, selectedRecord, selectedVehicle, setSelectedVehicle, segment } = props || {}
	const libraries = useMemo(() => ['geometry', 'drawing', 'places', 'visualization'], [])
	const { isLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_API_KEY, libraries })
	let bounds = useMemo(() => isLoaded && new window.google.maps.LatLngBounds(), [isLoaded])
	const [boundsCenter, setBoundsCenter] = useState({ lat: 30.3753, lng: 69.3451, zoomlevel: 5 })
	const [subOrders, setSubOrders] = useState([])

	const openInfoBox = (record) => setSelectedVehicle(record)

	useEffect(() => {
		setSubOrders(
			records
				?.filter((record) => (selectedRecord ? record?.preparedOrderNumber === selectedRecord?.preparedOrderNumber : true))
				?.map((record) => record?.subOrders)
				?.flat()
				?.filter((record) => record?.coordinates)
		)
	}, [selectedRecord, isLoaded, records])

	useEffect(() => {
		const vehicleCenter = selectedVehicle ? { lat: selectedVehicle?.coordinates?.lat, lng: selectedVehicle?.coordinates?.lng } : undefined
		if (isLoaded && selectedRecord) {
			const coordinates = selectedRecord?.subOrders?.filter((order) => order?.coordinates)
			if (coordinates?.length > 0) {
				bounds = new window.google.maps.LatLngBounds() // eslint-disable-line
				coordinates?.forEach((order) => bounds.extend(order?.coordinates)) // eslint-disable-line
				setBoundsCenter(
					vehicleCenter
						? { ...vehicleCenter, zoomlevel: getZoomLevel(bounds) + 2 }
						: { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng(), zoomlevel: getZoomLevel(bounds) }
				)
			} else {
				setBoundsCenter({ lat: 30.3753, lng: 69.3451, zoomlevel: 5 })
			}
		} else if (isLoaded && !selectedRecord) {
			setBoundsCenter(vehicleCenter ? { ...vehicleCenter, zoomlevel: 8 } : { lat: 30.3753, lng: 69.3451, zoomlevel: 5 })
		}
	}, [isLoaded, selectedRecord, selectedVehicle])

	return (
		<Layout.Content className='relative h-full w-full rounded-r-2xl !bg-gray-500'>
			{isLoaded && (
				<GoogleMap
					mapContainerClassName='relative h-full w-full rounded-r-2xl'
					bounds={bounds}
					zoom={boundsCenter && boundsCenter.zoomlevel}
					center={boundsCenter && { lat: boundsCenter.lat, lng: boundsCenter.lng }}
					options={{ disableDefaultUI: true }}
					onMouseMove={(e) => selectedVehicle && !selectedRecord && setBoundsCenter({ zoomlevel: 7 })}
					onClick={() => {
						setSelectedVehicle(null)
					}}
				>
					{subOrders?.map((record) => (
						<Marker
							key={`Marker_${record?._id}`}
							position={record?.coordinates}
							onMouseOver={(e) => {
								openInfoBox(record)
							}}
							icon={{
								url:
									'https://firebasestorage.googleapis.com/v0/b/bridgelinx-client.appspot.com/o/public%2Fimages%2Fmap%20pins%2Fblip.png?alt=media&token=00b468a4-de84-43c3-bbc6-751505288582',
							}}
						></Marker>
					))}
					{selectedVehicle && <SelectedVehicleMarker segment={segment} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />}
				</GoogleMap>
			)}
			{selectedRecord && <MapVehiclesList selectedRecord={selectedRecord} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />}
			{selectedRecord && <TrackingCountCard selectedRecord={selectedRecord} />}
		</Layout.Content>
	)
}

export default TrackingMap
