import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { GraphQLEnums } from '../utils/Enums'
import { updateData } from '../utils/Functions'

import { useSetRecoilState } from 'recoil'
import { userAtom } from '../recoil/atoms'

export const useFetch = ({ type, atomKey, variables = {}, subscriptionVariables = {}, pageInfo, fetchKey }) => {
	const setAtom = useSetRecoilState(atomKey ? GraphQLEnums[`${type}`]?.atoms?.[`${atomKey}`] : userAtom)
	const { where, options, conditions } = GraphQLEnums[`${type}`]?.fetch?.[fetchKey ? fetchKey : 'default']?.getVariables({ ...variables })
	let paginationOptions
	if (pageInfo) {
		paginationOptions = {
			...conditions,
			where,
			options: {
				...options,
				limit: pageInfo?.pageSize,
				offset: pageInfo?.pageSize * (pageInfo?.pageNo - 1),
			},
		}
	}

	const { data, loading, error, fetchMore, subscribeToMore } = useQuery(GraphQLEnums[`${type}`]?.fetch?.[fetchKey ? fetchKey : 'default']?.name, {
		variables: paginationOptions || { where, options, ...conditions },
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'cache-and-network',
	})
	const subscribeCallBack = (subscriptionKey = '', subscribeCallBackVariables = {}) => {
		// console.log('called subscription on ', subscriptionKey, subscribeCallBackVariables)
		subscribeToMore({
			document: GraphQLEnums[`${type}`]?.subscribe?.[subscriptionKey]?.name,
			variables: { ...subscribeCallBackVariables, ...conditions },
			updateQuery: (prev, { subscriptionData }) => updateData({ prev, subscriptionData, type, setAtom, subscriptionKey, fetchKey }),
		})
	}
	useEffect(() => {
		if (pageInfo) {
			fetchMore(paginationOptions)
		}
	}, [pageInfo]) // eslint-disable-line

	useEffect(() => {
		if (GraphQLEnums[`${type}`]?.subscribe?.default) {
			return subscribeToMore({
				document: GraphQLEnums[`${type}`]?.subscribe?.default?.name,
				variables: { ...subscriptionVariables, ...conditions },
				updateQuery: (prev, { subscriptionData }) => updateData({ prev, subscriptionData, type, setAtom, fetchKey }),
			})
		}
	}, [subscriptionVariables]) // eslint-disable-line

	useEffect(() => {
		if (error) {
			throw error
		}
		data && setAtom(GraphQLEnums[`${type}`]?.fetch?.[fetchKey ? fetchKey : 'default']?.key?.reduce((prev, curr) => prev?.[`${curr}`], data))
	}, [data, error]) // eslint-disable-line

	return [loading, subscribeCallBack]
}
