import React from 'react'
import { Form, Button, Input, Checkbox } from 'antd'
import { useNavigate } from 'react-router-dom'

const Login = (props) => {
	const navigator = useNavigate()
	return (
		<div className='w-2/5 rounded-lg bg-white p-12'>
			<p className='mb-3 text-3xl font-semibold'>Login to access your account</p>
			<p className='mb-6 text-base font-medium text-gray-400'>Enter your credentials.</p>
			<Form.Item
				key='email'
				name='email'
				label='Email Address'
				className='mb-3'
				rules={[
					{
						required: true,
						validator: async (_, value) => {
							const expression = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
							return expression.test(value) ? Promise.resolve() : Promise.reject(new Error('Please enter a valid email address.'))
						},
						validateTrigger: 'onSubmit',
					},
				]}
			>
				<Input className='h-12 rounded' placeholder='johndoe@gmail.com' />
			</Form.Item>
			<Form.Item key='password' name='password' label='Password' className='mb-4' rules={[{ required: true, message: 'Please enter your password.' }]}>
				<Input.Password className='h-12 rounded' placeholder='Enter your password' />
			</Form.Item>
			<div className='flex justify-between'>
				<Form.Item key='keepSignedIn' name='keepSignedIn' className='text-sm' valuePropName='checked' initialValue={false}>
					{!props.loading && <Checkbox>Keep me signed in</Checkbox>}
				</Form.Item>
				<Form.Item key='forgotPassword' name='forgotPassword' className='text-sm' valuePropName='checked' initialValue={false}>
					<Button className='p-0' type='link' onClick={() => navigator('/auth/forgotPassword')}>
						{!props?.loading && <p className='text-primary-800 underline'>Forgot Password?</p>}
					</Button>
				</Form.Item>
			</div>
			<Form.Item className='mb-0'>
				<Button className='btn-primary' htmlType='submit' block loading={props?.loading}>
					LOGIN
				</Button>
			</Form.Item>
		</div>
	)
}

export default Login
