import React, { useMemo } from 'react'
import { SegmentsEnum } from '../../utils/Enums'
import moment from 'moment'

const LiveTrackingCard = (props) => {
	const { record, selectedRecord, setSelectedRecord, setSelectedVehicle } = props
	const { shippingLine, preparedOrderNumber, docNumber, loadingDT, type, subOrders } = record || {}
	const segmentInfo = useMemo(() => SegmentsEnum[type], [type])

	const SummaryCard = (label, value, divClass) => (
		<div className={divClass}>
			<p className='text-text-xs font-medium text-gray-500'>{label}</p>
			<p className='text-sm font-semibold'>{value}</p>
		</div>
	)

	return (
		<div
			className={`cursor-pointer p-4 ${selectedRecord?._id === record?._id ? 'mt-3 rounded-md' : ''}`}
			style={selectedRecord?._id === record?._id ? { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' } : {}}
			onClick={() => {
				setSelectedRecord(record)
				setSelectedVehicle(null)
			}}
		>
			<div className='flex items-center'>
				<p
					className='mr-3 flex h-6 w-6 items-center justify-center rounded-full font-bold text-white'
					style={{ fontSize: 8, backgroundColor: segmentInfo?.textColor }}
				>
					{segmentInfo?.shortCode}
				</p>
				<p className='text-base font-semibold'>{preparedOrderNumber}</p>
				{/* {noTracking && <p className='pl-1 text-xs font-semibold text-yellow-500'>- No tracking available</p>} */}
			</div>
			<div className='mt-3 flex flex-col items-center rounded-md p-2' style={{ color: segmentInfo?.textColor, backgroundColor: segmentInfo?.bgColor }}>
				<p className='text-xs font-medium'>{type === 'LongHaul' ? 'Loading Date' : `${segmentInfo?.docTypeText} No.`}</p>
				<p className='text-base font-semibold'>{type === 'LongHaul' ? moment(loadingDT).format('DD/MM/YYYY hh:mm A') : docNumber}</p>
			</div>
			<div className='mt-3 flex justify-between'>
				{SummaryCard('Shipping Line', shippingLine)}
				{SummaryCard('Vehicles', subOrders?.length, 'w-1/4 ml-2')}
			</div>
		</div>
	)
}

export default LiveTrackingCard
