import React from 'react'
import { Select } from 'antd'
import { DownCircleFilled } from '@ant-design/icons'

const MapVehiclesList = (props) => {
	const { selectedRecord, selectedVehicle, setSelectedVehicle } = props

	return (
		<div className='absolute right-0 top-0 m-4'>
			<Select
				value={selectedVehicle}
				onChange={(value) => setSelectedVehicle(value)}
				className='MapVehicleSelect w-56'
				suffixIcon={<DownCircleFilled />}
				placeholder='Select Vehicle'
				showSearch
				allowClear
			>
				{selectedRecord?.subOrders?.map((record) => {
					return (
						<Select.Option key={record?._id} value={JSON.stringify(record)}>
							<div className='flex w-full items-center justify-between'>
								<p className='font-semibold'>{record?.preparedSubOrderNumber}</p>
								<p className='rounded bg-primary-800 px-2 font-semibold uppercase text-white'>{record?.driver?.driverVehicleRegistration}</p>
							</div>
						</Select.Option>
					)
				})}
			</Select>
		</div>
	)
}

export default MapVehiclesList
